import { MILLIS_SECOND } from '@constants';
import logger from '@services/logger';
import { convertLocalDateToApiString } from '@utils/utils';
import { differenceInMinutes } from 'date-fns';

const getGroupByVal = (diffBetweenDatesInUnit: number) =>
  Math.ceil(diffBetweenDatesInUnit / MILLIS_SECOND);

export const getApiDatesQueryParams = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const diffBetweenDatesInMins = differenceInMinutes(endDate, startDate);
  const groupByValInMins = getGroupByVal(diffBetweenDatesInMins);

  const groupBy = (() => {
    if (groupByValInMins < 1) {
      logger.log('Historical data: groupBy has invalid value', {
        level: 'warning',
        extra: {
          startDate,
          endDate,
        },
      });
      return '1m';
    }

    if (groupByValInMins <= 100) {
      return `${groupByValInMins}m`;
    }

    const diffBetweenDatesInHours = diffBetweenDatesInMins / 60;
    const groupByValInHours = getGroupByVal(diffBetweenDatesInHours);

    if (groupByValInHours <= 30) {
      return `${groupByValInHours}h`;
    }

    const diffBetweenDatesInDays = diffBetweenDatesInHours / 24;
    const groupByValInDays = getGroupByVal(diffBetweenDatesInDays);

    return `${groupByValInDays}d`;
  })();

  return {
    start: convertLocalDateToApiString(startDate),
    end: convertLocalDateToApiString(endDate),
    group_by: groupBy,
  };
};
