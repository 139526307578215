import { useCallback, useMemo } from 'react';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Flex } from '@components/LayoutUtils';
import { Switch } from '@components/Switch';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { useRedirect, useXDataGridConnect } from '@hooks';
import { GridCellParams } from '@mui/x-data-grid';
import { fetchedUsers, updateUsersFilter } from '@redux/ducks/users/actionCreators';
import { getUsersSelector, UsersSelectorReturn } from '@redux/ducks/users/selectors';
import { createFullName } from '@utils/data/createFullName';

type UserGridCellProps = GridCellParams<string, UserSimpleDto>;

export const UsersList = () => {
  const redirect = useRedirect();

  const goToUser = useCallback(
    (id: UserSimpleDto['id']) => {
      redirect((paths) => paths.configurationUser(id));
    },
    [redirect],
  );

  const dataGridColumns = useMemo(
    () =>
      [
        {
          field: 'is_active',
          headerName: 'Status',
          maxWidth: 80,
          renderCell: ({ row }: UserGridCellProps) => (
            <Flex>
              <Switch size="small" checked={row.is_active} />
            </Flex>
          ),
        },
        {
          field: 'last_name&first_name',
          headerName: 'Name',
          flex: 0.5,
          renderCell: ({ row: { first_name, last_name } }: UserGridCellProps) =>
            createFullName(first_name, last_name, true),
        },
        {
          field: 'email',
          headerName: 'E-mail',
          flex: 0.5,
        },
        {
          field: 'organization.name',
          headerName: 'Organization',
          minWidth: 200,
          renderCell: ({ row }: UserGridCellProps) => (
            <DetailCell onDetailClick={() => goToUser(row.id)}>{row.organization?.name}</DetailCell>
          ),
        },
      ].map((column) => ({
        minWidth: 100,
        flex: 1,
        ...column,
      })),
    [goToUser],
  );

  const usersGridConnection = useXDataGridConnect<UsersSelectorReturn, UserSimpleDto>({
    selector: getUsersSelector,
    currentItemsField: 'currentPageUsers',
    fetchPageAction: fetchedUsers,
    updateFilterAction: updateUsersFilter,
  });

  return <XDataGrid<UserSimpleDto> {...usersGridConnection} columns={dataGridColumns} />;
};
