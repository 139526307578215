import { SimplePaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    barGraph: SimplePaletteColorOptions;
    neutral: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    barGraph?: SimplePaletteColorOptions;
    neutral?: SimplePaletteColorOptions;
  }
}

export type ColorShades =
  | 'synergyBlue'
  | 'energyRed'
  | 'impactOrange'
  | 'electricYellow'
  | 'skyBlue'
  | 'sustGreen';

export type WattstorColors = keyof typeof wattstorColors;

export const wattstorColors = {
  synergyBlue50: '#E5E5F2',
  synergyBlue100: '#BEBEDF',
  synergyBlue200: '#9494C9',
  synergyBlue300: '#7C7DC4',
  synergyBlue400: '#6B6CB3',
  synergyBlue500: '#4E4DA4',
  synergyBlue600: '#3B399C',
  synergyBlue700: '#2C288C',
  synergyBlue800: '#0A0061',
  synergyBlue900: '#0C0737',

  energyRed50: '#FFE4EC',
  energyRed100: '#FFBCD1',
  energyRed200: '#FF8FB2',
  energyRed300: '#FF6093',
  energyRed400: '#FF387B',
  energyRed500: '#E9005A',
  energyRed600: '#D7005C',
  energyRed700: '#C20059',
  energyRed800: '#9C0054',
  energyRed900: '#7F0145',

  impactOrange50: '#FCE7E5',
  impactOrange100: '#FFC7B8',
  impactOrange200: '#FFA28A',
  impactOrange300: '#FF7C5B',
  impactOrange400: '#FF5D37',
  impactOrange500: '#FD3C14',
  impactOrange600: '#F23610',
  impactOrange700: '#E42F0A',
  impactOrange800: '#D62706',
  impactOrange900: '#BD1600',

  electricYellow50: '#FFF7DF',
  electricYellow100: '#FFEAAF',
  electricYellow200: '#FFDD7C',
  electricYellow300: '#FFCF44',
  electricYellow400: '#FEBB00',
  electricYellow500: '#FFAD00',
  electricYellow600: '#FF9900',
  electricYellow700: '#FF8800',
  electricYellow800: '#FF761A',
  electricYellow900: '#FF6600',

  skyBlue50: '#CCDBFE',
  skyBlue100: '#99B8FD',
  skyBlue200: '#6694FB',
  skyBlue300: '#3C79FF',
  skyBlue400: '#2367FF',
  skyBlue500: '#004DF9',
  skyBlue600: '#003AE0',
  skyBlue700: '#002ED6',
  skyBlue800: '#0011C5',
  skyBlue900: '#0110B0',

  sustGreen50: '#E0FAEA',
  sustGreen100: '#B5F3CA',
  sustGreen200: '#7CEAA8',
  sustGreen300: '#04E282',
  sustGreen400: '#00D963',
  sustGreen500: '#00CF4A',
  sustGreen600: '#00BF3F',
  sustGreen700: '#00AB32',
  sustGreen800: '#009926',
  sustGreen900: '#00780E',

  /* PODIUM Extra colors, should be replaced later, or defined in Figma */

  white: '#FFF',
  black: '#000',

  neutral30: '#EDF0F3',
  neutral50: '#D0D4D9',
  neutral100: '#202020',

  custom1: '#139D05',
  custom2: '#FF5733',
  custom3: '#2E86C1',
  custom4: '#900C2F',
  custom5: '#FFC300',
  custom6: '#051099',
  custom7: '#C70039',
  custom8: '#4A235A',
  custom9: '#40AE70',
  custom10: '#aa7A05',
  custom11: '#cf1322',
  custom12: '#5DcDf2',
  custom13: '#953000',
  custom14: '#1E4060',
  custom15: '#ffff85',
};

const addOpacity = (color: string, opacity: number): string => {
  const opacityHex = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');
  return `${color}${opacityHex}`;
};

export const palette = {
  primary: {
    main: wattstorColors.synergyBlue800,
    dark: wattstorColors.synergyBlue900,
    light: wattstorColors.synergyBlue600,

    _states: {
      hover: addOpacity(wattstorColors.synergyBlue800, 0.04),
      selected: addOpacity(wattstorColors.synergyBlue800, 0.08),
      focus: addOpacity(wattstorColors.synergyBlue800, 0.12),
      focusVisible: addOpacity(wattstorColors.synergyBlue800, 0.3),
      outlinedBorder: addOpacity(wattstorColors.synergyBlue800, 0.5),
    },
  },
  secondary: {
    main: wattstorColors.energyRed400,
    dark: wattstorColors.energyRed600,
    light: wattstorColors.energyRed200,
    _states: {
      hover: addOpacity(wattstorColors.energyRed400, 0.04),
      selected: addOpacity(wattstorColors.energyRed400, 0.08),
      focus: addOpacity(wattstorColors.energyRed400, 0.12),
      focusVisible: addOpacity(wattstorColors.energyRed400, 0.3),
      outlinedBorder: addOpacity(wattstorColors.energyRed400, 0.5),
    },
  },
  error: {
    main: wattstorColors.impactOrange700,
    dark: wattstorColors.impactOrange900,
    light: wattstorColors.impactOrange500,
    _states: {
      hover: addOpacity(wattstorColors.impactOrange700, 0.04),
      selected: addOpacity(wattstorColors.impactOrange700, 0.08),
      focusVisible: addOpacity(wattstorColors.impactOrange700, 0.3),
      outlinedBorder: addOpacity(wattstorColors.impactOrange700, 0.5),
    },
  },
  warning: {
    main: wattstorColors.electricYellow300,
    dark: wattstorColors.electricYellow400,
    light: wattstorColors.electricYellow200,
    contrast: wattstorColors.black,
    _states: {
      hover: addOpacity(wattstorColors.electricYellow300, 0.04),
      selected: addOpacity(wattstorColors.electricYellow300, 0.08),
      focusVisible: addOpacity(wattstorColors.electricYellow300, 0.3),
      outlinedBorder: addOpacity(wattstorColors.electricYellow300, 0.5),
    },
  },
  info: {
    main: wattstorColors.skyBlue500,
    dark: wattstorColors.skyBlue600,
    light: wattstorColors.skyBlue400,
    _states: {
      hover: addOpacity(wattstorColors.skyBlue500, 0.04),
      selected: addOpacity(wattstorColors.skyBlue500, 0.08),
      focusVisible: addOpacity(wattstorColors.skyBlue500, 0.3),
      outlinedBorder: addOpacity(wattstorColors.skyBlue500, 0.5),
    },
  },
  success: {
    main: wattstorColors.sustGreen400,
    dark: wattstorColors.sustGreen500,
    light: wattstorColors.sustGreen300,
    contrast: wattstorColors.black,
    _states: {
      hover: addOpacity(wattstorColors.sustGreen400, 0.04),
      selected: addOpacity(wattstorColors.sustGreen400, 0.08),
      focusVisible: addOpacity(wattstorColors.sustGreen400, 0.3),
      outlinedBorder: addOpacity(wattstorColors.sustGreen400, 0.5),
    },
  },

  // Podium extra palette
  barGraph: {
    light: wattstorColors.skyBlue50,
    main: wattstorColors.skyBlue200,
  },

  neutral: {
    light: wattstorColors.neutral30,
    main: wattstorColors.neutral50,
    dark: wattstorColors.neutral100,
  },

  white: wattstorColors.white,

  graph: {
    legend1: wattstorColors.custom1,
    legend2: wattstorColors.custom2,
    legend3: wattstorColors.custom3,
    legend4: wattstorColors.custom4,
    legend5: wattstorColors.custom5,
    legend6: wattstorColors.custom6,
    legend7: wattstorColors.custom7,
    legend8: wattstorColors.custom8,
    legend9: wattstorColors.custom9,
    legend10: wattstorColors.custom10,
    legend11: wattstorColors.custom11,
    legend12: wattstorColors.custom12,
    legend13: wattstorColors.custom13,
    legend14: wattstorColors.custom14,
    legend15: wattstorColors.custom15,
  },

  graphPointMin: wattstorColors.energyRed700,
  graphPointMax: wattstorColors.sustGreen700,
};
