import { useState } from 'react';
import { ButtonAdd } from '@components/Buttons/Buttons';
import { ConfigurationListModal } from '@components/ConfigurationList/ConfigurationListModal';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { RelationsTabs } from '@components/RelationsForm/RelationsTabs';
import { Box } from '@mui/material';

type RelationData = {
  component: (props: {
    searchTxt: string;
    setSearchTxt: React.Dispatch<React.SetStateAction<string>>;
  }) => JSX.Element;
  addTxt?: string;
  addCustomHandler?: () => void;
  hiddenInModal?: boolean;
};

export type RelationsGridWrapperProps = {
  initialSelectedTab?: ConfiguredItem;
  relations: Partial<{
    [key in ConfiguredItem]: RelationData;
  }>;
  showAddRelatedButton?: boolean;
};

export const RelationsGridWrapper = ({
  initialSelectedTab,
  showAddRelatedButton = true,
  relations,
}: RelationsGridWrapperProps) => {
  const tabs = Object.keys(relations) as ConfiguredItem[];

  const listModalTabs = tabs.filter(
    (relationName) => !relations[relationName]?.hiddenInModal && relations[relationName]?.addTxt,
  );

  const [relationsTabVal, setRelationsTabVal] = useState(
    initialSelectedTab || tabs[0] || ConfiguredItem.SITES,
  );
  const [relationsListModalOpen, setRelationsListModalOpen] = useState(false);

  const [sitesSearchTxt, setSitesSearchTxt] = useState('');
  const [usersSearchTxt, setUsersSearchTxt] = useState('');
  const [organizationsSearchTxt, setOrganizationsSearchTxt] = useState('');
  const [ecdsSearchTxt, setEcdsSearchTxt] = useState('');

  const relationsSearch = {
    [ConfiguredItem.SITES]: {
      searchTxt: sitesSearchTxt,
      setSearchTxt: setSitesSearchTxt,
    },
    [ConfiguredItem.USERS]: {
      searchTxt: usersSearchTxt,
      setSearchTxt: setUsersSearchTxt,
    },
    [ConfiguredItem.ORGANIZATIONS]: {
      searchTxt: organizationsSearchTxt,
      setSearchTxt: setOrganizationsSearchTxt,
    },
    [ConfiguredItem.ECDS]: {
      searchTxt: ecdsSearchTxt,
      setSearchTxt: setEcdsSearchTxt,
    },
  };

  const { searchTxt, setSearchTxt } =
    relationsSearch[relationsTabVal as keyof typeof relationsSearch];

  const {
    component: RelationsComponent,
    addTxt,
    addCustomHandler,
    hiddenInModal,
  } = relations[relationsTabVal as keyof typeof relations] || {};

  return (
    <>
      <ConfigurationListModal
        open={relationsListModalOpen}
        onClose={() => setRelationsListModalOpen(false)}
        initSelectedItem={relationsTabVal}
        visibleTabs={listModalTabs}
      />
      {tabs.length > 1 && (
        <RelationsTabs value={relationsTabVal} tabs={tabs} onValueChange={setRelationsTabVal} />
      )}
      {RelationsComponent && (
        <Box mt={2}>
          {(() => {
            if (!showAddRelatedButton || !addTxt) return null;

            if (!addCustomHandler && hiddenInModal) return null;

            return (
              <ButtonAdd
                sx={{ my: 2 }}
                onClick={addCustomHandler || (() => setRelationsListModalOpen(true))}
              >
                {addTxt}
              </ButtonAdd>
            );
          })()}
          <RelationsComponent searchTxt={searchTxt} setSearchTxt={setSearchTxt} />
        </Box>
      )}
    </>
  );
};
