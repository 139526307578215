import { checkCity } from '@utils/validators/string/city';
import { checkCountry } from '@utils/validators/string/country';
import { createStringValidator } from '@utils/validators/string/createStringValidator';
import { checkEMail } from '@utils/validators/string/eMail';
import { checkPhone } from '@utils/validators/string/phone';
import { checkPostalCode } from '@utils/validators/string/postalCode';
import { checkRequired } from '@utils/validators/string/required';
import { checkRequiredMinMax } from '@utils/validators/string/requiredMinMax';
import { checkSafeString, checkSafeStringSoft } from '@utils/validators/string/safeString';
import { checkStreet } from '@utils/validators/string/street';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: createStringValidator(checkRequiredMinMax(2, 50), checkSafeStringSoft)('Organization Name'),
  manager_name: createStringValidator(checkRequiredMinMax(4, 50), checkSafeString)('Manager Name'),
  manager_email: createStringValidator(checkRequired, checkEMail)('E-mail'),
  manager_phone: createStringValidator(checkRequired, checkPhone)('Phone'),
  street: createStringValidator(checkRequired, checkStreet)('Street'),
  city: createStringValidator(checkRequired, checkCity)('City'),
  country: createStringValidator(checkRequired, checkCountry)('Country'),
  postal_code: createStringValidator(checkRequired, checkPostalCode)('Postal Code'),
});
