import { useEffect } from 'react';
import { ErrorLocal } from '@appTypes/api/error';
import {
  UseChartDateManagementParams,
  useChartDateManagement,
} from '@components/Chart/hooks/useChartDateManagement';
import { TimeSpan } from '@components/Chart/types';
import { Box } from '@mui/material';

type ChartManagementReturn = ReturnType<typeof useChartDateManagement>;

type ChartToolbarProviderProps = {
  children: (props: Pick<ChartManagementReturn, 'renderDateInputs' | 'timeSpan'>) => JSX.Element;
  onDateChange?: UseChartDateManagementParams['onDateChange'];
  onInit?: (arg: { timeSpan: TimeSpan }) => void;
  onDestroy?: () => void;
  fetchError?: ErrorLocal;
  initStartDate?: Date;
  initEndDate?: Date;
  displayDateRangeSelect?: boolean;
  displayFromInput?: boolean;
};

export const ChartToolbarProvider = ({
  children,
  onDateChange,
  onInit,
  onDestroy,
  initStartDate,
  initEndDate,
  displayDateRangeSelect,
  displayFromInput,
}: ChartToolbarProviderProps) => {
  const { renderWithProvider, renderDateInputs, timeSpan } = useChartDateManagement({
    onDateChange,
    initStartDate,
    initEndDate,
    displayDateRangeSelect,
    displayFromInput,
  });

  useEffect(() => {
    if (onInit) {
      onInit({ timeSpan });
    }

    return () => {
      if (onDestroy) {
        onDestroy();
      }
    };
  }, [onDestroy, onInit, timeSpan]);

  return renderWithProvider(
    <Box maxWidth="100%" overflow="hidden">
      <>{children({ renderDateInputs, timeSpan })}</>
    </Box>,
  );
};
