import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

type DateTimeProps = {
  label: string;
  onChange: (val: Date) => void;
  minDateTime?: Date;
  maxDateTime?: Date;
  val: Nullable<Date>;
  size?: TextFieldProps['size'];
};

const CustomTextField = (props: TextFieldProps) => <TextField {...props} />;

export const DateTime = ({
  label,
  onChange,
  minDateTime,
  maxDateTime,
  val,
  size,
  ...textProps
}: DateTimeProps) => (
  <DateTimePicker
    // Note: This is currently only way how to pass tiny variant to underlying TextField as slots does not support custom variant styles
    // @ts-ignore
    size={size}
    slotProps={{ textField: { size } }}
    slots={{
      textField: CustomTextField,
    }}
    label={label}
    value={val}
    onChange={(onChangeVal) => {
      if (!onChangeVal) return;

      onChange(onChangeVal);
    }}
    format="yyyy-MM-dd HH:mm"
    minDateTime={minDateTime || undefined}
    maxDateTime={maxDateTime || undefined}
    disableFuture
    ampm={false}
    views={['year', 'month', 'day', 'hours', 'minutes']}
    {...textProps}
  />
);
