import { Tooltip } from '@components/Tooltip/Tooltip';
import { ReactComponent as IconInfo } from '@icons/info.svg';

const cardTooltipText = {
  systemStatus: 'The status of your energy system in real time.',
  returnOnInvestment:
    'We calculate your cost savings based on your Energy savings (compared to without Wattstor); Non-commoditity savings (buying energy when it’s cheap); Energy export (income from selling energy to the grid)',
  avoidedEmmisions:
    'We calculate the avoided emissions for the total renewable energy you generate, as well as the renewable energy you consume yourself',
  batteryHealth:
    'To keep track of your battery health and performance, we calculate the cycles per day, total cycles, as well as the total charge and discharge over time.',
  consumptionMix:
    'An overview of the proportion of generated renewable energy and grid energy your site is consuming over time. We are aware that there is an issue with the calculation of total consumption and we are working on fixing it',
};

export const CardTooltip = ({ textKey }: { textKey: keyof typeof cardTooltipText }) => (
  <Tooltip trigger={<IconInfo />} placement="top">
    {cardTooltipText[textKey]}
  </Tooltip>
);
