import { UserRole } from '@appTypes/models/user.dto';
import { selectAuthSlice } from '@redux/slices/auth';
import { useSelector } from 'react-redux';
import { FormInputCustomComponentProps } from './FormInput';
import { CustomSelectBoxProps, SelectBox } from './SelectBox/SelectBox';
import { Spinner } from './Spinner';

const options = [
  {
    id: UserRole.SUPERADMIN,
    name: UserRole.SUPERADMIN,
  },
  {
    id: UserRole.ADMIN,
    name: UserRole.ADMIN,
  },
  {
    id: UserRole.USER,
    name: UserRole.USER,
  },
];

const allRoles = [0, UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.USER]; // Note: roles are indexed from 1 in database

export const RolesSelect = (props: FormInputCustomComponentProps) => {
  const { value } = props;
  const { currentUser } = useSelector(selectAuthSlice);

  if (!currentUser) return <Spinner />;

  const selectedRoleId = allRoles.indexOf(value as UserRole);
  const loggedUserRoleId = currentUser.role.id;
  const onlyLowerRoleOpts = options.filter(
    (option) =>
      loggedUserRoleId <= allRoles.indexOf(option.name) || selectedRoleId < loggedUserRoleId,
  );

  return (
    <SelectBox
      {...(props as CustomSelectBoxProps<string>)}
      disableEmptyValue
      formControlProps={{ sx: { width: '100%' } }}
      options={onlyLowerRoleOpts}
    />
  );
};
