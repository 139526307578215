import { useEffect, useState } from 'react';
import { useUpdateGraphCustomSettingsMutation } from '@apiRtk/dashboards';
import { HistoricalDataDto } from '@appTypes/models/historicalData.dto';
import { CenterX, Flex } from '@components/LayoutUtils';
import { Typography, styled } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { userSettings } from '@services/userSettings';
import ApexCharts, { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useChartSeries } from '../hooks/useChartSeries';
import { GraphConfig } from '../types';
import { getChartColors, getChartInstance, isControl, rescaleYAxis } from '../utils/helpers';

const Item = styled('button')<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  white-space: nowrap;
  flex-grow: 0;
  background: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const Column = styled('div')<{ side: 'left' | 'right' }>`
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: ${({ side }) => (side === 'left' ? 'flex-start' : 'flex-end')};
`;

const Marker = styled('span')`
  width: 12px;
  height: 3px;
  flex-shrink: 0;
  background-color: ${({ color, theme }) => color || theme.palette.text.primary};
`;

const OutageMarker = styled('span')`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.warning.light};
  border: 1px solid ${({ theme }) => theme.palette.warning.main};
`;

const Label = styled(Typography)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.text.primary : theme.palette.text.secondary};
  }
`;

type LegendItemProps = {
  label: string;
  color?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const LegendItem = ({ label, color, active, disabled, onClick }: LegendItemProps) => (
  <Item
    onClick={onClick}
    disabled={disabled}
    style={{
      opacity: active ? 1 : 0.3,
    }}
  >
    <Marker style={{ backgroundColor: color }} />
    <Label variant="caption" disabled={disabled}>
      {label}
    </Label>
  </Item>
);

type ChartLegendProps = {
  chartId: string;
  seriesNames: string[];
  graphConfig: GraphConfig[];
  graphConfigId: string;
  graphConfigNoData: GraphConfig[];
  options: ApexOptions;
  series: ReturnType<typeof useChartSeries>;
  outages: HistoricalDataDto['outages'];
};

const ChartLegend = ({
  chartId,
  graphConfig,
  graphConfigId,
  graphConfigNoData,
  seriesNames,
  options,
  series,
  outages,
}: ChartLegendProps) => {
  const [updateGraphCustomSettings] = useUpdateGraphCustomSettingsMutation({});

  const storedInvisibleSeries = userSettings.getInvisibleSeriesByGraphId(graphConfigId);
  const [invisibleSeries, setInvisibleSeries] = useState<string[]>(storedInvisibleSeries);

  const { currentUser } = useSelector(selectAuthSlice);

  const userId = currentUser?.id;

  useEffect(() => {
    if (invisibleSeries.length > 0) {
      const chartInstance = getChartInstance(chartId);
      if (chartInstance) {
        invisibleSeries?.forEach((seriesName) => {
          if (seriesNames.includes(seriesName)) {
            ApexCharts.exec(chartId, 'hideSeries', seriesName);
          }
        });
        rescaleYAxis(chartId, options);
      }
    }
  }, [chartId, invisibleSeries, series, options, seriesNames]);

  const chartColors = getChartColors();

  const handleLegendItemClick = (
    itemName: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const currentInvisibleSeries = new Set(userSettings.getInvisibleSeriesByGraphId(graphConfigId));

    const currentVisibleSeries = seriesNames.filter(
      (seriesName) => !currentInvisibleSeries.has(seriesName),
    );

    let newVisibleSeries: Set<string>;

    if (isControl(event)) {
      newVisibleSeries = new Set(currentVisibleSeries);
      if (newVisibleSeries.has(itemName) && newVisibleSeries.size > 1) {
        newVisibleSeries.delete(itemName);
      } else {
        newVisibleSeries.add(itemName);
      }
    } else {
      newVisibleSeries = new Set(
        currentVisibleSeries.length === 1 && currentVisibleSeries.includes(itemName)
          ? seriesNames
          : [itemName],
      );
    }

    const invisible = seriesNames.filter((seriesName) => !newVisibleSeries.has(seriesName));

    seriesNames.forEach((name) =>
      ApexCharts.exec(chartId, newVisibleSeries.has(name) ? 'showSeries' : 'hideSeries', name),
    );

    updateGraphCustomSettings({ configurationGraphId: graphConfigId, userId: userId!, invisible });
    userSettings.saveInvisibleSeriesByGraphId(graphConfigId, invisible);
    setInvisibleSeries(invisible);

    rescaleYAxis(chartId, options);
  };

  const [leftSideItems, rightSideItems] = _.partition(graphConfig, (config) => config.leftSide);

  return (
    <>
      <Flex gap={3}>
        <Column side="left">
          {leftSideItems.map(({ name, label }, index) => (
            <LegendItem
              key={`legend-item-${name}`}
              onClick={(event) => {
                handleLegendItemClick(name, event);
              }}
              label={label}
              color={chartColors[index]}
              active={!invisibleSeries.includes(name)}
            />
          ))}
        </Column>
        {rightSideItems.length ? (
          <Column side="right">
            {rightSideItems.map(({ name, label }, index) => (
              <LegendItem
                key={`legend-item-${name}`}
                onClick={(event) => {
                  handleLegendItemClick(name, event);
                }}
                label={label}
                color={chartColors[leftSideItems.length + index]}
                active={!invisibleSeries.includes(name)}
              />
            ))}
          </Column>
        ) : null}
      </Flex>

      {graphConfigNoData.length > 0 ? (
        <Flex gap={2} pt={3}>
          <Column side="left">
            <Typography variant="subtitle2">No data: </Typography>
            {graphConfigNoData.map(({ name, label }) => (
              <LegendItem key={`legend-item-${name}`} label={label} disabled />
            ))}
          </Column>
        </Flex>
      ) : null}

      {outages && outages.length > 0 ? (
        <CenterX gap={0.5}>
          <OutageMarker />
          <Typography variant="caption" lineHeight={2}>
            Data outage on some device
          </Typography>
        </CenterX>
      ) : null}
    </>
  );
};

export default ChartLegend;
