import { SiteDto, SiteSimpleDto } from '@appTypes/models/site.dto';
import { SiteFilters } from '@appTypes/sites/filters';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { SitesAction } from './actionCreators';
import { SitesActionsTypes } from './actionTypes';

const initialState = {
  sites: null as Nullable<WPage<SiteSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  filters: {
    page: 1,
    pageSize: 5,
    search: '',
    descending: false,
    orderBy: 'name',
  } as SiteFilters,
  current: {
    data: null as Nullable<SiteDto>,
    ...defaultRequestState,
  },
  updateSiteUser: defaultRequestState,
  create: defaultRequestState,
  update: defaultRequestState,
  delete: defaultRequestState,
};

export const sitesReducer = (state = initialState, action: SitesAction): SitesStateType => {
  const reducerHandler = createReducerHandler<SitesStateType, SiteSimpleDto>('sites', state);

  switch (action.type) {
    case SitesActionsTypes.SET_SITE_REQUEST_LOADING:
      return reducerHandler.setRequestLoading(action.payload);
    case SitesActionsTypes.SET_SITE_REQUEST_ERROR:
      return reducerHandler.setRequestError(action.payload);
    case SitesActionsTypes.REQUEST_SITES:
      return reducerHandler.request();
    case SitesActionsTypes.CLEAR_SITE_CURRENT:
      return reducerHandler.clearCurrent();
    case SitesActionsTypes.REQUEST_SITES_SUCCESS:
      return reducerHandler.requestSuccess(action.payload);
    case SitesActionsTypes.REQUEST_SITES_FAILED:
      return reducerHandler.requestFailed(action.payload);
    case SitesActionsTypes.GET_SITE_SUCCESS:
      return reducerHandler.getCurrent(action.payload);
    case SitesActionsTypes.UPDATE_SITES_FILTER:
      return reducerHandler.updateFilter(action.payload);
    case SitesActionsTypes.RESET_SITES: {
      return initialState;
    }
    default:
      return state;
  }
};

export type SitesStateType = typeof initialState;
