import { organizationsAPI } from '@api/organization';
import {
  OrganizationDto,
  CreateOrganizationDto,
  UpdateOrganizationDto,
  OrganizationSimpleDto,
} from '@appTypes/models/organization.dto';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { fork } from 'redux-saga/effects';
import {
  requestOrganizations,
  requestOrganizationsSuccess,
  requestOrganizationsFailed,
  updateOrganizationSuccess,
  getOrganizationSuccess,
  createOrganizationSuccess,
  setOrganizationRequestLoading,
  setOrganizationRequestError,
} from './actionCreators';
import { OrganizationActionsType } from './actionTypes';
import { getOrganizationsSelector, OrganizationsSelectorReturn } from './selectors';

export function* organizationsWatcher() {
  yield fork<
    CreateSagaCrudWatcher<
      OrganizationsSelectorReturn,
      OrganizationDto,
      CreateOrganizationDto,
      UpdateOrganizationDto,
      OrganizationSimpleDto
    >
  >(createSagaCRUDWatcher, {
    selector: getOrganizationsSelector,
    setRequestLoading: setOrganizationRequestLoading,
    setRequestError: setOrganizationRequestError,
    getList: {
      actionName: OrganizationActionsType.FETCHED_ORGANIZATIONS,
      updateFilterActionName: OrganizationActionsType.UPDATE_ORGANIZATION_FILTER,
      getCountActionName: OrganizationActionsType.FETCHED_ORGANIZATIONS_COUNT,
      apiCall: organizationsAPI.getOrganizations,
      request: requestOrganizations,
      requestSuccess: requestOrganizationsSuccess,
      requestFailed: requestOrganizationsFailed,
    },
    getSingle: {
      actionName: OrganizationActionsType.FETCHED_GET_ORGANIZATION,
      clearCurrentActionName: OrganizationActionsType.CLEAR_ORGANIZATION_CURRENT,
      apiCall: organizationsAPI.getOrganization,
      onSuccess: getOrganizationSuccess,
    },
    create: {
      actionName: OrganizationActionsType.FETCHED_CREATE_ORGANIZATION,
      apiCall: organizationsAPI.createOrganization,
      onSuccess: createOrganizationSuccess,
    },
    update: {
      actionName: OrganizationActionsType.FETCHED_UPDATE_ORGANIZATION,
      apiCall: organizationsAPI.updateOrganization,
      onSuccess: updateOrganizationSuccess,
      cancelCurrentCleanup: true,
    },
  });
}
