export type QueryParams = {
  [key: string]: string | number;
};

export const createQueryParamsStr = (query: QueryParams) => {
  const queryParams = Object.entries(query).reduce((currQuery, [paramName, paramValue], index) => {
    const paramQuery = `${paramName}=${paramValue}`;

    if (index === 0) {
      return `?${paramQuery}`;
    }

    return `${currQuery}&${paramQuery}`;
  }, '');

  return queryParams;
};
