import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { palette } from 'styles/palette';
import { EcdFormInput } from '../../EcdFormInput/EcdFormInput';
import { EcdFormWrapper } from '../../EcdFormWrapper/EcdFormWrapper';
import { createDeviceField } from '../../utils/deviceField';

type DeviceFormProps = {
  id: string;
  name: string;
  visible: boolean;
  fields: {
    id: string;
    name: string;
    readOnly: boolean;
    initValue: string;
  }[];
  registerField: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  viewOnlyMode: boolean;
};

export const DeviceForm = ({
  id,
  name,
  fields,
  visible,
  registerField,
  control,
  viewOnlyMode,
}: DeviceFormProps) => (
  <EcdFormWrapper visible={visible} title={name} wrapperProps={{ bgcolor: palette.white }}>
    {fields.map(({ id: fieldName, name: label, readOnly, initValue }) => {
      const deviceFieldName = createDeviceField(id, fieldName);

      return (
        <EcdFormInput
          size="small"
          control={control}
          readOnly={viewOnlyMode || readOnly}
          initValue={initValue}
          key={fieldName}
          label={label}
          {...registerField(deviceFieldName)}
          data-testid={`${id}-device-${fieldName}`}
        />
      );
    })}
  </EcdFormWrapper>
);
