import { ecdsAPI } from '@api/ecds';
import { ApiError } from '@appTypes/api/error';
import { UpdateEcdDto, EcdSimpleRemoteDto, EcdWithErrorDto } from '@appTypes/models/ecd.dto';
import { createSafeSaga } from '@redux/common/createSafeSaga';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { ActionCreator } from 'redux';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  requestEcdStatus,
  requestEcdStatusSuccess,
  requestEcdStatusFailed,
  setEcdRequestLoading,
  setEcdRequestError,
  requestEcds,
  requestEcdsSuccess,
  requestEcdsFailed,
  getEcdSuccess,
  updateEcdSuccess,
} from './actionCreators';
import {
  FetchedEcdsStatuses,
  RequestEcdStatus,
  EcdsActionsTypes,
  RequestEcdsRemoteSuccess,
} from './actionTypes';
import { EcdsSelectorReturn, getEcdsSelector } from './selectors';

function* fetchEcdsStatuses({ payload }: FetchedEcdsStatuses) {
  const { ecds }: EcdsSelectorReturn = yield select(getEcdsSelector);

  const ecdsToFetchStatus = (ecds || []).filter(
    ({ _page, connect }) => _page === payload && !connect.loaded && !connect.loading,
  );

  yield all(ecdsToFetchStatus.map(({ id }) => put(requestEcdStatus(id))));
}

function* fetchEcdStatus({ payload }: RequestEcdStatus) {
  try {
    const { alive }: { alive: boolean } = yield call(ecdsAPI.getEcdStatus, payload);

    yield put(requestEcdStatusSuccess({ id: payload, alive }));
  } catch (error) {
    yield put(requestEcdStatusFailed({ id: payload, error: error as ApiError }));

    throw error;
  }
}

export function* ecdsWatcher() {
  yield fork<
    CreateSagaCrudWatcher<
      EcdsSelectorReturn,
      EcdWithErrorDto,
      unknown,
      UpdateEcdDto,
      EcdSimpleRemoteDto
    >
  >(createSagaCRUDWatcher, {
    selector: getEcdsSelector,
    setRequestLoading: setEcdRequestLoading,
    setRequestError: setEcdRequestError,
    getList: {
      actionName: EcdsActionsTypes.FETCHED_ECDS,
      updateFilterActionName: EcdsActionsTypes.UPDATE_ECDS_FILTER,
      getCountActionName: EcdsActionsTypes.FETCHED_ECDS_COUNT,
      apiCall: ecdsAPI.getEcds,
      request: requestEcds,
      requestSuccess: requestEcdsSuccess as unknown as ActionCreator<RequestEcdsRemoteSuccess>,
      requestFailed: requestEcdsFailed,
    },
    getSingle: {
      actionName: EcdsActionsTypes.FETCHED_ECD,
      clearCurrentActionName: EcdsActionsTypes.CLEAR_ECD_CURRENT,
      apiCall: ecdsAPI.getEcd,
      onSuccess: getEcdSuccess,
    },
    update: {
      actionName: EcdsActionsTypes.FETCHED_UPDATE_ECD,
      apiCall: ecdsAPI.updateEcd,
      onSuccess: updateEcdSuccess,
      cancelCurrentCleanup: true,
    },
  });

  yield takeEvery(EcdsActionsTypes.FETCHED_ECDS_STATUSES, createSafeSaga(fetchEcdsStatuses));
  yield takeEvery(EcdsActionsTypes.REQUEST_ECD_STATUS, createSafeSaga(fetchEcdStatus));
}
