import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

type FullscreenInfoProps = {
  children: ReactNode;
};

export const FullscreenInfo = ({ children }: FullscreenInfoProps) => (
  <Box display="grid" height="100%" justifyContent="center" alignContent="center">
    <Typography variant="h4">{children}</Typography>
  </Box>
);
