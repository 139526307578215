import { FormInputProps } from '@components/FormInput';

export const checkIfValChanged = (
  initValue: FormInputProps['initValue'],
  currentValue: FormInputProps['initValue'],
) => {
  if (!initValue && !currentValue) return false;

  return initValue !== currentValue;
};
