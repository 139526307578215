import * as Yup from 'yup';
import { createStringValidator } from './createStringValidator';
import { checkMax } from './max';
import { checkMin } from './min';
import { checkRequired } from './required';

export const checkRequiredMinMax =
  (minChars: number = 4, maxChars: number = 30) =>
  (label: string, validator = Yup.string()) =>
    createStringValidator(checkRequired, checkMin(minChars), checkMax(maxChars))(label, validator);
