import { TextFieldProps, Theme } from '@mui/material';
import { space, cssImp } from '@utils/utils';
import { palette } from './palette';

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    tiny: true;
  }
}

const selectIconStyles = {
  '& .MuiSvgIcon-fontSizeMedium': {
    height: '0.9em',
    marginRight: space(0.5),
  },
};

export const components: Theme['components'] = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: 'none',

        '&.Mui-expanded': {
          margin: 0,
        },
        '&:before': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: `${space(1.5)} 0`,
        },
        '&.Mui-expanded': {
          minHeight: space(0),
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: 'max-content',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standard: {
        padding: `${space(0.5)} ${space(1.5)}`,
        '& .MuiAlert-message': {
          fontSize: space(2),
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& .MuiFormLabel-colorSuccess': {
          color: cssImp(palette.success.dark),
        },

        '& .MuiInputBase-colorSuccess': {
          background: cssImp(palette.white),
          color: cssImp(palette.success.dark),

          '& fieldset': {
            borderColor: cssImp(palette.success.dark),
          },
        },

        '& legend': {
          color: palette.neutral.light,
        },

        '& fieldset': {
          borderColor: palette.neutral.main,
          fontFamily: 'Helvetica',
        },

        '&:hover fieldset, &.Mui-focused fieldset': {
          borderColor: palette.success.dark,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '& fieldset': {
          borderColor: palette.neutral.main,
          fontFamily: 'Helvetica',
        },

        color: palette.primary.main,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: TextFieldProps & Record<string, unknown> }) => ({
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          color: palette.primary.main,
        },
        ...(ownerState.size === 'tiny' && {
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
          },
          '& .MuiInputBase-input': {
            fontSize: '12px',
            paddingTop: '9px',
            paddingBottom: '7px',
          },

          '& .MuiInputAdornment-root': {
            '& .MuiSvgIcon-root': {
              fontSize: '16px',
            },
          },
          '& .MuiInputLabel-root': {
            fontSize: '12px',
            transform: 'translate(12px, 9px) scale(1)',
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(12px, -6px) scale(1)',
          },
        }),
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        backgroundColor: palette.white,
        borderRadius: cssImp('10px'),

        '& ~ fieldset legend': {
          overflow: 'unset',
          transform: 'translateY(-5px)',
          maxWidth: '100%',
          color: 'var(--mui-palette-text-secondary)',

          span: {
            opacity: 1,
          },
        },

        ...selectIconStyles,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: selectIconStyles,
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: palette.primary.main,
      },
    },
  },
};
