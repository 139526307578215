import { DeviceParameter } from '@appTypes/models/energyFlow.dto';

export enum ENERGY_FLOW_INDICATOR_COLORS {
  yellow = '#f90',
  green = '#0a0',
  red = '#c00',
}

export type DiagramConfigItem = {
  title: string;
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconOn?: JSX.Element;
  reverseFlow?: boolean;
  energyColor?: string;
  mainParameterName?: string;
  angle: number;
};

export type DiagramConfig = Record<string, DiagramConfigItem>;

export type DeviceParameterWithName = DeviceParameter & {
  name: string;
};
