import {
  CustomSettingsDto,
  CustomSettingsParamsDto,
  DashboardDto,
  DashboardGraphDto,
  UpdateCustomSettingsParamsDto,
} from '@appTypes/models/dashboard.dto';
import { coreApi } from './core';

const dashboardsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<DashboardDto, string>({
      query: (dashboardId) => `dashboards/${dashboardId}`,
    }),
    getGraphs: builder.query<DashboardGraphDto, string>({
      query: (graphId) => `dashboards/graphs/${graphId}`,
    }),
    getGraphCustomSettings: builder.query<CustomSettingsDto, CustomSettingsParamsDto>({
      query: ({ configurationGraphId, userId }) =>
        `dashboards/configuration-graphs/${configurationGraphId}/custom-settings/${userId}`,
      providesTags: ['GraphCustomSettings'],
      keepUnusedDataFor: 0,
    }),
    updateGraphCustomSettings: builder.mutation<CustomSettingsDto, UpdateCustomSettingsParamsDto>({
      query: ({ configurationGraphId, userId, invisible }) => ({
        url: `dashboards/configuration-graphs/${configurationGraphId}/custom-settings/${userId}`,
        method: 'PATCH',
        data: {
          invisible,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGraphsQuery,
  useGetDashboardQuery,
  useGetGraphCustomSettingsQuery,
  useUpdateGraphCustomSettingsMutation,
} = dashboardsApi;
