import { CreateOrganizationDto } from '@appTypes/models/organization.dto';
import { useRequestSuccessAlert } from '@hooks';
import { fetchedCreateOrganization } from '@redux/ducks/organization/actionCreators';
import { getOrganizationsSelector } from '@redux/ducks/organization/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationForm } from './OrganizationForm/OrganizationForm';

const CreateOrganization = () => {
  const dispatch = useDispatch();

  const onSubmit = (data: CreateOrganizationDto) => {
    dispatch(fetchedCreateOrganization(data));
  };

  const { create } = useSelector(getOrganizationsSelector);

  useRequestSuccessAlert(create, 'Organization successfully created.');

  return <OrganizationForm onSubmit={onSubmit} />;
};

export default CreateOrganization;
