import { ForwardedRef, forwardRef } from 'react';
import { FormInput, FormInputProps } from '@components/FormInput';
import { useOnUpdate } from '@hooks';
import { useEcdConfigurationContext } from '@providers/EcdConfigurationProvider';
import { Control, FieldValues, useWatch } from 'react-hook-form';
import { checkIfValChanged } from '../utils/checkIfValChanged';

type EcdFormInputProps = {
  control: Control<FieldValues>;
} & FormInputProps;

export const EcdFormInput = forwardRef(
  (
    {
      control,
      label,
      readOnly,
      initValue,
      name,
      CustomComponent,
      ...textFieldProps
    }: EcdFormInputProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const currentVal = useWatch({ control, name, disabled: readOnly });
    const hasChanged = checkIfValChanged(initValue, currentVal);

    const { setAllChangesCount, isFormFreezed } = useEcdConfigurationContext();

    useOnUpdate(() => {
      setAllChangesCount((currentCount) => (hasChanged ? currentCount + 1 : currentCount - 1));
    }, [hasChanged]);

    return (
      <FormInput
        {...textFieldProps}
        CustomComponent={CustomComponent}
        customComponentProps={{
          color: hasChanged ? 'success' : 'primary',
        }}
        value={CustomComponent ? currentVal : undefined}
        color={hasChanged ? 'success' : undefined}
        disabled={isFormFreezed}
        readOnly={readOnly}
        label={label}
        ref={ref}
        name={name}
        initValue={initValue}
        useInputLabel
        readOnlyStyle={{ marginTop: 0 }}
      />
    );
  },
);
