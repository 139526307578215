import { EllipsisTypography } from '@components/EllipsisTypography';
import { Box, Tabs, Tab } from '@mui/material';
import {
  ConfigurationItem,
  ConfigurationTabItem,
  ConfigurationTabItemCount,
} from '@pages/ConfigurationList/types';
import { palette } from 'styles/palette';

interface ConfigurationTabsProps {
  isLoading: boolean;
  items: ConfigurationTabItem[];
  itemsCount: ConfigurationTabItemCount[];
  selectedTab: ConfigurationItem;
  onHandleChange: (value: ConfigurationItem) => void;
}

const ConfigurationTabs = ({
  isLoading,
  items,
  itemsCount,
  selectedTab,
  onHandleChange,
}: ConfigurationTabsProps) => {
  const handleChange = (event: React.SyntheticEvent, value: string) => {
    onHandleChange(value as ConfigurationItem);
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          background: palette.primary.light,
        },
      }}
    >
      {items.map((item) => (
        <Tab
          key={item.id}
          label={item.label}
          value={item.id}
          icon={
            <Box bgcolor={palette.secondary.main} px={0.2} py={0.3} borderRadius="100%">
              <EllipsisTypography width={25} maxWidth={25} fontSize={14} color={palette.white}>
                {isLoading
                  ? '?'
                  : itemsCount.find((count) => count.id === item.id)?.itemsCount || '?'}
              </EllipsisTypography>
            </Box>
          }
          iconPosition="end"
        />
      ))}
    </Tabs>
  );
};

export default ConfigurationTabs;
