import { useState } from 'react';
import { EcdDevice } from '@appTypes/models/ecd.dto';
import { SearchInput } from '@components/SearchInput';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { Grid } from '@mui/material';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { DeviceForm } from './DeviceForm/DeviceForm';

export type DeviceWFields = {
  fields: {
    id: string;
    name: string;
    readOnly: boolean;
    initValue: string;
  }[];
} & EcdDevice;

type EcdDevicesProps = {
  registerField: UseFormRegister<FieldValues>;
  fields: DeviceWFields[];
  control: Control<FieldValues>;
  viewOnlyMode: boolean;
};

export const EcdDevices = ({ registerField, fields, control, viewOnlyMode }: EcdDevicesProps) => {
  const [search, setSearch] = useState('');

  return (
    <Grid>
      <TitleBar my={4} title="Devices" titleVariant="h5" />
      <SearchInput size="small" value={search} onValueChange={setSearch} />
      {fields.map(({ id: deviceId, name, fields: deviceFields }) => (
        <DeviceForm
          key={deviceId}
          viewOnlyMode={viewOnlyMode}
          visible={search === '' || name.toLowerCase().includes(search.toLowerCase())}
          control={control}
          id={deviceId}
          fields={deviceFields}
          name={name}
          registerField={registerField}
        />
      ))}
    </Grid>
  );
};
