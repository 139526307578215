import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { getEcdsSelector } from '@redux/ducks/ecds/selectors';
import { useSelector } from 'react-redux';

type EcdConfigurationContextValue = {
  allChangesCount: number;
  isFormFreezed: boolean;
  setAllChangesCount(cb: (currentCount: number) => number): void;
};

const EcdConfigurationContext = createContext<EcdConfigurationContextValue>(
  {} as EcdConfigurationContextValue,
);

export const useEcdConfigurationContext = () => useContext(EcdConfigurationContext);

export const EcdConfigurationProvider = ({ children }: PropsWithChildren) => {
  const [allChangesCount, setAllChangesCount] = useState(0);

  const {
    current: { loading },
    update: { loading: isUpdating },
  } = useSelector(getEcdsSelector);

  return (
    <EcdConfigurationContext.Provider
      value={{
        allChangesCount,
        setAllChangesCount,
        isFormFreezed: loading || isUpdating,
      }}
    >
      {children}
    </EcdConfigurationContext.Provider>
  );
};
