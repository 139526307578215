import { useState } from 'react';
import { useGetSitesQuery } from '@apiRtk/sites';
import { SiteDto } from '@appTypes/models/site.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { AppLink } from '@components/AppLink';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirectToFirstSite, useFilters } from '@hooks';
import { BookmarkBorderRounded, BookmarkRounded, Widgets } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { BookmarkedSitesService, getBookmarkedSitesService } from '@services/bookmarkedSites';
import CONFIG, { MAX_BOOKMARKED_SITES } from '@settings/config';
import { ColumnDef, FilterFnOption } from '@tanstack/react-table';
import { paths } from 'paths';
import { palette } from 'styles/palette';

const getColumns = (
  bookmarkedSitesService: BookmarkedSitesService,
  setSuccess: (message: Nullable<string>) => void,
  setError: (message: Nullable<string>) => void,
): ColumnDef<SiteDto>[] => [
  {
    header: () => <span>Name</span>,
    accessorKey: 'name',
    filterFn: 'includesString' as FilterFnOption<SiteDto>,
    cell: ({ row }) => (
      <div style={{ flex: 0.5, display: 'flex', alignItems: 'center' }}>
        <AppLink to={paths.siteOverview(row.original.id)} noUnderline>
          <CenterX gap={1}>
            <Widgets />
            <span>{row.original.name}</span>
          </CenterX>
        </AppLink>
      </div>
    ),
  },
  {
    id: 'organization.name',
    header: () => <span>Organization</span>,
    accessorKey: 'organization.name',
    filterFn: 'includesString' as FilterFnOption<SiteDto>,
  },
  {
    header: () => 'Location',
    accessorKey: 'address.city',
    filterFn: 'includesString' as FilterFnOption<SiteDto>,
    enableSorting: false,
    cell: ({ row }) => (
      <>
        <div>
          <strong>{row.original.address.city}</strong>
        </div>
        <div>{row.original.address.country}</div>
      </>
    ),
  },
  {
    header: () => 'Bookmark',
    accessorKey: 'bookmark',
    enableSorting: false,
    cell: ({ row }) => {
      const siteId = row.original.id;
      const siteName = row.original.name;
      const isBookmarked = bookmarkedSitesService.isSiteBookmarked(siteId);

      const handleBookmarkClick = () => {
        const action = isBookmarked ? 'unbookmarkSite' : 'bookmarkSite';
        const result = bookmarkedSitesService[action]({ id: siteId, name: siteName });

        if (result.success) {
          setSuccess(result.message || null);
        } else {
          setError(result.message || null);
        }
      };

      return (
        <IconButton
          onClick={handleBookmarkClick}
          data-cy={`bookmark-button-${row.original.id}`}
          aria-label={isBookmarked ? 'Unbookmark Site' : 'Bookmark Site'}
        >
          {isBookmarked ? (
            <BookmarkRounded sx={{ color: palette.primary.main }} />
          ) : (
            <BookmarkBorderRounded sx={{ color: palette.primary.main }} />
          )}
        </IconButton>
      );
    },
  },
];
const Sites = () => {
  useRedirectToFirstSite();

  const bookmarkedSitesService = getBookmarkedSitesService(MAX_BOOKMARKED_SITES);

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    setSorting,
    setSearchString,
    setPagination,
  } = useFilters({
    urlMirroringActive: false,
    sortingActive: false,
    pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE,
    sortingState: [
      {
        id: 'name',
        desc: false,
      },
    ],
  });

  const { data, isError, refetch, isLoading } = useGetSitesQuery(getFilters(), {
    refetchOnMountOrArgChange: true,
  });

  const [error, setError] = useState<Nullable<string>>(null);
  const handleCloseError = () => setError(null);

  const [success, setSuccess] = useState<Nullable<string>>(null);
  const handleCloseSuccess = () => setSuccess(null);

  const columns = getColumns(bookmarkedSitesService, setSuccess, setError);

  return (
    <SiteHeaderWrapper siteId={0} siteName="Your sites">
      {error && (
        <Snackbar
          key={error}
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          key={success}
          open={!!success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
        </Snackbar>
      )}
      <CenterX mb={2}>
        <SearchInput
          size="small"
          value={searchString}
          sx={{ maxWidth: 250 }}
          onValueChange={setSearchString}
        />
      </CenterX>
      {isLoading && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>
          Some error occured while getting sites, please try again later.
        </AlertWithRefetch>
      )}
      {data && !isLoading && (
        <TanStackTable<SiteDto>
          columns={columns}
          rows={data.sites || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={setSorting}
          onPaginationChange={setPagination}
        />
      )}
    </SiteHeaderWrapper>
  );
};

export default Sites;
