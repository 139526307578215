import { PropsWithChildren } from 'react';
import { FEATURE_FLAGS } from '@appTypes/common';
import { useCurrentUserInfo } from '@hooks';
import { isFeatureFlagActiveInCurrentEnv, isFeatureFlagEnabled } from '@services/featureFlags';

type FeatureFlagGuardProps = PropsWithChildren<{
  flagName: FEATURE_FLAGS;
  invert?: boolean;
}>;

const FeatureFlagGuard = ({ flagName, children, invert }: FeatureFlagGuardProps) => {
  const { isWattstorUser } = useCurrentUserInfo();
  const isFlagEnabled = isFeatureFlagEnabled(flagName);
  const isFlagDisabled = !isFlagEnabled;
  const isFlagForCurrentEnv = isFeatureFlagActiveInCurrentEnv(flagName);

  const shouldRenderChildren =
    isFlagEnabled && isFlagForCurrentEnv && isWattstorUser ? children : null;

  if (invert) {
    return isFlagDisabled ? children : null;
  }
  return shouldRenderChildren ? children : null;
};

export default FeatureFlagGuard;
