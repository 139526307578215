import { usePalette } from '@hooks';
import { Box, Palette } from '@mui/material';
import { formatCurrency } from '@utils/utils';
import ReactECharts from 'echarts-for-react';

type BarGraphSeries = {
  value: number;
  name: string;
}[];

interface FormatterParams {
  name: string;
  value: number;
  seriesName?: string;
}

const createOptions = (
  series: BarGraphSeries,
  colors: (string | undefined)[],
  currency: string,
  themePalette: Palette,
) => {
  const seriesValues = [...series].reverse();
  const colorValues = [...colors].reverse();

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: FormatterParams[]) =>
        params
          .map((item) => `${item.name}: ${currency} ${formatCurrency(item.value)}`)
          .join('<br/>'),
    },
    grid: {
      top: 0,
      bottom: 14,
    },
    xAxis: {
      type: 'value',
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        show: true,
        formatter: (val: number) => (val === 0 ? `${currency} 0` : ''),
        margin: 3,
        textStyle: {
          color: themePalette.text.secondary,
        },
      },
      splitLine: { show: false },
    },

    yAxis: {
      type: 'category',
      axisLine: { show: true },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      data: seriesValues.map((item) => item.name),
    },
    series: [
      {
        type: 'bar',
        stack: 'Total',
        barWidth: 20,
        data: seriesValues.map((item, index) => ({
          value: item.value,
          itemStyle: {
            color: colorValues[index],
          },
        })),
      },
    ],
  };
};

type BarGraphProps = {
  series: BarGraphSeries;
  colors: (string | undefined)[];
  currency: string;
};

const BarGraph = ({ series, colors, currency }: BarGraphProps) => {
  const palette = usePalette();
  const options = createOptions(series, colors, currency, palette);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      <ReactECharts option={options} style={{ height: 80, width: '100%' }} />
    </Box>
  );
};

export default BarGraph;
