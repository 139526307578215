import { Alert } from '@appTypes/alerts/alert';
import { GlobalAlert } from '@components/GlobalAlert/GlobalAlert';
import { ProviderContext, SnackbarProviderProps, useSnackbar } from 'notistack';

export const snackbarProviderProps: Omit<SnackbarProviderProps, 'children'> = {
  maxSnack: 4,
  autoHideDuration: null,
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
};

export class AlertService {
  snackbarContext: Nullable<ProviderContext>;

  constructor() {
    this.snackbarContext = null;
  }

  setSnackbarContext(snackbarContext: ProviderContext) {
    this.snackbarContext = snackbarContext;
  }

  showAlert(alertProps: Alert) {
    this.snackbarContext?.enqueueSnackbar('', {
      key: alertProps.id,
      persist: true,
      content: <GlobalAlert {...alertProps} />,
    });
  }

  hideAlert(id: Alert['id']) {
    this.snackbarContext?.closeSnackbar(id);
  }
}

export const alertService = new AlertService();

export const useAlertService = () => {
  const snackbarContext = useSnackbar();

  alertService.setSnackbarContext(snackbarContext);
};
