import { useEffect } from 'react';
import { toast, ToastContainer, ToastPosition } from 'react-toastify';
import { notificationService } from './notificationService';
import 'react-toastify/dist/ReactToastify.css';

const toastConfig = {
  position: 'top-right' as ToastPosition,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const NotificationListener = () => {
  useEffect(() => {
    const handleSuccess = (message: string) => toast.success(message, toastConfig);

    const handleError = (message: string) => toast.error(message, toastConfig);
    const handleInfo = (message: string) => toast.info(message, toastConfig);
    const handleWarning = (message: string) => toast.warning(message, toastConfig);

    notificationService.on('success', handleSuccess);
    notificationService.on('error', handleError);
    notificationService.on('info', handleInfo);
    notificationService.on('warning', handleWarning);

    return () => {
      notificationService.off('success', handleSuccess);
      notificationService.off('error', handleError);
      notificationService.off('info', handleInfo);
      notificationService.off('warning', handleWarning);
    };
  }, []);

  return <ToastContainer />;
};
