import SiteHeader from '@components/SiteHeader/SiteHeader';
import { Box } from '@mui/material';

interface PageWrapperProps {
  title: string;
  headerIcon?: Nullable<React.ElementType>;
  children: React.ReactNode;
}

export const PageWrapper = ({ title, headerIcon = null, children }: PageWrapperProps) => (
  <div>
    <SiteHeader siteName={title} headerIcon={headerIcon} tabItems={[]} />
    <Box px={6} py={4} flexGrow={1} overflow="hidden">
      {children}
    </Box>
  </div>
);
