import { instance } from '@api/instance';
import { AxiosRequestConfig } from 'axios';

export const getSingle = <TItem>(
  endpoint: string,
  id: number | string,
  config: AxiosRequestConfig<any> = {},
) => instance.get<TItem>(`${endpoint}/${id}`, config).then((res) => res.data);

export const getRelated = <TItem>(
  endpoint: string,
  id: number | string,
  related: string,
  config: AxiosRequestConfig<any> = {},
) => instance.get<TItem>(`${endpoint}/${id}/${related}`, config).then((res) => res.data);

export const create = <TItem>(endpoint: string, dto: {}, config: AxiosRequestConfig<any> = {}) =>
  instance.post<TItem>(endpoint, dto, config).then((res) => res.data);

export const update = <TItem>(
  endpoint: string,
  dto: { id: number | string },
  config: AxiosRequestConfig<any> = {},
) => instance.patch<TItem>(`${endpoint}/${dto.id}`, dto, config).then((res) => res.data);

export const createRelated = <TItem>(
  endpoint: string,
  id: number | string,
  related: string,
  data: {},
  config: AxiosRequestConfig<any> = {},
) => instance.post<TItem>(`${endpoint}/${id}/${related}`, data, config).then((res) => res.data);

export const updateRelated = <TItem>(
  endpoint: string,
  id: number | string,
  related: string,
  data: {},
  config: AxiosRequestConfig<any> = {},
) => instance.patch<TItem>(`${endpoint}/${id}/${related}`, data, config).then((res) => res.data);

export const deleteItem = <TItem>(
  endpoint: string,
  id: number | string,
  config: AxiosRequestConfig<any> = {},
) => instance.delete<TItem>(`${endpoint}/${id}`, config).then((res) => res.data);
