import { useEffect, useState } from 'react';
import { GapY } from '@components/LayoutUtils';
import { useURLQuery } from '@hooks';
import { Grid, SxProps, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  DataGrid as MuiDataGrid,
  DataGridProps,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { getPaginationInfo } from '@utils/data/getPaginationInfo';
import { palette } from 'styles/palette';
import { Pagination } from '../Pagination';

const borderRadius = '4px !important';

const StyledDataGrid = withStyles({
  root: {
    border: 'none !important',
    overflow: 'visible',
    backgroundColor: palette.white,
  },
  columnHeaders: {},
  columnHeader: {
    color: palette.primary.main,
  },
  columnSeparator: {
    visibility: 'hidden',
  },
  row: {
    '& > .MuiDataGrid-cell': {
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
    },

    '&:last-child': {
      borderBottomRightRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      '& > .MuiDataGrid-cell': {
        '&:first-child': {
          borderBottomLeftRadius: borderRadius,
        },
        '&:last-child': {
          borderBottomRightRadius: borderRadius,
        },
      },
    },
  },
  cell: {
    whiteSpace: 'normal !important' as 'normal',
  },
  virtualScroller: {
    overflow: 'hidden !important',
  },
})(MuiDataGrid);

export type OnSortingChange = (arg: { orderBy: string; descending: boolean }) => void;

interface IXDataGridProps<TItem> extends Omit<DataGridProps, 'rows' | 'page'> {
  headerHeight?: number;
  rowHeight?: number;
  items: Nullable<TItem[] | undefined>;
  page?: number | string;
  renderToolbar?: () => Nullable<JSX.Element>;
  remoteSortModel?: GridSortModel;
  updatePageURLParam?: (param: 'page', value: Nullable<string>) => void;
  wrapperSx?: SxProps<Theme>;
  itemsCount?: Nullable<number>;
  onPaginationChange?: (page: number) => void;
  onSortingChange?: OnSortingChange;
}

export function XDataGrid<TItem = unknown>({
  pageSize = 5,
  headerHeight = 45,
  rowHeight = 80,
  itemsCount: remoteItemsCount = null,
  page,
  onPaginationChange,
  items,
  renderToolbar,
  updatePageURLParam,
  onSortingChange,
  remoteSortModel,
  wrapperSx,
  disableColumnSelector = true,
  disableColumnFilter = true,
  disableColumnMenu = true,
  ...dataGridProps
}: IXDataGridProps<TItem>) {
  const [localCurrentPage, setLocalCurrentPage] = useState(1);

  const queryParams = { page: '1' };

  const { getURLParamValue } = page
    ? {
        getURLParamValue: () => '',
      }
    : // TODO: Remove conditionally called hook
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useURLQuery<typeof queryParams>(undefined, queryParams);

  const getCurrentPage = () => {
    if (page) {
      return typeof page === 'number' ? page : parseInt(page, 10);
    }

    if (updatePageURLParam) {
      return parseInt(getURLParamValue('page'), 10);
    }

    return localCurrentPage;
  };

  const currentPage = getCurrentPage();

  const itemsCount = remoteItemsCount === null ? items?.length || 0 : remoteItemsCount;

  const { pagesCount, isLastPage, lastPageItemsCount } = getPaginationInfo(
    itemsCount,
    pageSize,
    currentPage,
  );

  const allRowsHeight = rowHeight * pageSize;
  const rowsHeight =
    isLastPage && lastPageItemsCount > 0 ? rowHeight * lastPageItemsCount : allRowsHeight;

  const gridHeight = rowsHeight + headerHeight;

  const onPageChange = (val: number) => {
    if (onPaginationChange) {
      onPaginationChange(val);
    }

    if (!updatePageURLParam) {
      setLocalCurrentPage(val);
    }
  };

  const onSort: DataGridProps['onSortModelChange'] = (...args) => {
    if (dataGridProps.onSortModelChange) {
      dataGridProps.onSortModelChange(...args);
    }

    const localGridSortInfo = args[0][0];
    const remoteGridSortInfo = (remoteSortModel || [])[0];

    const nextSortingData = (() => {
      if (!remoteSortModel) {
        return {
          orderBy: localGridSortInfo?.field || '',
          descending: localGridSortInfo ? localGridSortInfo.sort === 'desc' : false,
        };
      }

      if (!remoteGridSortInfo || remoteGridSortInfo.field !== localGridSortInfo.field) {
        return {
          orderBy: localGridSortInfo?.field || '',
          descending: false,
        };
      }

      if (remoteGridSortInfo.sort === 'asc') {
        return {
          orderBy: remoteGridSortInfo.field || '',
          descending: true,
        };
      }

      if (remoteGridSortInfo.sort === 'desc') {
        return { orderBy: '', descending: false };
      }

      return {
        orderBy: remoteGridSortInfo.field || '',
        descending: false,
      };
    })();

    if (onSortingChange) {
      onSortingChange(nextSortingData);
    }
  };

  useEffect(() => {
    setLocalCurrentPage(1);
  }, [items]);

  // display current items when current page items filtering
  // is not managed by parent component
  const firstItemOnPageIndex = (localCurrentPage - 1) * pageSize;
  const currentItems = page
    ? items || []
    : (items || []).slice(firstItemOnPageIndex, firstItemOnPageIndex + pageSize);

  const remoteSortModelProps = !remoteSortModel
    ? {}
    : {
        sortModel: [], // prevent local sorting by DataGrid
        sx: {
          ...(dataGridProps.sx || {}),
          [`.MuiDataGrid-columnHeader[data-field="${remoteSortModel[0]?.field}"]` as any]: {
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
              transform: `rotate(${remoteSortModel[0]?.sort === 'desc' ? 180 : 0}deg)`,
            },
          },
        },
      };

  return (
    <Grid sx={wrapperSx}>
      {renderToolbar?.()}
      <Grid sx={{ height: gridHeight }}>
        <StyledDataGrid
          disableSelectionOnClick
          {...dataGridProps}
          {...remoteSortModelProps}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          onSortModelChange={onSort}
          sortingMode="server"
          page={0}
          rows={currentItems as GridValidRowModel[]}
          components={{
            Pagination: null,
          }}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          hideFooter
          disableColumnSelector={disableColumnSelector}
          disableColumnFilter={disableColumnFilter}
          disableColumnMenu={disableColumnMenu}
        />
      </Grid>
      {pagesCount > 0 ? (
        <>
          <GapY size={2} />
          <Pagination
            currentPage={currentPage}
            count={pagesCount}
            updateURLParam={updatePageURLParam}
            onChange={onPageChange}
          />
        </>
      ) : undefined}
    </Grid>
  );
}
