import { useEffect, useState } from 'react';
import {
  ActionCreatorWPayload,
  DefaultFilters,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import { AppStateType } from '@appTypes/redux';
import { SearchInput } from '@components/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentListSelector, ListSelectorReturn } from '../types';

export type CurrentFilterAction = ActionCreatorWPayload<
  UpdateFilterActionType<any, Omit<DefaultFilters, 'page' | 'pageSize'>>
>;

type ConfigurationSearchProps = {
  currentListSelector: CurrentListSelector | undefined;
  currentFilterAction: CurrentFilterAction | undefined;
};

export const ConfigurationSearch = ({
  currentListSelector,
  currentFilterAction,
}: ConfigurationSearchProps) => {
  const [localVal, setLocalVal] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentFilterAction) {
      return;
    }

    dispatch(currentFilterAction({ filterName: 'search', value: undefined }));
  }, [currentFilterAction, dispatch]);

  const {
    filters: { search },
  } = useSelector<AppStateType, ListSelectorReturn>((...args) => {
    if (!currentListSelector) {
      return { filters: { search: '' }, itemsCount: 0 };
    }

    return currentListSelector(...args);
  });

  const onValueChange = (value: string) => {
    if (!currentFilterAction) {
      setLocalVal(value);

      return;
    }

    dispatch(currentFilterAction({ filterName: 'search', value }));
  };

  return (
    <SearchInput
      size="small"
      value={search === undefined ? localVal : search}
      sx={{ maxWidth: 450 }}
      onValueChange={onValueChange}
      onValueChangeDependencies={[currentFilterAction]}
    />
  );
};
