import React from 'react';
import { useWindowSize } from '@hooks';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

const MainMenu = ({ children }: React.PropsWithChildren) => {
  const { isDesktop } = useWindowSize();

  return isDesktop ? <MenuDesktop>{children}</MenuDesktop> : <MenuMobile>{children}</MenuMobile>;
};

export default MainMenu;
