import { ENDPOINT_WITH_FILTERS, FilterParamsCommonDto } from '@appTypes/models/common.dto';
import {
  OrganizationDto,
  OrganizationResponse,
  OrganizationSimpleDto,
  OrganizationsDto,
} from '@appTypes/models/organization.dto';
import { createEndpointQueryForAllItems, createParams } from '@utils/utils';
import { coreApi } from './core';

type SelectOptions = {
  id: number;
  name: string;
}[];

export const organizationsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<OrganizationDto, number>({
      query: (organizationId) => `organizations/${organizationId}`,
    }),
    getOrganizations: builder.query<OrganizationResponse, FilterParamsCommonDto>({
      query: ({ ...params }) => `organizations?${createParams(params)}`,
      providesTags: ['Organizations'],
    }),
    getOrganizationsAll: builder.query<OrganizationSimpleDto[], void>({
      query: () => createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.ORGANIZATIONS),
    }),
    getOrganizationsAllForSelect: builder.query<SelectOptions, void>({
      query: () =>
        `${createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.ORGANIZATIONS)}&order_by=name`,
      transformResponse: (response: OrganizationsDto) => {
        if (!response.organizations) return [];

        const organizations = [...response.organizations].map(({ id, name }) => ({
          id,
          name,
        }));

        return organizations;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useGetOrganizationsAllForSelectQuery,
} = organizationsApi;
