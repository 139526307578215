import { AppStateType } from '@appTypes/redux';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { Box, Tab, TabProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { palette } from 'styles/palette';
import { CurrentListSelector, ListSelectorReturn } from '../types';

type ConfigurationTabProps = {
  selector: CurrentListSelector | undefined;
} & Omit<TabProps, 'icon' | 'iconPosition'>;

export const ConfigurationTab = ({ selector, ...tabProps }: ConfigurationTabProps) => {
  const { itemsCount } = useSelector<AppStateType, ListSelectorReturn>((...args) => {
    if (!selector) {
      return { filters: { search: '' }, itemsCount: 0 };
    }

    return selector(...args);
  });

  return (
    <Tab
      {...tabProps}
      icon={
        <Box bgcolor={palette.secondary.main} px={0.2} py={0.3} borderRadius="100%">
          <EllipsisTypography width={25} maxWidth={25} fontSize={14} color={palette.white}>
            {itemsCount}
          </EllipsisTypography>
        </Box>
      }
      iconPosition="end"
    />
  );
};
