import { useEffect, useState } from 'react';
import { useExportMutation } from '@apiRtk/sites';
import {
  ExportDataFormat,
  ExportGranularity,
  ExportSeries,
  ExportUnit,
} from '@appTypes/models/site.dto';
import { ButtonCancel, ButtonPrimary } from '@components/Buttons/Buttons';
import { DateTime } from '@components/Chart/DateSpanInputs/DateTime/DateTime';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { usePalette } from '@hooks';
import {
  Close,
  Download,
  SvgIconComponent,
  Widgets,
  CalendarMonth,
  ClearAllOutlined,
  Percent,
  TextSnippetOutlined,
} from '@mui/icons-material';

import {
  Alert,
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material';
import { addHours, differenceInSeconds, subHours } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const seriesItems: { label: string; value: ExportSeries }[] = [
  {
    value: 'siteLoad',
    label: 'Site load',
  },
  {
    value: 'gridConsumption',
    label: 'Grid consumption',
  },
  {
    value: 'gridBackflow',
    label: 'Grid backflow',
  },
  {
    value: 'renewableGeneration',
    label: 'Renewable generation',
  },
  {
    value: 'batteryCharging',
    label: 'Battery charging',
  },
  {
    value: 'batteryDischarging',
    label: 'Battery discharging',
  },
];

const unitItems: { label: string; value: ExportUnit }[] = [
  { label: 'kW', value: 'kW' },
  { label: 'MW', value: 'MW' },
  { label: 'kWh', value: 'kWh' },
  { label: 'MWh', value: 'MWh' },
];

const granularityItems: { label: string; value: ExportGranularity }[] = [
  { label: '10 Minutes', value: '10m' },
  { label: '15 Minutes', value: '15m' },
  { label: '30 Minutes', value: '30m' },
  { label: '1 Hour', value: '1h' },
  { label: '24 Hours', value: '24h' },
];

const dataFormatItems: { label: string; value: ExportDataFormat }[] = [
  { label: 'XLSX', value: 'xlsx' },
  { label: 'CSV', value: 'csv' },
];

const DrawerHeader = ({
  children,
  onCloseClick,
}: React.PropsWithChildren<{ onCloseClick?: () => void }>) => (
  <CenterX justifyContent="space-between">
    <Typography variant="h5">{children}</Typography>
    {onCloseClick ? (
      <IconButton color="primary" onClick={onCloseClick}>
        <Close />
      </IconButton>
    ) : null}
  </CenterX>
);

const Container = styled(Box)`
  width: 427px;
  box-sizing: border-box;
  display: grid;
`;

type DataExportPanelProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessMessageOpen: (open: boolean) => void;
};

const Section = ({
  children,
  title,
  IconComponent,
}: React.PropsWithChildren<{ title: string; IconComponent?: SvgIconComponent }>) => (
  <section>
    <CenterX gap={1} pb={1}>
      {IconComponent ? <IconComponent color="primary" /> : null}
      <Typography fontSize={20}>{title}</Typography>
    </CenterX>
    {children}
  </section>
);

type FormValues = {
  start: Nullable<Date>;
  end: Nullable<Date>;
  unit: Nullable<ExportUnit>;
  granularity: Nullable<ExportGranularity>;
  format: Nullable<ExportDataFormat>;
  series: ExportSeries[];
};

const defaultValues: FormValues = {
  start: null,
  end: null,
  series: [],
  unit: null,
  granularity: null,
  format: 'xlsx',
};

const DataExportPanel = ({ open, setOpen, onSuccessMessageOpen }: DataExportPanelProps) => {
  const [runExport, { isLoading, isSuccess, isError }] = useExportMutation();
  const [error, setError] = useState('');
  const currentPalette = usePalette();

  const params = useParams();
  const siteId = parseInt(params.id as string, 10);

  const { handleSubmit, control, formState, reset, watch } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (isSuccess && formState.isSubmitSuccessful) {
      onSuccessMessageOpen(true);
      setOpen(false);
      reset();
    }
  }, [formState.isSubmitSuccessful, isSuccess, reset, onSuccessMessageOpen, setOpen]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { start, end, granularity, unit, format, series } = data;

    if (start && end && granularity && unit && format) {
      if (differenceInSeconds(end, start) <= 3600) {
        setError('Time period must differ by at least one hour.');
        return;
      }
      setError('');

      runExport({
        siteId,
        start,
        end,
        granularity,
        unit,
        series,
        format,
      });
    }
  };

  const getMinDateTime = () => {
    const start = watch('start');
    if (!start) {
      return undefined;
    }

    const startDate = new Date(start);
    if (Number.isNaN(startDate)) {
      return undefined;
    }

    return addHours(startDate, 1);
  };

  const getMaxDateTime = () => {
    const end = watch('end');
    if (!end) {
      return undefined;
    }

    const endDate = new Date(end);
    if (Number.isNaN(endDate)) {
      return undefined;
    }

    return subHours(endDate, 1);
  };

  return (
    <Drawer open={open} anchor="right" sx={{ width: '400px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container p={3} gap={4}>
          <DrawerHeader onCloseClick={() => setOpen(false)}>Data Export</DrawerHeader>
          <Typography variant="body2">
            Select the time period, type, and granularity of the data you want to export
          </Typography>

          <Alert severity="info">Note: Data will be exported in UTC</Alert>

          <Section title="Time period" IconComponent={CalendarMonth}>
            <CenterX gap={2}>
              <Controller
                rules={{ required: true }}
                control={control}
                name="start"
                render={({ field: { onChange, value } }) => (
                  <DateTime
                    label="From"
                    val={value}
                    onChange={onChange}
                    maxDateTime={getMaxDateTime()}
                    size="medium"
                  />
                )}
              />
              <Controller
                rules={{ required: true }}
                control={control}
                name="end"
                render={({ field: { onChange, value } }) => (
                  <DateTime
                    label="To"
                    val={value}
                    onChange={onChange}
                    minDateTime={getMinDateTime()}
                    size="medium"
                  />
                )}
              />
            </CenterX>
          </Section>

          <Section title="Data type" IconComponent={Widgets}>
            <FormGroup>
              <>
                {seriesItems.map(({ label, value }) => (
                  <Controller
                    key={label}
                    name="series"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            color="secondary"
                            value={value}
                            checked={field.value?.includes(value)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...(field.value || []), value]
                                : field.value.filter((val) => val !== value);
                              field.onChange(newValue);
                            }}
                          />
                        }
                        label={label}
                      />
                    )}
                  />
                ))}
              </>
            </FormGroup>
          </Section>

          <Section title="Unit" IconComponent={Percent}>
            <Controller
              name="unit"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  size="medium"
                  label="Select unit"
                  sx={{ width: '200px' }}
                  value={value || ''}
                  onChange={onChange}
                >
                  {unitItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Section>

          <Section title="Data granularity" IconComponent={ClearAllOutlined}>
            <Controller
              name="granularity"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  size="medium"
                  label="Select granularity"
                  sx={{ width: '200px' }}
                  value={value || ''}
                  onChange={onChange}
                >
                  {granularityItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Section>

          <Section title="Data format" IconComponent={TextSnippetOutlined}>
            <Controller
              name="format"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  size="medium"
                  label="Select data format"
                  sx={{ width: '200px' }}
                  value={value || ''}
                  onChange={onChange}
                >
                  {dataFormatItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Section>

          {isError && (
            <Alert severity="error">
              We encountered an error while processing your request. Please try again later.
            </Alert>
          )}

          {error && <Alert severity="error">{error}</Alert>}
          <CenterX gap={1}>
            <ButtonPrimary
              startIcon={
                isLoading ? (
                  <Spinner
                    color={currentPalette.getContrastText(currentPalette.secondary.main)}
                    size={22}
                  />
                ) : (
                  <Download />
                )
              }
              type="submit"
              disabled={!formState.isValid || isLoading}
            >
              Download data
            </ButtonPrimary>

            <ButtonCancel onClick={() => setOpen(false)} />
          </CenterX>
        </Container>
      </form>
    </Drawer>
  );
};

export default DataExportPanel;
