import { coreApi, rtkApiTags } from '@apiRtk/core';
import { loginApi } from '@apiRtk/login';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { routerHistory } from '@redux/ducks/routerHistory/reducer';
import { fetchedSites, resetSites } from '@redux/ducks/sites/actionCreators';
import { startAppListening } from '@redux/middlewares/listenerMiddleware';
import { storage } from '@services/localStorage';
import { paths } from 'paths';
import { authActions, selectAuthSlice } from '../auth';
import { storeRelationInfo } from '../auth/helpers';
import { profileSwitcherActions } from '.';

startAppListening({
  actionCreator: profileSwitcherActions.triggerStartSession,
  effect: async (action, listenerApi) => {
    try {
      listenerApi.dispatch(authActions.setIsAuthLoading(true));
      const authData = selectAuthSlice(listenerApi.getState());
      const selectedEmail = action.payload;

      if (authData.currentUser?.email !== selectedEmail) {
        const watcherResponse = await listenerApi.dispatch(
          loginApi.endpoints.watcher.initiate(selectedEmail),
        );

        if ('data' in watcherResponse) {
          const { user_id, refresh_token, access_token } = watcherResponse.data;

          storeRelationInfo();

          listenerApi.dispatch(profileSwitcherActions.setSessionActive(true));
          listenerApi.dispatch(authActions.setIsAuthLoading(true));

          listenerApi.dispatch(
            authActions.authorise({
              access_token,
              refresh_token,
              user_id,
            }),
          );

          listenerApi.dispatch(coreApi.util.invalidateTags(rtkApiTags));
          routerHistory.push(paths.sites);
          listenerApi.dispatch(resetSites()); // TODO: Remove when sites endpoint migrated to RTK
          listenerApi.dispatch(fetchedSites({ page: 1, pageSize: 5 })); // TODO: Remove when sites endpoint migrated to RTK
        } else {
          throw new Error();
        }
      }

      listenerApi.dispatch(authActions.setIsAuthLoading(false));
    } catch (error) {
      listenerApi.dispatch(
        createAlert({
          message: 'Error occured while switching user',
          variant: 'error',
        }),
      );

      storage.remove('accessTokenMaster');
      storage.remove('refreshTokenMaster');
      listenerApi.dispatch(profileSwitcherActions.setSessionActive(false));
      listenerApi.dispatch(authActions.setIsAuthLoading(false));
    }
  },
});

startAppListening({
  actionCreator: profileSwitcherActions.triggerStopSession,
  effect: async (action, listenerApi) => {
    try {
      listenerApi.dispatch(authActions.setIsAuthLoading(true));
      listenerApi.dispatch(
        authActions.authorise({
          access_token: storage.get<string>('accessTokenMaster')!,
          refresh_token: storage.get<string>('refreshTokenMaster')!,
          user_id: parseInt(storage.get<string>('userIdMaster')!, 10),
        }),
      );

      listenerApi.dispatch(profileSwitcherActions.setSessionActive(false));
      listenerApi.dispatch(authActions.setIsAuthLoading(false));

      storage.remove('accessTokenMaster');
      storage.remove('refreshTokenMaster');
      storage.remove('userIdMaster');

      listenerApi.dispatch(coreApi.util.invalidateTags(rtkApiTags));
      routerHistory.push(paths.sites);
      listenerApi.dispatch(resetSites()); // TODO: Remove when sites endpoint migrated to RTK
      listenerApi.dispatch(fetchedSites({ page: 1, pageSize: 5 })); // TODO: Remove when sites endpoint migrated to RTK
    } catch (error) {
      listenerApi.dispatch(
        createAlert({
          message: 'Error occured while returning to your main profile',
          variant: 'error',
        }),
      );
      listenerApi.dispatch(authActions.logout());
    }
  },
});

startAppListening({
  actionCreator: authActions.logout,
  effect: async () => {
    storage.remove('accessTokenMaster');
    storage.remove('refreshTokenMaster');
    storage.remove('userIdMaster');
  },
});

startAppListening({
  actionCreator: authActions.reauthorise,
  effect: async (action, listenerApi) => {
    const refreshTokenMaster = storage.get<string>('refreshTokenMaster');
    const accessTokenMaster = storage.get<string>('accessTokenMaster');

    if (refreshTokenMaster && accessTokenMaster) {
      listenerApi.dispatch(profileSwitcherActions.setSessionActive(true));
    }
  },
});
