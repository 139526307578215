import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { AppStateType } from '@appTypes/redux';
import { createCurrentPageSelector } from '@redux/common/createCurrentPageSelector';

export const getSitesSelector = createCurrentPageSelector<
  AppStateType['sites'],
  'sites',
  SitesSelectorReturn
>('sites', 'currentPageSites');

export type SitesSelectorReturn = AppStateType['sites'] & {
  currentPageSites: WPage<SiteSimpleDto>[];
};
