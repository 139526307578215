import { PropsWithChildren, createContext, useState } from 'react';

export type Permission = 'user:write' | 'user:read';
const admin: Permission[] = ['user:write', 'user:read'];

type PermissionContextValue = {
  permissions: Permission[];
};
export const PermissionContext = createContext<Nullable<PermissionContextValue>>(null);

export const PermissionProvider = ({ children }: PropsWithChildren) => {
  const [permissions] = useState<Permission[]>(admin);
  return (
    <PermissionContext.Provider value={{ permissions }}>{children}</PermissionContext.Provider>
  );
};
