import { Suspense, useEffect, useLayoutEffect } from 'react';
import { ScrollToTop } from '@components/ScrollToTop/ScrollToTop';
import { Spinner } from '@components/Spinner';
import { Grid } from '@mui/material';
import { updateCurrentRoute } from '@redux/ducks/routerHistory/actionCreators';
import { routerHistory } from '@redux/ducks/routerHistory/reducer';
import { getRouterHistorySelector } from '@redux/ducks/routerHistory/selectors';
import { authActions, selectAuthSlice } from '@redux/slices/auth';
import { useAlertService } from '@services/AlertService';
import { paths } from 'paths';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Route, Routes, Router } from 'react-router-dom';
import { routes } from 'routes';
import { AuthenticatedWrapper } from './AuthenticatedWrapper/AuthenticatedWrapper';
import { Cookies } from './Cookies/Cookies';
import { UnauthenticatedWrapper } from './UnauthenticatedWrapper/UnauthenticatedWrapper';

export const AppWrapper = () => {
  useAlertService();

  const { isAuth, loading } = useSelector(selectAuthSlice);
  const {
    currentRoute: { action, location },
  } = useSelector(getRouterHistorySelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.reauthorise());
  }, [dispatch]);

  useLayoutEffect(() => {
    routerHistory.listen((update) => dispatch(updateCurrentRoute(update)));
  }, [dispatch]);

  const renderFullscreenSpinner = () => (
    <Grid height="100vh">
      <Spinner />
    </Grid>
  );

  const renderWithSuspense = (children: Nullable<JSX.Element>) => (
    <Suspense fallback={renderFullscreenSpinner()}>{children}</Suspense>
  );

  const getRouteElement = (
    isPaddingActive: boolean,
    Component: React.ComponentType,
    authRequired: boolean,
  ) => {
    if (loading) {
      return renderFullscreenSpinner();
    }

    if (!authRequired) {
      switch (isAuth) {
        case true:
          return <Navigate to={paths.sites} />;
        default:
          return (
            <UnauthenticatedWrapper>{renderWithSuspense(<Component />)}</UnauthenticatedWrapper>
          );
      }
    }

    if (authRequired) {
      switch (isAuth) {
        case true:
          return (
            <AuthenticatedWrapper isPaddingActive={isPaddingActive}>
              {renderWithSuspense(<Component />)}
            </AuthenticatedWrapper>
          );
        default:
          return <Navigate to={paths.login} />;
      }
    }

    return null;
  };

  return (
    <Grid height="100%">
      <Router navigator={routerHistory} location={location} navigationType={action}>
        <ScrollToTop />
        <Cookies />
        <Routes>
          <Route path="*" element={<Navigate to={isAuth ? paths.sites : paths.login} />} />
          {routes.map(({ isPaddingActive, path, Component, authRequired }) => {
            const paddingActive = isPaddingActive === undefined ? true : isPaddingActive;
            const strPath = typeof path === 'function' ? path(null) : path;

            return (
              <Route
                key={strPath}
                path={strPath}
                element={getRouteElement(paddingActive, Component, authRequired)}
              />
            );
          })}
        </Routes>
      </Router>
    </Grid>
  );
};
