import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { ApexSeries, GraphConfig } from '../types';
import { formatNumberWithUnit } from '../utils/helpers';

// Note: This file has exception not to using colors from palette as it does not supports MUI theme
const containerStyle: React.CSSProperties = {
  fontFamily: 'Helvetica, Arial',
  fontSize: '12px',
};

const headerStyle: React.CSSProperties = {
  backgroundColor: '#333',
  color: 'whitesmoke',
  padding: '5px 10px',
  fontWeight: 'bold',
};

const itemStyle: React.CSSProperties = {
  borderLeft: '5px solid',
  padding: '3px 10px',
  borderTop: '1px solid #ddd',
  fontWeight: 'normal',
  backgroundColor: 'inherit',
};

interface ChartTooltipProps {
  series: ApexSeries[];
  timestamp: number | undefined;
  dataPointIndex: number;
  options: ApexOptions;
  hiddenSeries: number[];
  graphConfig: GraphConfig[];
}

/* 
  Note: Is not used as standart React component.
  It is parsed into plain html string and used in 
  Apexcharts custom tooltip callback instead.
  It does not support styled components nor Mui, can be styled by style prop only.
*/
const ChartTooltip = ({
  dataPointIndex,
  timestamp,
  series,
  options,
  hiddenSeries,
  graphConfig,
}: ChartTooltipProps) => {
  const items = series.map((s, index) => {
    const { name, data } = s;
    const value = data[dataPointIndex]?.y;

    const itemColor = Array.isArray(options?.colors)
      ? options?.colors[index]
      : options?.colors || '#000';

    const { label, unit } = graphConfig.find((info) => info.name === name) || {
      field: '',
      unit: '',
    };

    const formattedValue = formatNumberWithUnit(value || 0, unit || '');

    if (hiddenSeries.includes(index)) {
      return null;
    }

    return (
      <div key={`${name}`} style={{ ...itemStyle, borderLeftColor: itemColor }}>
        {label}:&nbsp;<strong>{formattedValue}</strong>
      </div>
    );
  });

  return (
    <section style={containerStyle}>
      <header style={headerStyle}>{timestamp ? format(timestamp, 'dd MMM yyyy HH:mm') : ''}</header>
      <div>{items}</div>
    </section>
  );
};

export default ChartTooltip;
