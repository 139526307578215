import { ENDPOINT_WITH_FILTERS, FilterParamsCommonDto } from '@appTypes/models/common.dto';
import {
  EcdHealthCheckResponse,
  EcdResponse,
  EcdSimpleRemoteDto,
  UpdateEcdDto,
} from '@appTypes/models/ecd.dto';
import { EnergyFlowDto } from '@appTypes/models/energyFlow.dto';
import { createEndpointQueryForAllItems, createParams } from '@utils/utils';
import { coreApi, extraParams } from './core';

export type EcdsData = {
  id: string;
  name: string;
}[];

export const impsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getEcds: builder.query<EcdResponse, FilterParamsCommonDto>({
      query: ({ ...params }) => `imps?${createParams(params)}`,
      providesTags: ['Ecds'],
    }),
    getEcdStatus: builder.query<EcdHealthCheckResponse, string>({
      query: (impId) => `imps/${impId}/healthcheck`,
      providesTags: (result, error, impId) => [{ type: 'EcdHealth', id: impId }],
    }),
    getEnergyFlow: builder.query<EnergyFlowDto, string>({
      query: (impId) => `imps/${impId}/energy-flow?${createParams(extraParams)}`,
    }),
    restartECD: builder.query<any, string>({
      query: (impId) => `imps/${impId}/restart`,
    }),
    getEcdsForSelect: builder.query<EcdsData, void>({
      query: () => `${createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.IMPS)}&order_by=id`,
      transformResponse: (response: EcdResponse) => {
        const ecdsData = [...response.imps].map(({ id, name }) => ({
          id,
          name,
        }));

        return ecdsData;
      },
    }),
    addEcdToSite: builder.mutation<EcdSimpleRemoteDto, Omit<UpdateEcdDto, 'name'>>({
      query: ({ id, ...payload }) => ({
        url: `imps/${id}`,
        method: 'PATCH',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: (result, error, { site_id }) => [{ type: 'Site', site_id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEcdsQuery,
  useGetEcdStatusQuery,
  useGetEnergyFlowQuery,
  useLazyRestartECDQuery,
  useGetEcdsForSelectQuery,
  useAddEcdToSiteMutation,
} = impsApi;
