import { AlarmListDto } from '@appTypes/models/alarm.dto';
import { FilterParamsDto } from '@appTypes/models/common.dto';
import CONFIG from '@config';
import { createParams } from '@utils/utils';
import { coreApi } from './core';

const alarmsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getAlarms: builder.query<
      AlarmListDto,
      PropertyNullable<Omit<FilterParamsDto, 'order_by'>, 'is_active' | 'site_id'>
    >({
      query: ({ ...params }) => `alarms?${createParams(params)}`,
      keepUnusedDataFor: CONFIG.ENDPOINTS.CACHE_DURATION.ALARMS,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAlarmsQuery } = alarmsApi;
