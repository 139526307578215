import { FilterParamsDto } from '@appTypes/models/common.dto';
import {
  CreateNotificationRuleDto,
  NotificationRuleDto,
  NotificationRuleListDto,
  UpdateNotificationRuleDto,
} from '@appTypes/models/notificationRule.dto';
import { createParams } from '@utils/utils';
import { coreApi } from './core';

const notificationRulesApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationRules: builder.query<
      NotificationRuleListDto,
      PropertyNullable<Omit<FilterParamsDto, 'order_by'>, 'is_active' | 'site_id'>
    >({
      query: ({ ...params }) => `rules?${createParams(params)}`,
      providesTags: ['NotificationRules'],
    }),
    getNotificationRule: builder.query<NotificationRuleDto, number>({
      query: (id) => `rules/${id}`,
    }),
    createNotificationRule: builder.mutation<NotificationRuleDto, CreateNotificationRuleDto>({
      query: (data) => ({
        url: `rules`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NotificationRules'],
    }),
    updateNotificationRule: builder.mutation<string, UpdateNotificationRuleDto>({
      query: ({ id, ...data }) => ({
        url: `rules/${id}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: ['NotificationRules'],
    }),
    deleteNotificationRule: builder.mutation<string, number>({
      query: (notificationRuleId) => ({
        url: `rules/${notificationRuleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NotificationRules'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotificationRulesQuery,
  useCreateNotificationRuleMutation,
  useDeleteNotificationRuleMutation,
  useUpdateNotificationRuleMutation,
  useGetNotificationRuleQuery,
} = notificationRulesApi;
