import { CenterX } from '@components/LayoutUtils';
import { Typography } from '@mui/material';
import { format, isSameDay } from 'date-fns';

type DateRangeProps = {
  start: Date;
  end: Date;
};

export const DateRangeDisplay = ({ start, end }: DateRangeProps) => {
  const sameDay = isSameDay(start, end);
  return (
    <CenterX>
      <Typography variant="body1" color="primary">
        {format(start, 'MMM dd, yyyy')}
      </Typography>

      {!sameDay ? (
        <>
          <Typography mx={1}>-</Typography>
          <Typography variant="body1" color="primary">
            {format(end, 'MMM dd, yyyy')}
          </Typography>
        </>
      ) : undefined}
    </CenterX>
  );
};
