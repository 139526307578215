import { getDeviceDetailsFromField } from './deviceField';
import { getChangedVals } from './getChangedVals';

export const getDevicesToUpdate = (
  initValue: { [key: string]: string | undefined },
  formValue: { [key: string]: string | undefined },
) => {
  const changedValues = getChangedVals(initValue, formValue);

  const changedValuesEntries = Object.entries(changedValues);

  return {
    devicesChangedValues: changedValues,
    devicesToUpdate: changedValuesEntries.reduce<{
      [key: string]: string;
    }>((acc, [field, value]) => {
      const { id, fieldName } = getDeviceDetailsFromField(field);

      return { ...acc, [`${id}.${fieldName}`]: value as string };
    }, {}),
  };
};
