import { useGetOrganizationQuery } from '@apiRtk/organizations';
import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useFilters, useRedirect } from '@hooks';
import { Box, Button } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';

type SitesGridCellProps = {
  name: string;
  id: number;
};

const CellWithRedirectButton = ({ name, id }: SitesGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{name}</span>
      <Button
        variant="outlined"
        onClick={() => {
          redirect((paths) => paths.configurationSite(id));
        }}
      >
        Site detail
      </Button>
    </Box>
  );
};

const columns: ColumnDef<SiteSimpleDto>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1 }}>
        <CellWithRedirectButton name={row.original.name} id={row.original.id} />
      </div>
    ),
  },
];

interface SitesRelationsProps {
  organizationId: number;
}

export const SitesRelations = ({ organizationId }: SitesRelationsProps) => {
  const searchFields = ['name'];

  const { data } = useGetOrganizationQuery(organizationId);

  const { sorting, searchString, pagination, setSearchString, setPagination } = useFilters({
    urlMirroringActive: false,
    sortingActive: false,
    pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE_SMALL,
    sortingState: [{ id: 'name', desc: false }],
  });

  const sites = data?.sites || [];

  const filteredData =
    searchString === ''
      ? sites
      : sites.filter((item) =>
          searchFields.some((field) => {
            const searchTxtLowercase = searchString.toLowerCase();
            const itemFieldVal = (item[field as keyof SiteSimpleDto] as string)?.toLowerCase();
            return itemFieldVal?.includes(searchTxtLowercase);
          }),
        );

  const paginatedData = filteredData.slice(
    pagination.pageIndex * pagination.pageSize,
    (pagination.pageIndex + 1) * pagination.pageSize,
  );

  return (
    <>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<SiteSimpleDto>
        columns={columns}
        rows={paginatedData}
        itemsCount={filteredData.length}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={() => {}}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};
