import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { FlashOn } from '@mui/icons-material';
import { useEcdConfigurationContext } from '@providers/EcdConfigurationProvider';
import { paths } from 'paths';

export const EcdConfigurationHeader = ({ title }: { title: string | undefined }) => {
  const { isFormFreezed } = useEcdConfigurationContext();

  return (
    <>
      <ButtonBack
        sx={{ ml: 1 }}
        disabled={isFormFreezed}
        path={paths.configuration}
        query={{ selectedItem: ConfiguredItem.ECDS }}
      >
        Back to configuration list
      </ButtonBack>
      <TitleBar my={4} title={`${title || 'ECD'} - Configuration`} IconComponent={FlashOn} />
    </>
  );
};
