import { PropsWithChildren } from 'react';
import { usePermission } from '@hooks';
import { Permission } from '@providers/PermissionProvider';

interface CanProps {
  permissions?: Permission | Permission[];
}

export const Can = ({ children, permissions = [] }: PropsWithChildren<CanProps>) => {
  const { permissions: userPermission } = usePermission();
  let match = false;
  const permissionsArr = Array.isArray(permissions) ? permissions : [permissions];
  if (permissionsArr.length === 0) {
    match = true;
  } else {
    match = permissionsArr.some((p) => userPermission.includes(p));
  }
  if (match && children) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return null;
};
