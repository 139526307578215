import { UserRole } from '@appTypes/models/user.dto';
import { ReactComponent as AdminIcon } from '@icons/admin.svg';
import { ReactComponent as SuperAdminIcon } from '@icons/superadmin.svg';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

const RoleIcon = ({ role }: { role?: UserRole }) => {
  if (!role) {
    return <PersonRoundedIcon color="primary" />;
  }

  if (role === UserRole.ADMIN) {
    return <AdminIcon color="primary" />;
  }

  if (role === UserRole.SUPERADMIN) {
    return <SuperAdminIcon color="primary" />;
  }
  return <PersonRoundedIcon color="primary" />;
};

export default RoleIcon;
