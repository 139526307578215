import { AlarmOff, AlarmOn } from '@mui/icons-material';
import { Box } from '@mui/material';

const ActivityIndicator = ({ active }: { active: boolean }) => (
  <Box title={active ? 'Alarm is active' : 'Alarm is inactive'}>
    {active ? (
      <AlarmOn color="warning" data-testid="alarm-on" />
    ) : (
      <AlarmOff data-testid="alarm-off" />
    )}
  </Box>
);

export default ActivityIndicator;
