import { instance } from '@api/instance';
import { storage } from '@services/localStorage';
import { BroadcastChannel } from 'broadcast-channel';

export const setAccessTokenHeader = (accessToken: string) => {
  storage.set('accessToken', accessToken);

  instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const storeRelationInfo = () => {
  storage.set('accessTokenMaster', storage.get('accessToken'));
  storage.set('refreshTokenMaster', storage.get('refreshToken'));
  storage.set('userIdMaster', storage.get('userId'));
};

export const authBroadcastChannel = new BroadcastChannel('authChannel');
export const authBroadcastLogoutMsg = 'logout';
