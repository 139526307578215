import * as Yup from 'yup';
import { createStringValidator } from './createStringValidator';
import { checkMax } from './max';
import { checkMin } from './min';

export const checkPostalCode = (label: string, validator = Yup.string()) =>
  createStringValidator(checkMin(4), checkMax(10))(
    label,
    validator.matches(/^[A-Z0-9 -]*$/, `${label} must be valid`),
  );
