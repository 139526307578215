import { DefaultFilters } from '@appTypes/helpers/redux';
import { AppStateType } from '@appTypes/redux';

type ItemPageExtension = WPage<unknown>;
type State<T extends string> = {
  [prop in T]: Nullable<ItemPageExtension[]>;
} & { filters: DefaultFilters };

const getCurrentPage = <TItemsListName extends string>(
  state: State<TItemsListName>,
  name: TItemsListName,
) => {
  if (!state || !state[name]) return [];

  return (state[name] as ItemPageExtension[]).filter((item) => item._page === state.filters.page);
};

export const createCurrentPageSelector =
  <T extends State<TItemsListName>, TItemsListName extends string, TReturnType>(
    name: keyof AppStateType,
    currentName: string,
  ) =>
  (state: AppStateType) =>
    ({
      ...state[name],
      [currentName]: getCurrentPage(
        state[name] as unknown as T,
        name as unknown as keyof AppStateType[keyof AppStateType],
      ),
    }) as unknown as TReturnType;
