import { useCallback, useEffect } from 'react';
import { ActionCreator, DefaultFilters, UpdateFilterActionType } from '@appTypes/helpers/redux';
import { AppStateType } from '@appTypes/redux';
import { OnSortingChange } from '@components/XDataGrid/XDataGrid';
import { GridSortModel } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

type UseXDataGridConnectParams<TSelectorReturn> = {
  selector: (state: AppStateType) => TSelectorReturn;
  currentItemsField: keyof TSelectorReturn;
  fetchOnInit?: boolean;
  fetchPageAction: ActionCreator<{
    type: any;
    payload: { page: number; pageSize: number };
  }>;
  updateFilterAction: ActionCreator<UpdateFilterActionType<any, DefaultFilters>>;
};

export function useXDataGridConnect<
  TSelectorReturn extends {
    filters: DefaultFilters;
    itemsCount: number;
    loading: boolean;
  },
  TItem = unknown,
>({
  selector,
  currentItemsField,
  fetchOnInit = true,
  fetchPageAction,
  updateFilterAction,
}: UseXDataGridConnectParams<TSelectorReturn>) {
  const dispatch = useDispatch();

  const selectorReturn = useSelector(selector);

  const {
    loading,
    itemsCount,
    filters: { page, pageSize, orderBy: filterOrderBy, descending: filterDescending },
  } = selectorReturn;

  const remoteSortModel: GridSortModel = !filterOrderBy
    ? []
    : [{ field: filterOrderBy, sort: filterDescending ? 'desc' : 'asc' }];

  const fetchPage = useCallback(
    (val: number) => {
      dispatch(fetchPageAction({ page: val, pageSize }));
    },
    [dispatch, fetchPageAction, pageSize],
  );

  const onSortingChange: OnSortingChange = ({ orderBy, descending }) => {
    dispatch(
      updateFilterAction([
        ['orderBy', orderBy],
        ['descending', descending],
      ]),
    );
  };

  useEffect(() => {
    if (!fetchOnInit) return;

    fetchPage(page);
  }, [fetchOnInit, fetchPage, page]);

  return {
    items: selectorReturn[currentItemsField] as unknown as TItem[],
    itemsCount,
    loading,
    page,
    pageSize,
    onPaginationChange: fetchPage,
    remoteSortModel,
    onSortingChange,
  };
}
