import { paths, Path } from 'paths';
import { matchRoutes } from 'react-router';

const pathValues = Object.values(paths);

export const isSamePath = (stringPath: string, pathToCompare: Path) => {
  const routes = pathValues
    .filter((path) => path === pathToCompare)
    .map((path) => {
      if (typeof path === 'string') {
        return { path };
      }

      return { path: path(':id') };
    });

  return matchRoutes(routes, stringPath)?.length === 1;
};
