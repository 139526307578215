import { ApiError } from '@appTypes/api/error';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { prepareError } from '@utils/data/prepareError';
import { put } from 'redux-saga/effects';

export function* sagaApiErrorHandler(error: unknown): unknown {
  if ((error as ApiError)?.response?.data?.detail) {
    yield put(
      createAlert({
        message: prepareError(error as ApiError)?.message || '',
        variant: 'error',
      }),
    );
  }
}
