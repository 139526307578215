import { useState } from 'react';
import { ButtonCancel, ButtonPrimary } from '@components/Buttons/Buttons';
import { Box, Drawer, Typography } from '@mui/material';
import { storage } from '@services/localStorage';
import { space } from '@utils/utils';
import { palette } from 'styles/palette';

export const Cookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(!!storage.get('cookiesAccepted') || false);
  const [cookiesRejected, setCookiesRejected] = useState(!!storage.get('cookiesRejected') || false);

  const onConfirmClick = () => {
    setCookiesAccepted(true);
    storage.set('cookiesAccepted', true);
    storage.remove('cookiesRejected');
  };

  const onRejectClick = () => {
    setCookiesRejected(true);
    storage.set('cookiesRejected', true);
    storage.remove('cookiesAccepted');
  };

  return (
    <Drawer
      open={!cookiesAccepted && !cookiesRejected}
      anchor="bottom"
      variant="persistent"
      PaperProps={{
        sx: {
          maxWidth: 750,
          margin: '0 auto',
          border: 'none',
          borderRadius: `${space(1)} ${space(1)} 0px 0px`,
        },
      }}
    >
      <Box p={2} display="grid" gridTemplateColumns="1fr auto" bgcolor={palette.neutral.light}>
        <Typography variant="subtitle1">
          Our application collects and monitors user data for google analytics. Please accept the
          condition to select the best content for you.
        </Typography>
        <Box>
          <ButtonPrimary onClick={onConfirmClick}>Confirm</ButtonPrimary>
          <ButtonCancel sx={{ ml: space(1) }} onClick={onRejectClick}>
            Reject
          </ButtonCancel>
        </Box>
      </Box>
    </Drawer>
  );
};
