type PatternProps = React.SVGProps<SVGPatternElement> & {
  id: string;
  svgSize?: number;
  rotation?: number;
};

const Pattern = ({
  children,
  rotation = 0,
  svgSize = 24,
  ...patternProps
}: React.PropsWithChildren<PatternProps>) => {
  const svgCenter = svgSize / 2;

  return (
    <defs>
      <pattern width="100%" height="100%" viewBox={`0 0 ${svgSize} ${svgSize}`} {...patternProps}>
        {true && <g transform={`rotate(${rotation} ${svgCenter} ${svgCenter})`}>{children}</g>}
      </pattern>
    </defs>
  );
};

export default Pattern;
