export enum ConfigurationItem {
  ECDS = 'ECDS',
  SITES = 'SITES',
  ORGANIZATIONS = 'ORGANIZATIONS',
  USERS = 'USERS',
}

export type ConfigurationTabItem = {
  id: ConfigurationItem;
  label: string;
};

export type ConfigurationTabItemCount = {
  id: ConfigurationItem;
  itemsCount: number;
};
