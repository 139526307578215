import {
  StringValidator,
  createStringValidator,
} from '@utils/validators/string/createStringValidator';
import { checkEMail } from '@utils/validators/string/eMail';
import { checkMax } from '@utils/validators/string/max';
import { checkMin } from '@utils/validators/string/min';
import { checkPassword } from '@utils/validators/string/password';
import { checkRequired } from '@utils/validators/string/required';
import * as Yup from 'yup';

const createValidationSchema = (name: string, rules: StringValidator) =>
  Yup.object().shape(
    {
      [name]: rules,
    },
    [
      ['code', 'code'],
      ['password', 'password'],
    ],
  );

export const emailValidation = createValidationSchema(
  'email',
  createStringValidator(checkRequired, checkEMail)('E-mail'),
);

export const verifyCodeValidation = createValidationSchema(
  'code',
  createStringValidator(checkRequired, checkMin(6), checkMax(6))('Verification code'),
);

export const passwordValidation = createValidationSchema(
  'password',
  createStringValidator(checkRequired, checkPassword)('Password'),
);
