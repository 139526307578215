import { useMemo } from 'react';
import { EnergyFlowDto } from '@appTypes/models/energyFlow.dto';
import ConditionalWrapper from '@components/ConditionalWrapper/ConditionalWrapper';
import { useDynamicWidth } from '@hooks';
import { styled } from '@mui/material';
import DeviceNode from './components/DeviceNode';
import Loader from './components/Loader';
import { wattstorSvg } from './elements';
import { calculateCount, getMainValues, getSanitisedParameter } from './helpers';
import { DiagramConfig } from './types';

const Center = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled('div')`
  margin: 0 auto;
  max-width: 1200px;
  min-width: 400px;
  position: relative;
  overflow: hidden;
`;

const Logo = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 10;
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;
`;

type DiagramProps = {
  config: DiagramConfig;
  data?: EnergyFlowDto;
  loading?: boolean;
};

export const Diagram = ({ config, loading = false, data }: DiagramProps) => {
  const { width, parentRef } = useDynamicWidth();

  const canvasSize = Math.max(400, Math.min(1200, width));
  const diagramRadius = canvasSize / 4.5;
  const diagramDiameter = diagramRadius * 2;
  const heightAspectRatio = 0.5;
  const logoSize = Math.ceil(diagramRadius / 2.2);
  const cardSize = Math.max(canvasSize / 14, 48);
  const dataPrepared = !loading && data;

  const mainValues = useMemo(
    () => (dataPrepared ? getMainValues(data, config) : []),
    [dataPrepared, data, config],
  );

  return (
    <div>
      <Wrapper
        ref={parentRef}
        style={{
          width: '100%',
          height: canvasSize * heightAspectRatio,
        }}
      >
        {dataPrepared &&
          Object.keys(config).map((key) => {
            const deviceData = data[key as keyof typeof data];
            if (!deviceData) {
              return null;
            }

            const { parameters: paramObj } = deviceData;
            const parametersWithName = Object.entries(paramObj).map(([name, parameter]) =>
              getSanitisedParameter({ parameter, name, deviceName: key }),
            );

            if (!parametersWithName.length) {
              return null;
            }

            const { IconComponent, title, energyColor, reverseFlow, mainParameterName, angle } =
              config[key];

            let orderedParameters = parametersWithName;

            if (mainParameterName) {
              // order mainParameter first, if defined in config

              orderedParameters = [...parametersWithName].sort((a) =>
                a.name === mainParameterName ? -1 : 1,
              );
            }

            const mainParameter = orderedParameters[0];

            const energyNodeCount = calculateCount(mainParameter.value || 0, mainValues);

            let propsOveride = {};

            if (key === 'ev') {
              const radians = 160 * (Math.PI / 180);

              const center = canvasSize / 2;

              const x = center + Math.cos(radians) * diagramRadius;
              const y = center * heightAspectRatio + Math.sin(radians) * diagramRadius;

              const halfCardSize = cardSize / 2;

              const rotation = 22.5;

              propsOveride = {
                style: {
                  position: 'absolute',
                  left: x - halfCardSize,
                  top: y - halfCardSize,
                },
                rotation,
                wireLength: diagramDiameter * Math.sin((rotation * Math.PI) / 180),
              };
            }

            return (
              <ConditionalWrapper Wrapper={Center} condition={key !== 'ev'} key={key}>
                <DeviceNode
                  cardSize={cardSize}
                  IconComponent={IconComponent}
                  parameters={orderedParameters}
                  title={title}
                  energyColor={energyColor}
                  count={energyNodeCount}
                  rotation={angle}
                  wireLength={diagramRadius}
                  reverse={
                    reverseFlow ? (mainParameter.value || 0) < 0 : (mainParameter.value || 0) > 0
                  }
                  {...propsOveride}
                />
              </ConditionalWrapper>
            );
          })}
        <Center>
          <Logo
            data-testid="logo"
            style={{
              width: `${logoSize}px`,
              height: `${logoSize}px`,
            }}
          >
            {wattstorSvg}
          </Logo>
          {loading ? (
            <Loader
              data-testid="loader"
              size={logoSize + 4}
              style={{ position: 'absolute', top: -2, left: -2 }}
              thickness={6}
            />
          ) : null}
        </Center>
      </Wrapper>
    </div>
  );
};
