import { useEffect, useState } from 'react';
import { HistoricalDatapoints } from '@appTypes/models/historicalData.dto';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { GraphConfig } from '../types';
import { convertDataArrToApex } from '../utils/convertDataArrToApex';

const createWorker = createWorkerFactory(() => import('../utils/convertDataArrToApex'));

export const useChartSeries = (data: HistoricalDatapoints, graphConfig: GraphConfig[]) => {
  const worker = useWorker(createWorker);

  const [series, setSeries] = useState<ReturnType<typeof convertDataArrToApex>>([]);

  useEffect(() => {
    if (data.length === 0) {
      setSeries([]);
      return;
    }

    (async () => {
      const newSeries = await worker.convertDataArrToApex(data, graphConfig);

      setSeries(newSeries);
    })();
  }, [data, graphConfig, worker]);

  return series;
};
