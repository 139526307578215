import { ReactNode } from 'react';
import { divideWithSI } from '@components/Chart/utils/helpers';
import { CenterX } from '@components/LayoutUtils';
import { usePalette } from '@hooks';
import { GridProps, styled } from '@mui/material';
import { space } from '@utils/utils';
import { TextLarge, TextNormal } from './components';

type CardAlignedItemProps = GridProps & {
  value: number;
  caption: string;
  subItem?: boolean;
  icon?: ReactNode;
  decimals?: number;
};

const Row = styled('div')`
  display: grid;
  gap: 8px;
  grid-template-columns: 70px 1fr;
  align-items: center;
`;

const CardAlignedItem = ({
  value,
  caption,
  subItem,
  icon,
  decimals = 1,
  ...props
}: CardAlignedItemProps) => {
  const { resultValue, prefix: prefixSI } = divideWithSI(value, decimals);
  const parsedValue = resultValue.toFixed(decimals);
  const currentPalette = usePalette();

  return (
    <Row>
      <CenterX gap={space(0.5)} justifyContent="space-between" {...props}>
        {icon || <div />}
        <TextLarge color="neutral100" smaller={subItem} sx={{ textAlign: 'right' }}>
          {parsedValue}
        </TextLarge>
      </CenterX>
      <TextNormal
        color={currentPalette.text.secondary}
        smaller={subItem}
        sx={{ textAlign: 'left' }}
      >
        {prefixSI}
        {caption}
      </TextNormal>
    </Row>
  );
};

export default CardAlignedItem;
