import { List, ListItem, ListItemText } from '@mui/material';

type FieldConfig = {
  label: string;
  value: Nullable<string | number | undefined>;
};

interface FormViewModeProps {
  fields: FieldConfig[];
  disabled?: boolean;
}

const FormViewMode = ({ fields, disabled }: FormViewModeProps) => (
  <List style={{ opacity: disabled ? 0.5 : 1 }}>
    {fields.map((field) => (
      <ListItem key={`${field.label}`}>
        <ListItemText primary={field.value || '-'} secondary={field.label} />
      </ListItem>
    ))}
  </List>
);

export default FormViewMode;
