type UserSettings = {
  graphsLegends: {
    graphId: string;
    invisibleSeries: string[];
  }[];
};

export const userSettings = {
  storage: localStorage,

  save(settings: UserSettings) {
    const serializedSettings = JSON.stringify(settings);
    this.storage.setItem('userSettings', serializedSettings);
  },

  get(): Nullable<UserSettings> {
    const storedSettings = this.storage.getItem('userSettings');
    return storedSettings ? JSON.parse(storedSettings) : null;
  },

  getInvisibleSeriesByGraphId(graphId: string): string[] {
    const storedSettings = this.get();
    return storedSettings
      ? storedSettings.graphsLegends.find((graph) => graph.graphId === graphId)?.invisibleSeries ||
          []
      : [];
  },

  saveInvisibleSeriesByGraphId(graphId: string, invisibleSeries: string[]) {
    const currentSettings = this.get();
    const legends = currentSettings?.graphsLegends || [];

    const existingGraphIndex = legends.findIndex((graph) => graph.graphId === graphId);

    if (existingGraphIndex !== -1) {
      legends[existingGraphIndex].invisibleSeries = invisibleSeries;
    } else {
      legends.push({ graphId, invisibleSeries });
    }

    this.save({
      ...currentSettings,
      graphsLegends: legends,
    });
  },
};
