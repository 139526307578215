import { DefaultRequestState } from '@redux/common/defaultRequestState';
import { useOnUpdate } from './useOnUpdate';

export const useRequestSuccess = (
  requestState: DefaultRequestState,
  onRequestSuccess: () => void,
) => {
  const { loading, error } = requestState;

  useOnUpdate(() => {
    if (!loading && !error) {
      onRequestSuccess();
    }
  }, [loading]);
};
