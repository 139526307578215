import { useGetNotificationRuleQuery } from '@apiRtk/notificationRules';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { Spinner } from '@components/Spinner';
import { Drawer } from '@mui/material';
import { DrawerContentWrapper } from './DrawerContentWrapper';
import { EditRule } from './EditRule';

interface EditRulePanelProps {
  selectedSiteId?: Nullable<number>;
  ruleId: Nullable<number>;
  createRuleMode: boolean;
  handleFormClose(): void;
}

export const EditRulePanel = ({
  selectedSiteId = null,
  ruleId,
  createRuleMode,
  handleFormClose,
}: EditRulePanelProps) => {
  const { refetch, data, isLoading, isFetching, isError } = useGetNotificationRuleQuery(ruleId!, {
    skip: !ruleId || createRuleMode,
    refetchOnMountOrArgChange: true,
  });

  if (createRuleMode) {
    return (
      <Drawer open anchor="right" variant="persistent">
        <EditRule selectedSiteId={selectedSiteId} handleFormClose={handleFormClose} />
      </Drawer>
    );
  }

  if (isError) {
    return (
      <AlertWithRefetch onRetryClick={refetch}>Error getting notification detail</AlertWithRefetch>
    );
  }

  return (
    <Drawer open={ruleId !== null} anchor="right" variant="persistent">
      {isLoading || isFetching || !data ? (
        <DrawerContentWrapper flex={1}>
          <Spinner />
        </DrawerContentWrapper>
      ) : (
        <EditRule selectedSiteId={selectedSiteId} rule={data!} handleFormClose={handleFormClose} />
      )}
    </Drawer>
  );
};
