import { useEffect } from 'react';
import { useGetGraphCustomSettingsQuery, useGetGraphsQuery } from '@apiRtk/dashboards';
import { DashboardGraphDto, GraphConfiguration } from '@appTypes/models/dashboard.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { DataExportButton } from '@components/Chart/ExportButton/DataExportButton';
import { GraphConfig, TimeSpan } from '@components/Chart/types';
import { getUnitSymbol } from '@components/Chart/utils/helpers';
import { HistoricalDataChart } from '@components/HistoricalDataChart/HistoricalDataChart';
import { CenterX, PaperLight } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Box, Typography } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { userSettings } from '@services/userSettings';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

type GraphProps = { timeSpan: TimeSpan } & GraphConfiguration;

const createGraphConfig = (graphConfiguration: DashboardGraphDto) => {
  if (!graphConfiguration.values) {
    return [];
  }

  const { values, yaxes } = graphConfiguration;

  return [...values]
    .sort((a, b) => a.yaxis - b.yaxis)
    .map(({ yaxis, field_name, name }) => {
      const { unit, min, max } = yaxes[yaxis - 1];
      const firstItemUnit = yaxes[0].unit;

      const configItem: GraphConfig = {
        name: field_name,
        label: name,
        min,
        max,
        unit,
        unitSymbol: getUnitSymbol(unit),
        leftSide: unit === firstItemUnit,
      };

      return configItem;
    });
};

export const Graph = ({ graph_id, graph_title, imp_id, device_id, timeSpan, id }: GraphProps) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  const {
    refetch,
    data: graphSchema,
    isLoading,
    error,
  } = useGetGraphsQuery(graph_id, {
    skip: !graph_id || !inView,
  });

  const { currentUser } = useSelector(selectAuthSlice);

  const userId = currentUser?.id;

  const { data: graphCustomSettings } = useGetGraphCustomSettingsQuery(
    {
      configurationGraphId: id,
      userId: userId!,
    },
    { skip: !userId },
  );

  const invisibleSeriesFromApi = graphCustomSettings?.invisible;

  useEffect(() => {
    if (invisibleSeriesFromApi?.length) {
      userSettings.saveInvisibleSeriesByGraphId(id, invisibleSeriesFromApi);
    }
  }, [id, invisibleSeriesFromApi]);

  let content = <Spinner />;
  if (!isLoading && (error || !graphSchema)) {
    content = (
      <AlertWithRefetch onRetryClick={refetch}>
        An error occurred while loading data for this graph
      </AlertWithRefetch>
    );
  }

  if (graphSchema) {
    const graphConfig = createGraphConfig(graphSchema);

    content = (
      <HistoricalDataChart
        graphConfig={graphConfig}
        ecdId={imp_id}
        deviceId={device_id}
        timeSpan={timeSpan}
        graphId={graph_id}
        graphConfigId={id}
      />
    );
  }

  return (
    <PaperLight
      sx={{ minHeight: 340, p: 2, my: 2, display: 'flex', flexDirection: 'column' }}
      ref={ref}
      data-testid="historical-graph-wrapper"
    >
      <CenterX>
        <Typography variant="subtitle1">{graph_title || 'Unknown device'}</Typography>
        {graphSchema?.id !== 'calculator' ? (
          <Box ml="auto">
            <DataExportButton ecdId={imp_id} deviceId={device_id} sourceType={graph_id} />
          </Box>
        ) : null}
      </CenterX>
      <Box marginTop="auto" marginBottom="auto">
        {inView ? content : null}
      </Box>
    </PaperLight>
  );
};
