import { createStringValidator } from '@utils/validators/string/createStringValidator';
import { checkEMail } from '@utils/validators/string/eMail';
import { checkFirstName } from '@utils/validators/string/firstName';
import { checkLastName } from '@utils/validators/string/lastName';
import { checkPhone } from '@utils/validators/string/phone';
import { checkRequired } from '@utils/validators/string/required';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  first_name: createStringValidator(checkRequired, checkFirstName)('First Name'),
  last_name: createStringValidator(checkRequired, checkLastName)('Last Name'),
  email: createStringValidator(checkRequired, checkEMail)('E-mail'),
  phone: createStringValidator(checkRequired, checkPhone)('Phone'),
  role_name: createStringValidator(checkRequired)('Role'),
});
