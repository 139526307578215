import { getRouterHistorySelector } from '@redux/ducks/routerHistory/selectors';
import { isSamePath } from '@utils/routing/isSamePath';
import { paths, Path } from 'paths';
import { useSelector } from 'react-redux';

export const useDataByPreviousPath = <T>(
  determineContent: (args: {
    isPreviousPath: (path: Path | Path[]) => boolean;
    noPreviousPath: boolean;
    paths: typeof paths;
  }) => T,
) => {
  const { previousRoute } = useSelector(getRouterHistorySelector);
  const previousPath = previousRoute?.location.pathname;

  const isPreviousPath = (pathToCheck: Path | Path[]) => {
    if (Array.isArray(pathToCheck)) {
      return pathToCheck.reduce((acc, forbiddenPath) => {
        if (acc) return true;

        return isSamePath(previousPath || '', forbiddenPath);
      }, false);
    }

    return isSamePath(previousPath || '', pathToCheck);
  };

  return determineContent({
    isPreviousPath,
    paths,
    noPreviousPath: !previousPath,
  });
};
