import { Tabs as MuiTabs } from '@mui/material';
import { TabsProps } from '@mui/material/Tabs';
import { palette } from 'styles/palette';

export function Tabs<TValue = string>({
  TabIndicatorProps = {},
  children,
  ...rest
}: Omit<TabsProps, 'onChange'> & {
  onChange(event: React.ChangeEvent<{}>, value: TValue): void;
}) {
  return (
    <MuiTabs
      {...rest}
      TabIndicatorProps={{
        style: {
          background: palette.primary.light,
          ...(TabIndicatorProps.style || {}),
        },
        ...TabIndicatorProps,
      }}
    >
      {children}
    </MuiTabs>
  );
}
