import { AppStateType } from '@appTypes/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  sessionActive: false,
};

export type ProfileSwitcherStateType = typeof initialState;

const profileSwitcherSlice = createSlice({
  name: 'profileSwitcher',
  initialState,
  reducers: {
    setSessionActive: (state, action: PayloadAction<boolean>) => {
      state.sessionActive = action.payload;
    },
    // eslint-disable-next-line
    triggerStartSession: (_, action: PayloadAction<string>) => {},
    triggerStopSession: () => {},
  },
});

export const selectProfileSwitcherSlice = (state: AppStateType) => state.profileSwitcher;

export const profileSwitcherActions = profileSwitcherSlice.actions;

export default profileSwitcherSlice.reducer;
