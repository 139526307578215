/* eslint-disable func-names */
import { DefaultFilters, DefaultItem } from '@appTypes/helpers/redux';
import { TakeableChannel } from 'redux-saga';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { createSafeSaga } from '../createSafeSaga';
import { create } from './create';
import { deleteItem } from './deleteItem';
import { getCurrentPage } from './getCurrentPage';
import { getList } from './getList';
import { getSingle } from './getSingle';
import { CreateCRUDWatcherParams, SelectorReturn } from './types';
import { update } from './update';

export function* createSagaCRUDWatcher<
  TSelector extends SelectorReturn,
  TItem extends DefaultItem,
  TItemCreate,
  TItemUpdate,
  TSimpleItem = TItem,
>({
  selector,
  setRequestError,
  setRequestLoading,
  getList: getListParams,
  getSingle: getSingleParams,
  create: createParams,
  update: updateParams,
  delete: deleteParams,
}: CreateCRUDWatcherParams<TSelector, TItem, TItemCreate, TItemUpdate, TSimpleItem>) {
  const setRequestsFns =
    setRequestError && setRequestLoading
      ? {
          setRequestError,
          setRequestLoading,
        }
      : null;

  if (getListParams) {
    yield takeLatest(
      getListParams.actionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* ({ payload }: { payload: DefaultFilters }) {
        yield getList<TSelector, TSimpleItem>({
          payload,
          selector,
          ...getListParams,
        });
      }),
    );

    yield takeLatest(
      getListParams.updateFilterActionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* () {
        yield getCurrentPage<TSelector>({
          selector,
          getListActionName: getListParams.actionName,
          clearCurrentActionName: getSingleParams?.clearCurrentActionName,
          ...getListParams,
        });
      }),
    );
  }

  if (getListParams?.getCountActionName) {
    yield takeLatest(
      getListParams.getCountActionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* () {
        yield getList<TSelector, TSimpleItem>({
          payload: {
            page: 0,
            pageSize: 0,
            search: '',
            orderBy: '',
            descending: false,
          },
          selector,
          ...getListParams,
        });
      }),
    );
  }

  const paramsOfSingleProvided = [getSingleParams, createParams, updateParams, deleteParams].find(
    (params) => params,
  );

  if (paramsOfSingleProvided && !setRequestsFns) {
    throw new Error(
      'Params for certain requests were provided but there were no setRequestError or setRequestLoading',
    );
  }

  if (!setRequestsFns) {
    return;
  }

  if (getSingleParams) {
    yield takeEvery(
      getSingleParams.actionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* ({ payload }: { payload: number }) {
        yield getSingle<TItem>({
          payload,
          ...getSingleParams,
          ...setRequestsFns,
        });
      }),
    );
  }

  if (createParams) {
    yield takeEvery(
      createParams.actionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* ({ payload }: { payload: TItemCreate }) {
        yield create<TItem, TItemCreate, TSelector>({
          payload,
          selector,
          getListActionName: getListParams?.actionName,
          clearCurrentActionName: getSingleParams?.clearCurrentActionName,
          ...createParams,
          ...setRequestsFns,
        });
      }),
    );
  }

  if (updateParams) {
    yield takeEvery(
      updateParams.actionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* ({ payload }: { payload: TItemUpdate }) {
        yield update<TItem, TItemUpdate>({
          payload,
          clearCurrentActionName: getSingleParams?.clearCurrentActionName,
          ...updateParams,
          ...setRequestsFns,
        });
      }),
    );
  }

  if (deleteParams) {
    yield takeEvery(
      deleteParams.actionName as unknown as TakeableChannel<unknown>,
      createSafeSaga(function* ({ payload }: { payload: number }) {
        yield deleteItem<TItem, TSelector>({
          payload,
          selector,
          getListActionName: getListParams?.actionName,
          clearCurrentActionName: getSingleParams?.clearCurrentActionName,
          ...deleteParams,
          ...setRequestsFns,
        });
      }),
    );
  }
}
