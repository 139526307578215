import { useGetUsersFilteredQuery } from '@apiRtk/users';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Flex } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Switch } from '@components/Switch';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect } from '@hooks';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { createFullName } from '@utils/data/createFullName';

const createColumns = (redirect: ReturnType<typeof useRedirect>): ColumnDef<UserSimpleDto>[] => [
  {
    header: 'Status',
    accessorKey: 'is_active',
    cell: ({ row }) => (
      <Flex style={{ maxWidth: '80px' }}>
        <Switch size="small" checked={row.original.is_active} />
      </Flex>
    ),
  },
  {
    header: 'Name',
    accessorKey: 'last_name',
    cell: ({ row }) => (
      <div style={{ flex: 0.5 }}>
        {createFullName(row.original.first_name, row.original.last_name, true)}
      </div>
    ),
  },
  {
    header: 'E-mail',
    accessorKey: 'email',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.email}</div>,
  },
  {
    id: 'organization.name',
    header: 'Organization',
    accessorKey: 'organization',
    cell: ({ row }) => (
      <div style={{ minWidth: '200px' }}>
        <DetailCell
          onDetailClick={() => redirect((paths) => paths.configurationUser(row.original.id))}
        >
          {row.original.organization?.name}
        </DetailCell>
      </div>
    ),
  },
];

export type UserListProps = {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const UserList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: UserListProps) => {
  const redirect = useRedirect();

  const columns = createColumns(redirect);

  const { data, isError, refetch, isLoading } = useGetUsersFilteredQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {isLoading && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>
          Some error occurred while getting Users, please try again later.
        </AlertWithRefetch>
      )}
      {data && !isLoading && (
        <TanStackTable<UserSimpleDto>
          columns={columns}
          rows={data.users || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
