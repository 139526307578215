import { DashboardsNavItem } from '@components/AppWrapper/AuthenticatedWrapper/Navbar/DashboardsNavItem/DashboardsNavItem';
import { SvgIconComponent } from '@mui/icons-material';
import AccessAlarmRounded from '@mui/icons-material/AccessAlarmRounded';
import AdminPanelSettingsRounded from '@mui/icons-material/AdminPanelSettingsRounded';
import BusinessRounded from '@mui/icons-material/BusinessRounded';
import NotificationsNoneRounded from '@mui/icons-material/NotificationsNoneRounded';
import SettingsRounded from '@mui/icons-material/SettingsRounded';
import { Permission } from '@providers/PermissionProvider';
import { paths } from 'paths';

type NavItem = {
  id: string;
  title: string;
  CustomItemComponent?: () => JSX.Element;
  path?: string;
  permissions?: Permission[];
  alwaysExpanded?: boolean;
  IconComponent: SvgIconComponent;
  wattstorUserOnly?: boolean;
  items?: {
    id: string;
    title: string;
    path: string;
  }[];
};

export const navItems = [
  {
    title: 'Admin tools',
    IconComponent: AdminPanelSettingsRounded,
    path: paths.adminTools,
    wattstorUserOnly: true,
  },
  {
    title: 'Your sites',
    IconComponent: BusinessRounded,
    path: paths.sites,
    CustomItemComponent: DashboardsNavItem,
    alwaysExpanded: true,
  },
  {
    title: 'Global alarms',
    IconComponent: AccessAlarmRounded,
    path: paths.alarms,
  },
  {
    title: 'Global notifications',
    IconComponent: NotificationsNoneRounded,
    path: paths.notifications,
  },
  {
    title: 'Global configuration',
    IconComponent: SettingsRounded,
    path: paths.configuration,
  },
].map((navItem, navItemIndex) => {
  const navItemWithId = {
    ...navItem,
    id: `${navItemIndex}`,
  };

  return navItemWithId;
}) as NavItem[];
