import CONFIG from '@config';
import { AlertService } from '@services/AlertService';
import _ from 'lodash';
import { delay, getContext, put, takeEvery } from 'redux-saga/effects';
import { hideAlert, showAlert } from './actionCreators';
import { AlertsActionsTypes, CreateAlert, HideAlert, ShowAlert } from './actionTypes';

function* createAlertSaga({ payload }: CreateAlert) {
  const id = _.uniqueId();

  yield put(showAlert({ ...payload, id }));
}

function* showAlertSaga({ payload }: ShowAlert) {
  const alertService: AlertService = yield getContext('alertService');

  yield alertService.showAlert(payload);
  const { timeout, id } = payload;

  if (timeout === null) return;

  const alertTimeout = timeout === undefined ? CONFIG.ALERT_AUTO_HIDE_DURATION_MS : timeout;

  yield delay(alertTimeout);

  yield put(hideAlert(id));
}

function* hideAlertSaga({ payload }: HideAlert) {
  const alertService: AlertService = yield getContext('alertService');

  yield alertService.hideAlert(payload);
}

export function* alertsWatcher() {
  yield takeEvery(AlertsActionsTypes.CREATE_ALERT, createAlertSaga);
  yield takeEvery(AlertsActionsTypes.SHOW_ALERT, showAlertSaga);
  yield takeEvery(AlertsActionsTypes.HIDE_ALERT, hideAlertSaga);
}
