import { useEffect, useRef } from 'react';
import { fetchedEcdsStatuses } from '@redux/ducks/ecds/actionCreators';
import { getEcdsSelector } from '@redux/ducks/ecds/selectors';
import { useSelector, useDispatch } from 'react-redux';

export const useEcdsStatuses = () => {
  const firstLoad = useRef(true);

  const {
    loading,
    error,
    filters: { page },
  } = useSelector(getEcdsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }

    if (loading || error) {
      return;
    }

    dispatch(fetchedEcdsStatuses(page));
  }, [loading, page, error, dispatch]);
};
