import { useMemo } from 'react';
import { Flex } from '@components/LayoutUtils';
import { ZoomOut, ZoomIn } from '@mui/icons-material';
import {
  differenceInMinutes,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { OnDateApplyClick } from '../types';

type DateZoomInOutProps = {
  startDate: Date;
  endDate: Date;
  onChange: OnDateApplyClick;
};

const hourInMinutes = 60;
const dayInMinutes = 24 * hourInMinutes;
const weekInMinutes = 7 * dayInMinutes;
const monthInMinutes = 4 * weekInMinutes;

export const DateZoomInOut = ({ onChange, startDate, endDate }: DateZoomInOutProps) => {
  const diffBetweenDatesInMins = useMemo(
    () => differenceInMinutes(endDate, startDate),
    [endDate, startDate],
  );

  const zoomInAvailable = diffBetweenDatesInMins > 5;

  const onZoomIn = () => {
    if (!zoomInAvailable) return;

    const range = (
      [
        [15, subMinutes, 5],
        [hourInMinutes, subMinutes, 15],
        [hourInMinutes * 6, subHours, 1],
        [hourInMinutes * 12, subHours, 6],
        [dayInMinutes, subHours, 12],
        [dayInMinutes * 3, subDays, 1],
        [weekInMinutes, subDays, 3],
        [weekInMinutes * 2, subDays, 7],
        [monthInMinutes, subWeeks, 2],
        [monthInMinutes * 6, subMonths, 1],
        [monthInMinutes * 13, subMonths, 6],
      ] as [number, typeof subMinutes, number][]
    ).find(([minMinutes]) => diffBetweenDatesInMins <= minMinutes);

    const rangeToApply = range || [null, () => subYears(endDate, 1), 0];

    onChange(rangeToApply[1](endDate, rangeToApply[2]), endDate);
  };

  const onZoomOut = () => {
    const range = (
      [
        [15, subHours, 1],
        [hourInMinutes, subHours, 6],
        [hourInMinutes * 6, subHours, 12],
        [hourInMinutes * 12, subDays, 1],
        [dayInMinutes, subDays, 3],
        [dayInMinutes * 3, subDays, 7],
        [weekInMinutes, subWeeks, 2],
        [weekInMinutes * 2, subMonths, 1],
        [monthInMinutes, subMonths, 6],
        [monthInMinutes * 6, subYears, 1],
      ] as [number, typeof subMinutes, number][]
    ).find(([minMinutes]) => diffBetweenDatesInMins <= minMinutes);

    const rangeToApply = range || [null, () => subYears(endDate, 15), 0];

    onChange(rangeToApply[1](endDate, rangeToApply[2]), endDate);
  };

  return (
    <Flex gap={0.5}>
      <ZoomOut fontSize="large" color="secondary" onClick={onZoomOut} sx={{ cursor: 'pointer' }} />
      <ZoomIn
        fontSize="large"
        color={zoomInAvailable ? 'secondary' : undefined}
        sx={{ cursor: zoomInAvailable ? 'pointer' : 'default' }}
        onClick={onZoomIn}
      />
    </Flex>
  );
};
