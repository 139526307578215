import { EcdDevice } from '@appTypes/models/ecd.dto';
import { getDeviceDetailsFromField } from './deviceField';
import { prepareDevicesFormInitVal } from './prepareDevicesFormInitVal';

export const prepareDevicesForm = (
  devices: EcdDevice[],
  updatedDevices: { [key: string]: string },
) => {
  const updatedDeviceAttrsByDevice = Object.entries(updatedDevices).reduce<{
    [key: string]: { [key: string]: string };
  }>((acc, [deviceWAttr, val]) => {
    const { id: deviceId, fieldName: deviceAttr } = getDeviceDetailsFromField(deviceWAttr);

    if (acc[deviceId]) {
      return {
        ...acc,
        [deviceId]: { ...acc[deviceId], [deviceAttr]: val },
      };
    }

    return { ...acc, [deviceId]: { [deviceAttr]: val } };
  }, {});

  const devicesWUpdatedAttrs = devices.map((device) => {
    const deviceAttrs = updatedDeviceAttrsByDevice[device.id];

    if (!deviceAttrs) {
      return device;
    }

    return {
      ...device,
      attributes: device.attributes.map((attr) => {
        const attrValue = deviceAttrs[attr.id];
        if (attrValue !== undefined) {
          return {
            ...attr,
            value: attrValue,
          };
        }
        return attr;
      }),
    };
  });

  const devicesInitVal = prepareDevicesFormInitVal(devicesWUpdatedAttrs);

  const devicesFields = devicesWUpdatedAttrs.map((device) => ({
    ...device,
    fields: device.attributes.map(({ id, name, read_only: readOnly, value }) => ({
      id,
      name,
      readOnly,
      initValue: value,
    })),
  }));

  return { devicesInitVal, devicesFields };
};
