import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useWindowSize = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isSiteHeaderTablet = useMediaQuery(theme.breakpoints.between('sm', 1020));
  const isSiteHeaderDesktop = useMediaQuery(theme.breakpoints.up(1020));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isSiteHeaderTablet,
    isSiteHeaderDesktop,
  };
};
