import * as Yup from 'yup';
import { createStringValidator } from './createStringValidator';
import { checkMax } from './max';
import { checkMin } from './min';

export const checkPhone = (label: string, validator = Yup.string()) =>
  createStringValidator(checkMin(8), checkMax(15))(
    label,
    validator.matches(
      /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/,
      'Must be a valid phone number in international format, starting with a plus sign.',
    ),
  );
