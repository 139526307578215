import CircleIcon from '@components/Svg/CircleIcon';
import { styled } from '@mui/material';
import { palette } from 'styles/palette';
import { icons } from '../elements';
import { ENERGY_FLOW_INDICATOR_COLORS } from '../types';

export type WireProps = React.HTMLAttributes<SVGSVGElement> & {
  count?: number;
  rotation?: number;
  reverse?: boolean;
  energyColor?: string;
  wireLength?: number;
  wireThickness?: number;
};

const SVGImage = styled('svg')<{ rotation: number }>`
  -webkit-transform: rotate(${({ rotation }) => `${rotation}deg`});
  transform: rotate(${({ rotation }) => `${rotation}deg`});
  -webkit-transform-origin: left center;
  transform-origin: left center;
`;

const Wire = ({
  count = 0,
  wireLength = 100,
  rotation = 0,
  reverse = false,
  wireThickness = 40,
  energyColor = ENERGY_FLOW_INDICATOR_COLORS.yellow,
  ...props
}: WireProps) => {
  const animationDuration = 3;

  const delays = Array.from({ length: count }, (_, index) => (animationDuration / count) * index);

  const energyCells = delays.map((delay) => (
    <CircleIcon
      icon={icons.arrowChevron}
      key={`energy-node-${delay}`}
      fill={energyColor}
      rotation={reverse ? 0 : 180}
      size={wireThickness}
    >
      <animate
        attributeName="cx"
        from={reverse ? 0 : wireLength}
        to={reverse ? wireLength : 0}
        dur={`${animationDuration}s`}
        repeatCount="indefinite"
        begin={`${delay}s`}
      />
    </CircleIcon>
  ));

  return (
    <SVGImage {...props} height={wireThickness} width={wireLength} rotation={rotation}>
      <line
        x1="0"
        y1={wireThickness / 2}
        x2={wireLength}
        y2={wireThickness / 2}
        stroke={palette.neutral.dark}
        strokeWidth="2"
        strokeDasharray="5 5"
      />
      {energyCells}
    </SVGImage>
  );
};

export default Wire;
