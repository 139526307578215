import { GetAllParams } from '@api/types';
import { AxiosRequestConfig } from 'axios';
import {
  getSingle,
  getRelated,
  create,
  createRelated,
  update,
  updateRelated,
  deleteItem,
} from './crud';
import { getAll } from './getAll';

export function makeEndpointHandler<TItem, TSimpleItem = TItem>(endpoint: string) {
  return {
    getAll(
      itemsResponseName: GetAllParams['itemsResponseName'],
      params: GetAllParams['params'],
      config?: GetAllParams['config'],
    ) {
      return getAll<TSimpleItem>(endpoint, itemsResponseName, params, config);
    },
    getSingle(id: number | string, config?: AxiosRequestConfig<any>) {
      return getSingle<TItem>(endpoint, id, config);
    },
    getRelated<TRelated>(id: number | string, related: string, config?: AxiosRequestConfig<any>) {
      return getRelated<TRelated>(endpoint, id, related, config);
    },
    create(dto: {}, config?: AxiosRequestConfig<any>) {
      return create<TItem>(endpoint, dto, config);
    },
    createRelated<TRelated>(
      id: number | string,
      related: string,
      data: {},
      config?: AxiosRequestConfig<any>,
    ) {
      return createRelated<TRelated>(endpoint, id, related, data, config);
    },
    update<TUpdateItem = {}>(
      dto: { id: number | string } & TUpdateItem,
      config?: AxiosRequestConfig<any>,
    ) {
      return update<TItem>(endpoint, dto, config);
    },
    updateRelated<TRelated>(
      id: number | string,
      related: string,
      data: {},
      config?: AxiosRequestConfig<any>,
    ) {
      return updateRelated<TRelated>(endpoint, id, related, data, config);
    },
    deleteItem(id: number | string, config?: AxiosRequestConfig<any>) {
      return deleteItem<TItem>(endpoint, id, config);
    },
  };
}
