import { Flex } from '@components/LayoutUtils';
import { SvgIconComponent } from '@mui/icons-material';
import { Box, BoxProps, Typography, TypographyTypeMap } from '@mui/material';

type TitleBarProps = {
  title: string | undefined;
  titleVariant?: TypographyTypeMap['props']['variant'];
  subTitle?: string;
  subTitleVariant?: TypographyTypeMap['props']['variant'];
  IconComponent?: SvgIconComponent;
} & BoxProps;

export const TitleBar = ({
  title,
  titleVariant = 'h4',
  subTitleVariant = 'h5',
  subTitle,
  IconComponent,
  ...wrapperProps
}: TitleBarProps) => (
  <Box {...wrapperProps}>
    <Flex flexWrap="wrap" alignItems="center">
      {IconComponent && (
        <Flex mr={1}>
          <IconComponent color="secondary" sx={{ fontSize: '38px' }} />
        </Flex>
      )}
      <Box zIndex={2}>
        <Typography variant={titleVariant}>{title}</Typography>
      </Box>
      {subTitle && (
        <Box zIndex={2} ml={1}>
          <Typography variant={subTitleVariant}>{subTitle}</Typography>
        </Box>
      )}
    </Flex>
  </Box>
);
