import { ActionCreator } from '@appTypes/helpers/redux';
import { CreateAlert, ShowAlert, HideAlert, RemoveAlert, AlertsActionsTypes } from './actionTypes';

export const createAlert: ActionCreator<CreateAlert> = (payload) => ({
  type: AlertsActionsTypes.CREATE_ALERT,
  payload,
});

export const showAlert: ActionCreator<ShowAlert> = (payload) => ({
  type: AlertsActionsTypes.SHOW_ALERT,
  payload,
});

export const hideAlert: ActionCreator<HideAlert> = (payload) => ({
  type: AlertsActionsTypes.HIDE_ALERT,
  payload,
});

export const removeAlert: ActionCreator<RemoveAlert> = (payload) => ({
  type: AlertsActionsTypes.REMOVE_ALERT,
  payload,
});

export type AlertsAction = CreateAlert | ShowAlert | HideAlert | RemoveAlert;
