import { useCallback, useMemo } from 'react';
import { EcdSimpleDto } from '@appTypes/models/ecd.dto';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Flex } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Switch } from '@components/Switch';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { useRedirect, useXDataGridConnect } from '@hooks';
import { Wifi } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { fetchedEcds, updateEcdsFilter } from '@redux/ducks/ecds/actionCreators';
import { EcdsSelectorReturn, getEcdsSelector } from '@redux/ducks/ecds/selectors';
import { palette } from 'styles/palette';
import { useEcdsStatuses } from './useEcdsStatuses';

type EcdGridCellProps = GridCellParams<string, EcdSimpleDto>;

export const EcdsList = () => {
  useEcdsStatuses();

  const redirect = useRedirect();

  const goToDetail = useCallback(
    (ecdId: EcdSimpleDto['id']) => redirect((paths) => paths.configurationEcd(ecdId)),
    [redirect],
  );

  const dataGridColumns = useMemo(
    () =>
      [
        {
          field: 'is_active',
          headerName: 'Status',
          sortable: false,
          maxWidth: 80,
          renderCell: ({ row }: EcdGridCellProps) => (
            <Flex>
              <Switch size="small" checked={row.is_active} />
            </Flex>
          ),
        },
        {
          field: 'id',
          headerName: 'ECD ID',
          flex: 0.6,
        },
        {
          field: 'name',
          headerName: 'ECD Name',
          flex: 0.5,
        },
        {
          field: 'is_online',
          headerName: 'Connect',
          sortable: false,
          maxWidth: 75,
          renderCell: ({ row }: EcdGridCellProps) => (
            <Box width="100%" display="grid" justifyItems="center">
              {(() => {
                const { loading, alive } = row?.connect || {};

                if (loading) {
                  return <Spinner size={20} />;
                }

                return <Wifi sx={{ color: alive ? palette.success.dark : palette.neutral.main }} />;
              })()}
            </Box>
          ),
        },
        {
          field: 'site.organization.name',
          headerName: 'Organization',
          minWidth: 250,
          sortable: false,
          renderCell: ({ row }: EcdGridCellProps) => (
            <DetailCell onDetailClick={() => goToDetail(row.id)}>
              {row.organization?.name}
            </DetailCell>
          ),
        },
      ].map((column) => ({
        minWidth: 100,
        flex: 1,
        ...column,
      })),
    [goToDetail],
  );

  const ecdsGridConnection = useXDataGridConnect<EcdsSelectorReturn, EcdSimpleDto>({
    selector: getEcdsSelector,
    currentItemsField: 'currentPageEcds',
    fetchPageAction: fetchedEcds,
    updateFilterAction: updateEcdsFilter,
  });

  return <XDataGrid<EcdSimpleDto> {...ecdsGridConnection} columns={dataGridColumns} />;
};
