import { UserDto, UserSimpleDto } from '@appTypes/models/user.dto';
import { UserFilters } from '@appTypes/users/filters';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { UsersAction } from './actionCreators';
import { UserActionsType } from './actionTypes';

const initialState = {
  users: null as Nullable<WPage<UserSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  filters: {
    page: 1,
    pageSize: 5,
    search: '',
    descending: false,
    orderBy: 'last_name&first_name',
  } as UserFilters,
  current: {
    data: null as Nullable<UserDto>,
    ...defaultRequestState,
  },
  create: defaultRequestState,
  update: defaultRequestState,
};

export type UsersStateType = typeof initialState;

export const usersReducer = (state = initialState, action: UsersAction): UsersStateType => {
  const reducerHandler = createReducerHandler<UsersStateType, UserSimpleDto>('users', state);

  switch (action.type) {
    case UserActionsType.SET_USER_REQUEST_LOADING:
      return reducerHandler.setRequestLoading(action.payload);
    case UserActionsType.SET_USER_REQUEST_ERROR:
      return reducerHandler.setRequestError(action.payload);
    case UserActionsType.UPDATE_USER_FILTER:
      return reducerHandler.updateFilter(action.payload);
    case UserActionsType.REQUEST_USERS:
      return reducerHandler.request();
    case UserActionsType.CLEAR_USER_CURRENT:
      return reducerHandler.clearCurrent();
    case UserActionsType.REQUEST_USERS_SUCCESS:
      return reducerHandler.requestSuccess(action.payload);
    case UserActionsType.REQUEST_USERS_FAILED:
      return reducerHandler.requestFailed(action.payload);
    case UserActionsType.GET_USER_SUCCESS:
      return reducerHandler.getCurrent(action.payload);
    case UserActionsType.CREATE_USER_SUCCESS:
      return reducerHandler.createSuccess();
    case UserActionsType.UPDATE_USER_SUCCESS:
      return reducerHandler.updateSuccess(action.payload);
    default:
      return state;
  }
};
