import EventEmitter from 'events';

type NotificationType = 'success' | 'error' | 'info' | 'warning';

export class NotificationService extends EventEmitter {
  success(message: string): void {
    this.emit('success', message);
  }

  error(message: string): void {
    this.emit('error', message);
  }

  info(message: string): void {
    this.emit('info', message);
  }

  warning(message: string): void {
    this.emit('warning', message);
  }

  on(event: NotificationType, listener: (message: string) => void): this {
    return super.on(event, listener);
  }
}

export const notificationService = new NotificationService();
