import { PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import logo from '@assets/images/logo.png';
import CardHead from '@components/Cards/CardHead';
import CardListItem from '@components/Cards/CardListItem';
import { CardContentWithLoader } from '@components/Cards/CardWithLoader';
import { CardContainer, TextLarge, TextNormal } from '@components/Cards/components';
import { Flex } from '@components/LayoutUtils';
import Overlay from '@components/Overlay/Overlay';
import { usePalette } from '@hooks';
import { ReactComponent as IconCurrency } from '@icons/currency.svg';
import { Box, Grid } from '@mui/material';
import { formatCurrency } from '@utils/utils';
import BarGraph from '../BarGraph';
import { CardTooltip } from './CardTooltip';
import ComingSoonBig from './ComingSoonOverlay';

const keyToTitleMap: Record<string, string> = {
  'balance-with-wattstor': 'Balance with Wattstor',
  'balance-without-wattstor': 'Balance without Wattstor',
};

interface CardBenefitCalculationProps {
  data?: PerformanceOverTimeDto['savings'];
  loading?: boolean;
  comingSoon?: boolean;
}

enum CurrencyCode {
  EUR = 'eur',
  GBP = 'gbp',
}

type Currency = {
  name: CurrencyCode | '';
  prefixSymbol: string;
};

const currencySymbols: Record<CurrencyCode, string> = {
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.GBP]: '£',
};

const getCurrency = (data?: PerformanceOverTimeDto['savings']): Currency => {
  const currency = (data?.currency as CurrencyCode) || '';
  const prefixSymbol = currencySymbols[currency as CurrencyCode] || '';

  return {
    name: currency,
    prefixSymbol,
  };
};

const CardBenefitCalculation = ({ data, loading, comingSoon }: CardBenefitCalculationProps) => {
  const currentPalette = usePalette();
  const chartColors = [
    currentPalette.success.dark,
    currentPalette.barGraph.main,
    currentPalette.barGraph.light,
  ];
  const currency = getCurrency(data);

  const seriesData = [
    {
      value: data ? data.total : 0,
      name: 'Return on Investment',
    },
    ...(data?.items?.map((item) => ({
      value: item.value,
      name: keyToTitleMap[item.key] || item.key,
    })) || []),
  ];

  const isNoData = data?.total === 0 && data?.items?.every((item) => item.value === 0);

  return (
    <CardContainer>
      <CardHead
        title="Benefit Calculation"
        subtitle="Energy import and export balance"
        icon={<IconCurrency />}
        rightSlot={<CardTooltip textKey="returnOnInvestment" />}
      />
      <CardContentWithLoader loading={loading} position="relative">
        {data ? (
          <>
            {comingSoon ? (
              <Overlay>
                <ComingSoonBig featureName="Benefit Calculation" />
              </Overlay>
            ) : null}
            {isNoData ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <TextNormal color={currentPalette.text.secondary}>No data</TextNormal>
              </Box>
            ) : (
              <BarGraph colors={chartColors} series={seriesData} currency={currency.prefixSymbol} />
            )}
            <Grid item xs={12} pb={3}>
              <Box>
                <Flex alignItems="baseline" gap="4px">
                  <TextNormal color={currentPalette.text.secondary} smaller>
                    {currency.prefixSymbol}
                  </TextNormal>
                  <TextLarge
                    color={data.total < 0 ? currentPalette.error.main : currentPalette.success.dark}
                  >
                    {formatCurrency(data.total)}
                  </TextLarge>
                </Flex>
                <TextNormal color={currentPalette.text.secondary}>Return on investment</TextNormal>
              </Box>
            </Grid>
            {data.items.map(({ key, value }, index) => {
              const icon =
                key === 'balance-with-wattstor' ? (
                  <img src={logo} height={10} alt="Wattstor" />
                ) : null;

              return (
                <CardListItem
                  key={key}
                  color={chartColors[index + 1]}
                  value={value || 0}
                  prefix={currency.prefixSymbol}
                  suffix={keyToTitleMap[key] || key}
                  icon={icon}
                  currency
                  light
                  boldValue
                />
              );
            })}
          </>
        ) : (
          <Box sx={{ height: '100px' }} />
        )}
      </CardContentWithLoader>
    </CardContainer>
  );
};

export default CardBenefitCalculation;
