import { ButtonPrimary } from '@components/Buttons/Buttons';
import { CenterXY, PaperLight } from '@components/LayoutUtils';
import CONFIG from '@config';
import logo from '@images/logotype.png';
import HomeIcon from '@mui/icons-material/Home';
import ReplayIcon from '@mui/icons-material/Replay';
import { Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { paths } from 'paths';

const Fallback = () => (
  <CenterXY height="100vh">
    <PaperLight sx={{ p: 4 }}>
      <img src={logo} alt="logo" />
      <Typography variant="h5" py={2}>
        Something unexpected happened, but we are here to help.
      </Typography>
      <Typography variant="body1" pb={2}>
        <span>
          Click the Retry button to give it another go, or use the Go to Homepage button to navigate
          back.
        </span>
        <br />
        <span>
          If you are still facing issues, contact{' '}
          <a href={`mailto:${CONFIG.SUPPORT_EMAIL_ADDRESS}`}>our support team</a> for assistance.
        </span>
      </Typography>
      <CenterXY gap={2}>
        <ButtonPrimary
          startIcon={<ReplayIcon />}
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </ButtonPrimary>
        <ButtonPrimary
          startIcon={<HomeIcon />}
          onClick={() => {
            window.location.replace(paths.sites);
          }}
        >
          Go to Homepage
        </ButtonPrimary>
      </CenterXY>
    </PaperLight>
  </CenterXY>
);

const GlobalErrorBoundary = ({ children }: React.PropsWithChildren) => (
  <ErrorBoundary
    fallback={<Fallback />}
    beforeCapture={(scope) => {
      scope.setLevel('fatal');
    }}
  >
    {children}
  </ErrorBoundary>
);

export default GlobalErrorBoundary;
