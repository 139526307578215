import { SetStateAction, useState, Dispatch } from 'react';
import { useOnUpdate } from '@hooks';
import { isAfter, isDate, isEqual, isValid } from 'date-fns';
import { DateTimePickerDate, TimeSpan } from '../types';

type Axis = [number | undefined, number | undefined];

export type SetXAxis = Dispatch<SetStateAction<Axis>>;

export const useXAxis = ({
  timeSpan,
}: {
  timeSpan?: TimeSpan;
} = {}) => {
  const defaultXAxis: Axis = [undefined, undefined];
  const [xAxis, setXAxis] = useState<Axis>(defaultXAxis);

  const { startDate, endDate } = timeSpan || {};

  const dateProvided = (timeSpanDate: DateTimePickerDate | undefined): timeSpanDate is Date =>
    isDate(timeSpanDate) && isValid(timeSpanDate);

  const getXAxisFromTimeSpan = (): Axis => {
    const datesProvided = dateProvided(startDate) && dateProvided(endDate);

    if (!datesProvided || isAfter(startDate, endDate) || isEqual(startDate, endDate)) {
      return defaultXAxis;
    }

    return [startDate.getTime(), endDate.getTime()];
  };

  useOnUpdate(() => {
    setXAxis(getXAxisFromTimeSpan());
  }, [timeSpan]);

  return [xAxis, setXAxis] as [Axis, SetXAxis];
};
