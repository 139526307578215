import { UserDto, UserRole } from '@appTypes/models/user.dto';
import { selectAuthSlice } from '@redux/slices/auth';
import { createFullName } from '@utils/data/createFullName';
import { useSelector } from 'react-redux';
import { useCurrentRole } from './useCurrentRole';

interface CurrentUserInfo {
  isLogged: boolean;
  fullName: string;
  isWattstorUser: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  currentUser: Nullable<UserDto>;
}

export const useCurrentUserInfo = (): CurrentUserInfo => {
  const { currentUser } = useSelector(selectAuthSlice);
  const { hasRole } = useCurrentRole();

  if (!currentUser) {
    return {
      isLogged: false,
      isWattstorUser: false,
      fullName: '',
      isSuperAdmin: false,
      isAdmin: false,
      currentUser: null,
    };
  }

  const { first_name, last_name, organization_id } = currentUser;

  return {
    isLogged: true,
    fullName: createFullName(first_name, last_name),
    isSuperAdmin: hasRole(UserRole.SUPERADMIN),
    isAdmin: hasRole(UserRole.ADMIN),
    isWattstorUser: organization_id === 1,
    currentUser,
  };
};
