import { GraphDto } from '@apiRtk/analysis';
import { formatNumberWithUnit, getChartColors } from '@components/Chart/utils/helpers';
import { EChartsOption, LineSeriesOption, YAXisComponentOption } from 'echarts';
import ReactDOMServer from 'react-dom/server';
import { palette } from 'styles/palette';
import EchartsTooltip from './EchartsTooltip';

export const getStaticOptions = (): EChartsOption => ({
  animation: false,

  grid: {
    top: 10,
    bottom: 30,
    left: 60,
    right: 60,
  },

  xAxis: {
    type: 'time',
    minorTick: {
      show: true,
    },
    splitLine: {
      show: true,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'black',
      },
    },

    axisLabel: {
      align: 'center',
    },

    axisPointer: {
      type: 'line',
      show: true,
      label: {
        show: false,
      },
      lineStyle: {
        color: palette.error.dark,
        width: 1,
        type: 'solid',
      },
    },
  },

  tooltip: {
    transitionDuration: 0,
    trigger: 'axis',
    axisPointer: {
      label: {
        show: false,
      },
    },
    formatter(params) {
      const content = ReactDOMServer.renderToString(<EchartsTooltip params={params} />);

      return content;
    },
  },
  legend: {
    show: false,
  },

  toolbox: {
    itemSize: 0,
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: false,
        brushStyle: {
          opacity: 0.3,
          color: palette.success.light,
        },
      },
    },
  },
});

export type DynamicOptions = {
  series: LineSeriesOption[];
  yAxis: YAXisComponentOption[];
};

export const createDynamicOptions = (
  graphData: Nullable<GraphDto> | undefined,
  hiddenSeries: string[],
): DynamicOptions => {
  if (!graphData?.series?.length || !graphData?.yaxes?.length) {
    return {
      series: [],
      yAxis: [],
    };
  }

  const { series: allSeries, yaxes } = graphData;

  const visibleSeries: LineSeriesOption[] = [];

  const yAxisConfigs = yaxes.map((yAxisConfig) => ({
    min: yAxisConfig.min,
    max: yAxisConfig.max,
    axisLabel: {
      formatter: (value: number) => formatNumberWithUnit(value, yAxisConfig.unit),
    },
    show: false, // Default to not show, will be set to true if any series uses it
    axisTick: {
      show: true,
    },
    hasVisibleSeries: false,
  }));

  const chartColors = getChartColors();

  allSeries.forEach((s, index) => {
    if (hiddenSeries.includes(s.uniqueName)) {
      return;
    }

    const yAxisIndex = s.yaxisIndex;
    const yAxisConfig = yAxisConfigs[yAxisIndex];

    // Mark Y-axis as having visible series
    yAxisConfig.hasVisibleSeries = true;
    yAxisConfig.show = true;

    visibleSeries.push({
      name: s.uniqueName,
      color: chartColors[index],
      type: 'line',
      showSymbol: false,
      animation: false,
      lineStyle: { width: 0.8 },
      yAxisIndex,
      data: s.dataPoints,
      sampling: 'average',
      id: `${s.label}|${yaxes[yAxisIndex].unit}`,
    });
  });

  // Update Y-axis visibility and split line configuration
  const yAxis = yAxisConfigs.map((yAxisConfig, index) => ({
    ...yAxisConfig,
    axisLine: {
      show: yAxisConfig.hasVisibleSeries,
    },
    splitLine: {
      lineStyle: {
        width:
          !yAxisConfig.hasVisibleSeries ||
          (yAxisConfigs[0]?.hasVisibleSeries && yAxisConfigs[1]?.hasVisibleSeries && index === 1)
            ? 0
            : 1,
      },
    },
  }));

  return {
    series: visibleSeries,
    yAxis,
  };
};
