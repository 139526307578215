import { put, select } from 'redux-saga/effects';
import { GetCurrentPageParams, SelectorReturn } from './types';

export function* getCurrentPage<TSelector extends SelectorReturn>({
  selector,
  getListActionName,
  clearCurrentActionName,
}: GetCurrentPageParams<TSelector>) {
  const {
    filters: { page, pageSize },
  }: SelectorReturn = yield select(selector);

  if (getListActionName) {
    yield put({ type: getListActionName, payload: { page, pageSize } });
  }

  if (clearCurrentActionName) {
    yield put({ type: clearCurrentActionName });
  }
}
