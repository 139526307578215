import { EcdSimpleDto } from '@appTypes/models/ecd.dto';
import { AppStateType } from '@appTypes/redux';
import { createCurrentPageSelector } from '@redux/common/createCurrentPageSelector';

export const getEcdsSelector = createCurrentPageSelector<
  AppStateType['ecds'],
  'ecds',
  EcdsSelectorReturn
>('ecds', 'currentPageEcds');

export type EcdsSelectorReturn = AppStateType['ecds'] & {
  currentPageEcds: WPage<EcdSimpleDto>[];
};
