import { useEffect, useMemo } from 'react';
import CONFIG from '@config';
import { useOnUpdate } from '@hooks';
import { ApexOptions } from 'apexcharts';
import ReactDOMServer from 'react-dom/server';
import { palette } from 'styles/palette';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import { ApexSeries, UseChartOptionsParams } from '../types';
import { formatNumberWithUnit, getChartColors } from '../utils/helpers';
import { useChartDateManagement } from './useChartDateManagement';
import { useXAxis } from './useXAxis';

export const useChartOptions = ({
  showToolbar,
  graphConfig,
  chartId,
  outages,
}: UseChartOptionsParams) => {
  const { xAxisMin, xAxisMax, setXAxis, timeSpan, setTimeSpan } = useChartDateManagement();

  const [[localXAxisMin, localXAxisMax], setLocalXAxis] = useXAxis({
    timeSpan,
  });

  useEffect(() => {
    setLocalXAxis([xAxisMin, xAxisMax]);
  }, [xAxisMin, xAxisMax, setLocalXAxis]);

  useOnUpdate(() => {
    if (!setXAxis) return;

    setXAxis([undefined, undefined]);
  }, []);

  const annotations = outages?.length!!
    ? {
        annotations: {
          xaxis: createOutageAnnotations(),
        },
      }
    : {};

  const options: ApexOptions = {
    ...annotations,
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        right: CONFIG.GRAPHS.YAXIS_OFFSET_X + 10, // Note: Solves ApexCharts tooltip x-position bug
        left: CONFIG.GRAPHS.YAXIS_OFFSET_X + 10,
      },
    },
    chart: {
      redrawOnWindowResize: false,
      parentHeightOffset: 15,
      id: chartId,
      group: 'chartsGroup',
      toolbar: { show: showToolbar },
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
      events: {
        scrolled: xAxisEventHandler,
        beforeZoom: xAxisEventHandler,
        beforeResetZoom() {
          setLocalXAxis([undefined, undefined]);
        },
      },
    },
    stroke: { width: 1 },
    colors: getChartColors(),
    dataLabels: { enabled: false },
    markers: {
      size: 0,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      min: localXAxisMin,
      max: localXAxisMax,
      type: 'datetime',
      labels: { datetimeUTC: false },
      crosshairs: {
        show: true,
        width: 1,
        position: 'front',
        opacity: 1,
        stroke: {
          color: palette.error.dark,
          dashArray: 0,
        },
      },
      axisTicks: {
        color: palette.neutral.main,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: useMemo(createYAxis, [graphConfig]),
    tooltip: {
      shared: true,
      followCursor: false,

      custom: (params) => {
        const { dataPointIndex, seriesIndex, w } = params;
        const series: ApexSeries[] = w.globals.initialSeries;
        const apexOptions: ApexOptions = w.config;
        const timestamp: number | undefined = w.globals.seriesX[seriesIndex][dataPointIndex];
        const { ancillaryCollapsedSeriesIndices, collapsedSeriesIndices } = w.globals;

        const hiddenSeriesIndices: number[] =
          ancillaryCollapsedSeriesIndices.concat(collapsedSeriesIndices);

        return ReactDOMServer.renderToString(
          <ChartTooltip
            series={series}
            timestamp={timestamp}
            dataPointIndex={dataPointIndex}
            options={apexOptions}
            hiddenSeries={hiddenSeriesIndices}
            graphConfig={graphConfig}
          />,
        );
      },
    },
    legend: {
      show: false,
    },
  };

  return { options };

  function createYAxis() {
    if (!graphConfig) return [];

    let leadYAxisName = '';
    /**
     * For each series in the graph, a yAxis configuration needs to be created to ensure correct assignment of series to the corresponding yAxis.
     * The lead yAxis determines the axis settings. Range in shared yAxis is automatically calculated by ApexCharts by biggest series.
     * This setting is shared with each series that has a `seriesName`
     * matching the lead yAxis. These series share the same range, allowing ApexChart to perform autoscaling accurately.
     * Non-lead yAxes are hidden and inherit the settings from the lead yAxis.
     * The association between child axes and the leading axis is determined by the `seriesName` property.
     */

    const yAxisConfig: ApexOptions['yaxis'] = graphConfig.map(
      ({ name, unit, min, max, leftSide }, index) => {
        const prevGraphConfig = graphConfig[index - 1];

        const isLeadAxis = index === 0 || prevGraphConfig?.unit !== unit;

        if (isLeadAxis) {
          leadYAxisName = name;
        }

        return {
          seriesName: leadYAxisName,
          min: min ?? undefined,
          max: max ?? undefined,
          showAlways: isLeadAxis,
          show: isLeadAxis,
          opposite: !leftSide,
          floating: true, // Note: Solves ApexCharts tooltip x-position bug
          labels: {
            formatter(value: number) {
              return `${formatNumberWithUnit(value, unit)}`;
            },
            offsetX: CONFIG.GRAPHS.YAXIS_OFFSET_X,
          },
          title: {
            text: unit, // it serves as placeholder for coupling axis with unit for rescaleYAxis function
            style: {
              color: 'transparent',
              fontSize: '0px',
            },
          },
        };
      },
    );

    return yAxisConfig;
  }

  function xAxisEventHandler(_: unknown, { xaxis }: { xaxis: { min: number; max: number } }) {
    setTimeSpan({
      startDate: new Date(xaxis.min),
      endDate: new Date(xaxis.max),
    });

    setLocalXAxis([xaxis.min, xaxis.max]);
    return false;
  }

  function createOutageAnnotations() {
    const mergedOutages = mergeOverlappingRanges(outages || []);

    return mergedOutages?.map<XAxisAnnotations>((outage) => ({
      x: new Date(outage.start).getTime(),
      x2: new Date(outage.end).getTime(),
      fillColor: palette.warning.main,
      borderColor: palette.warning.dark,
    }));
  }
};

type Range = {
  start: string;
  end: string;
};

type DateRange = {
  start: Date;
  end: Date;
};

function mergeOverlappingRanges(ranges: Range[]): Range[] {
  if (ranges.length < 2) return ranges;

  const dateRanges: DateRange[] = ranges.map((r) => ({
    start: new Date(r.start),
    end: new Date(r.end),
  }));

  dateRanges.sort((a, b) => a.start.getTime() - b.start.getTime());

  const mergedRanges: DateRange[] = [];
  let currentRange = dateRanges[0];

  for (let i = 1; i < dateRanges.length; i += 1) {
    const nextRange = dateRanges[i];
    if (nextRange.start <= currentRange.end) {
      currentRange.end = new Date(Math.max(currentRange.end.getTime(), nextRange.end.getTime()));
    } else {
      mergedRanges.push(currentRange);
      currentRange = nextRange;
    }
  }

  mergedRanges.push(currentRange);

  return mergedRanges.map((r) => ({
    start: r.start.toISOString().replace('.000Z', 'Z'),
    end: r.end.toISOString().replace('.000Z', 'Z'),
  }));
}
