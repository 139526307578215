import { ApiError } from '@appTypes/api/error';
import { DefaultFilters } from '@appTypes/helpers/redux';
import { put, select, call } from 'redux-saga/effects';
import { GetListRequestParams, Selector, SelectorReturn } from './types';

interface GetListParams<TSelector extends SelectorReturn, TItem>
  extends GetListRequestParams<TSelector, TItem> {
  payload: DefaultFilters;
  selector: Selector<TSelector>;
}

export function* getList<TSelector extends SelectorReturn, TItem>({
  payload,
  selector,
  apiCall,
  request,
  requestSuccess,
  requestFailed,
}: GetListParams<TSelector, TItem>) {
  const {
    filters,
    fetchedPages,
    itemsCount: currentItemsCount,
  }: SelectorReturn = yield select(selector);

  if (fetchedPages.includes(payload.page)) {
    yield put(
      requestSuccess({
        items: [],
        itemsCount: currentItemsCount,
        page: payload.page,
      }),
    );

    return;
  }

  try {
    yield put(request());
    const { items, itemsCount } = yield call(apiCall, {
      ...filters,
      ...payload,
    });
    yield put(requestSuccess({ items, itemsCount, page: payload.page }));
  } catch (error) {
    yield put(requestFailed(error as ApiError));
    throw error;
  }
}
