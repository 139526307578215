import { UpdateOrganizationDto } from '@appTypes/models/organization.dto';
import { useRequestSuccessAlert } from '@hooks';
import { fetchedUpdateOrganization } from '@redux/ducks/organization/actionCreators';
import { getOrganizationsSelector } from '@redux/ducks/organization/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrganizationForm } from './OrganizationForm/OrganizationForm';

const UpdateOrganization = () => {
  const params = useParams();
  const id = params.id as string;

  const organizationId = parseInt(id, 10);

  const dispatch = useDispatch();

  const onSubmit = (data: UpdateOrganizationDto) => {
    dispatch(fetchedUpdateOrganization(data));
  };

  const { update } = useSelector(getOrganizationsSelector);

  useRequestSuccessAlert(update, 'Organization successfully updated.');

  if (Number.isNaN(organizationId)) return null;

  return <OrganizationForm organizationId={organizationId} onSubmit={onSubmit} />;
};

export default UpdateOrganization;
