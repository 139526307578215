import axios from 'axios';

const apiUrl = import.meta.env.VITE_APP_API_URL || '';

export const BASE_URL = `${apiUrl}/api/v1/`;

export const instance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});
