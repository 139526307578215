import { useState } from 'react';
import { SelectBox } from '@components/SelectBox/SelectBox';
import {
  subHours,
  endOfYesterday,
  subDays,
  subMinutes,
  startOfYesterday,
  startOfToday,
  endOfToday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { TimeUnit } from '../types';

const options = [
  { name: 'last 15m', unit: TimeUnit.MINUTES, value: 15 },
  { name: 'last 1h', unit: TimeUnit.HOURS, value: 1 },
  { name: 'last 6h', unit: TimeUnit.HOURS, value: 6 },
  { name: 'last 12h', unit: TimeUnit.HOURS, value: 12 },
  { name: 'last 1d', unit: TimeUnit.DAYS, value: 1 },
  { name: 'last 2d', unit: TimeUnit.DAYS, value: 2 },
  { name: 'last 7d', unit: TimeUnit.DAYS, value: 7 },
  { name: 'last 30d', unit: TimeUnit.DAYS, value: 30 },
  { name: 'last 90d', unit: TimeUnit.DAYS, value: 90 },
  { name: 'today', unit: TimeUnit.TODAY },
  { name: 'yesterday', unit: TimeUnit.YESTERDAY },
  { name: 'this week', unit: TimeUnit.THIS_WEEK },
  { name: 'this month', unit: TimeUnit.THIS_MONTH },
].map((opt, index) => ({ ...opt, id: `${index}`, value: opt.value || 0 }));

export const DateRangeSelect = ({
  onChange,
}: {
  onChange(startDate: Date, endDate: Date): void;
}) => {
  const [rangeVal, setRangeVal] = useState('');

  const handleChange = (changedVal: string) => {
    setRangeVal(changedVal);

    if (!changedVal) return;

    const { unit, value: optVal } = options[parseInt(changedVal, 10)];

    let startDate = new Date();
    let endDate = new Date();

    switch (unit) {
      case TimeUnit.MINUTES:
        startDate = subMinutes(startDate, optVal);
        break;
      case TimeUnit.HOURS:
        startDate = subHours(startDate, optVal);
        break;
      case TimeUnit.DAYS:
        startDate = subDays(startDate, optVal);
        break;
      case TimeUnit.TODAY:
        startDate = startOfToday();
        endDate = endOfToday();
        break;
      case TimeUnit.YESTERDAY:
        startDate = startOfYesterday();
        endDate = endOfYesterday();
        break;
      case TimeUnit.THIS_WEEK:
        startDate = startOfWeek(startDate);
        endDate = endOfWeek(endDate);
        break;
      case TimeUnit.THIS_MONTH:
        startDate = startOfMonth(startDate);
        endDate = endOfMonth(endDate);
        break;
      default:
        break;
    }

    onChange(startDate, endDate);
  };

  return (
    <SelectBox
      size="small"
      value={rangeVal}
      onValueChange={handleChange}
      emptyValueContent="-"
      options={options}
      formControlProps={{ sx: { width: 120 } }}
      sx={{ fontSize: 12 }}
    />
  );
};
