import {
  BatteryComparisonField,
  CalculatorComparisonField,
  NotificationRuleDevice,
} from '@appTypes/notificationRules/fields';
import { CustomSelectBoxProps } from '@components/SelectBox/SelectBox';
import { Timeline } from '@mui/icons-material';
import { LabeledSelectBox } from '../Labeled/LabeledSelectBox';

const calculatorComparisonFieldOpts = [
  { id: CalculatorComparisonField.CONSUMPTION, name: 'Consumption (kW)' },
  {
    id: CalculatorComparisonField.GRID_CONSUMPTION,
    name: 'Grid consumption (kW)',
  },
  {
    id: CalculatorComparisonField.GRID_BACKFLOW,
    name: 'Grid backflow (kW)',
  },
  {
    id: CalculatorComparisonField.PV_GENERATION,
    name: 'PV generation (kW)',
  },
  {
    id: CalculatorComparisonField.BATTERY_CHARGE,
    name: 'Battery charging (kW)',
  },
  {
    id: CalculatorComparisonField.BATTERY_DISCHARGE,
    name: 'Battery discharging (kW)',
  },
];

const batteryComparisonFieldOpts = [
  { id: BatteryComparisonField.SOC, name: 'SoC (%)' },
  { id: BatteryComparisonField.SOH, name: 'SoH (%)' },
];

export const ComparisonFieldSelectBox = ({
  currentDevice,
  ...props
}: Omit<CustomSelectBoxProps, 'options' | 'disableEmptyValue'> & {
  currentDevice: `${NotificationRuleDevice}`;
}) => {
  const options =
    currentDevice === NotificationRuleDevice.CALCULATOR
      ? calculatorComparisonFieldOpts
      : batteryComparisonFieldOpts;

  return (
    <LabeledSelectBox
      {...props}
      IconComponent={Timeline}
      label="Value"
      options={options}
      disableEmptyValue
      variant="outlined"
    />
  );
};
