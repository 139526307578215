import { useGetDashboardQuery } from '@apiRtk/dashboards';
import { DASHBOARD_TYPES } from '@appTypes/enums';
import { prepareError } from '@utils/data/prepareError';
import { useParams } from 'react-router-dom';

const useDashboardInfo = (dashboardType: DASHBOARD_TYPES = DASHBOARD_TYPES.SUMMARY) => {
  const params = useParams();
  const siteId = params.id!;
  let dashboardId = siteId;

  if (dashboardType === DASHBOARD_TYPES.SUMMARY) {
    dashboardId = `${siteId}.summary`;
  }

  const dashboard = useGetDashboardQuery(dashboardId);

  const { refetch, data, isLoading, error, isError } = dashboard;

  return {
    refetch,
    siteId: parseInt(siteId, 10),
    dashboardId,
    graphsConfig: data?.graphs_configuration || [],
    isLoading,
    isError,
    error: prepareError(error),
  };
};

export default useDashboardInfo;
