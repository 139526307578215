import { useState } from 'react';
import { SiteIdName } from '@appTypes/models/site.dto';
import { SearchInput } from '@components/SearchInput';
import { Box, Alert, Typography } from '@mui/material';
import { paths } from 'paths';
import { NavLink, useParams } from 'react-router-dom';
import { palette } from 'styles/palette';

type SitesNavListProps = {
  sites: SiteIdName[];
  maxItemsVisible?: number;
  showSearchBox?: boolean;
};

export const SitesNavList = ({
  maxItemsVisible,
  sites,
  showSearchBox = true,
}: SitesNavListProps) => {
  const [searchedSite, setSearchedSite] = useState('');
  const params = useParams();
  const currentSiteId = parseInt(params.id as string, 10);

  if (sites.length === 0) {
    return (
      <Box p={2}>
        <Alert variant="filled" severity="info">
          Sorry, you don&apos;t have any sites linked with your account.
        </Alert>
      </Box>
    );
  }

  const sitesToRender = maxItemsVisible === undefined ? sites : sites.slice(0, maxItemsVisible);

  return (
    <>
      {showSearchBox && (
        <Box p={2}>
          <SearchInput value={searchedSite} onValueChange={(v) => setSearchedSite(v)} />
        </Box>
      )}
      {sitesToRender.map(({ id: siteId, name }) => {
        if (searchedSite && !name.toLowerCase().includes(searchedSite.toLowerCase())) {
          return null;
        }

        const isCurrentSite = siteId === currentSiteId;
        const path = paths.siteOverview(siteId.toString());

        return (
          <Box key={siteId}>
            <NavLink
              to={path}
              style={{
                textDecoration: 'none',
              }}
            >
              <Box
                pl="72px"
                pr="16px"
                py="6px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: palette.primary.main,
                  ...(isCurrentSite && {
                    backgroundColor: palette.neutral.light,
                    borderRadius: '4px',
                  }),
                }}
                data-cy={`allsites-modal-item-${name}`}
              >
                <Typography>{name}</Typography>
              </Box>
            </NavLink>
          </Box>
        );
      })}
    </>
  );
};
