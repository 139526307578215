import { CustomSelectBoxProps, SelectBox } from '@components/SelectBox/SelectBox';
import { ILabeledInputProps, LabeledInput } from './LabeledInput';

type LabeledSelectBoxProps =
  | ({
      selectBox: JSX.Element;
    } & Partial<CustomSelectBoxProps>)
  | ({
      selectBox?: never;
    } & CustomSelectBoxProps);

export const LabeledSelectBox = ({
  label,
  selectBox,
  IconComponent,
  ...selectBoxProps
}: Omit<ILabeledInputProps, 'renderedInputComponent'> & LabeledSelectBoxProps) => (
  <LabeledInput
    label={label}
    IconComponent={IconComponent}
    renderedInputComponent={
      selectBox || <SelectBox {...(selectBoxProps as CustomSelectBoxProps)} />
    }
  />
);
