import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { CenterX, Flex } from '@components/LayoutUtils';
import { Typography } from '@mui/material';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { isAfter, subWeeks } from 'date-fns';
import { useDispatch } from 'react-redux';
import { DateRangeSelect } from '../DateRangeSelect/DateRangeSelect';
import { DateSpanInputs } from '../DateSpanInputs/DateSpanInputs';
import { DateZoomInOut } from '../DateZoomInOut/DateZoomInOut';
import { OnDateApplyClick, TimeSpan } from '../types';
import { SetXAxis, useXAxis } from './useXAxis';

export type UseChartDateManagementParams = {
  onDateChange?: (data: TimeSpan) => void;
  initStartDate?: Date;
  initEndDate?: Date;
  inheritContext?: boolean;
  displayDateRangeSelect?: boolean;
  displayFromInput?: boolean;
};

type ChartDateContextValue = {
  xAxisMin: number | undefined;
  xAxisMax: number | undefined;
  setXAxis: SetXAxis;
  timeSpan: TimeSpan;
  setTimeSpan: Dispatch<SetStateAction<TimeSpan>>;
} & Pick<UseChartDateManagementParams, 'onDateChange'>;

const ChartDateContext = createContext({} as ChartDateContextValue);

const useChartDateContext = () => useContext(ChartDateContext);

export const useChartDateManagement = ({
  displayDateRangeSelect = true,
  displayFromInput = true,
  onDateChange,
  initStartDate = subWeeks(new Date(), 1),
  initEndDate = new Date(),
}: UseChartDateManagementParams = {}) => {
  const chartDateContextData = useChartDateContext();
  const dispatch = useDispatch();

  const timeSpanState = useState<TimeSpan>({
    startDate: initStartDate,
    endDate: initEndDate,
  });

  const isContextAvailable = !!chartDateContextData?.setTimeSpan;

  const [timeSpan, setTimeSpan] = isContextAvailable
    ? [chartDateContextData.timeSpan, chartDateContextData.setTimeSpan]
    : timeSpanState;

  const useXAxisResult = useXAxis({
    timeSpan,
  });

  const [[xAxisMin, xAxisMax], setXAxis] = isContextAvailable
    ? [
        [chartDateContextData.xAxisMin, chartDateContextData.xAxisMax],
        chartDateContextData.setXAxis,
      ]
    : useXAxisResult;

  const onDateApply: OnDateApplyClick = (startDate, endDate) => {
    if (isAfter(startDate, endDate)) {
      dispatch(
        createAlert({
          message: 'Ensure end date is after start date.',
          variant: 'error',
        }),
      );
      return;
    }

    const appliedDates = { startDate, endDate };

    setTimeSpan(appliedDates);

    if (onDateChange) {
      onDateChange(appliedDates);
    }
  };

  const renderDateInputs = () =>
    isContextAvailable ? null : (
      <CenterX
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
        my={1}
        p={1}
        gap={1.5}
      >
        <Flex
          gap={1.5}
          width={{ xs: '100%', md: 'initial' }}
          justifyContent={{ xs: 'space-between', md: 'initial' }}
        >
          {displayDateRangeSelect && <DateRangeSelect onChange={onDateApply} />}
          <DateZoomInOut
            startDate={timeSpan.startDate}
            endDate={timeSpan.endDate}
            onChange={onDateApply}
          />
        </Flex>
        <CenterX gap={1}>
          <Typography variant="caption">Select period to show</Typography>
          <DateSpanInputs
            displayFromInput={displayFromInput}
            initStartVal={timeSpan.startDate}
            initEndVal={timeSpan.endDate}
            onApplyClick={onDateApply}
          />
        </CenterX>
      </CenterX>
    );

  const renderWithProvider = (children: PropsWithChildren['children']) => (
    <ChartDateContext.Provider
      value={{
        xAxisMin,
        xAxisMax,
        setXAxis,
        timeSpan,
        setTimeSpan,
        onDateChange,
      }}
    >
      {children}
    </ChartDateContext.Provider>
  );

  return {
    renderWithProvider,
    renderDateInputs,
    timeSpan,
    xAxisMin,
    xAxisMax,
    setXAxis,
    setTimeSpan,
  };
};
