import { checkIfValChanged } from './checkIfValChanged';

export const getChangedVals = (
  initVal: { [key: string]: string | undefined },
  val: { [key: string]: string | undefined },
) => {
  const changedValsEntries = Object.entries(val).filter(([key, value]) => {
    // react-hook-form appended object cleanup
    if (typeof value === 'object' && value !== null) {
      return false;
    }

    return checkIfValChanged(initVal[key as keyof typeof initVal], value);
  });

  return Object.fromEntries(changedValsEntries);
};
