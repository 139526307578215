import { Box, Typography, styled } from '@mui/material';
import { space } from '@utils/utils';
import { palette } from 'styles/palette';

export const CardContainer = styled(Box)`
  height: calc(100% - 32px);
  border-radius: 8px;
  padding: ${space(2)};
  background-color: ${palette.white};
`;

export const CardContainerColumn = styled(CardContainer)`
  display: flex;
  flex-direction: column;
`;

export const CardSubtitle = styled(Typography)`
  color: ${palette.primary.main};
  font-size: 12px;
  line-height: 16px;
`;

export const CardContentWrapper = styled(Box)`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-top: ${space(3)};
`;

export const Dot = styled(Box)<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

type CardTextProps = {
  smaller?: boolean;
};

export const TextLarge = styled(Typography, {
  shouldForwardProp: (prop: string) => !['smaller'].includes(prop),
})<CardTextProps>`
  font-size: ${({ smaller }) => (smaller ? '14px' : '18px')};
  font-weight: bold;
`;

export const TextNormal = styled(Typography, {
  shouldForwardProp: (prop: string) => !['smaller'].includes(prop),
})<CardTextProps>`
  font-size: ${({ smaller }) => (smaller ? '12px' : '14px')};
`;
