import { styled, Switch as MuiSwitch } from '@mui/material';
import { palette } from 'styles/palette';

const smallSwitchStyles = {
  width: 35,
  padding: 6,
  boxShadow: 'none !important',
  '& .MuiSwitch-switchBase': {
    color: `${palette.white} !important`,
    ':hover': {
      background: 'none !important',
    },
  },
  '& .Mui-checked': {
    transform: 'translateX(11px) !important',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: `${palette.primary.main} !important`,
    borderRadius: 50,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%) !important',
      width: 8,
      height: 8,
    },
  },
  '& .Mui-checked + .MuiSwitch-track': {
    opacity: '1 !important',
    backgroundColor: `${palette.secondary.main} !important`,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 8,
    height: 8,
    margin: 4,
  },
};

export const Switch = styled(MuiSwitch)(({ size }) => (size === 'small' ? smallSwitchStyles : {}));
