import { useState, forwardRef, ForwardedRef } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, IconButton, TextFieldProps, TextField } from '@mui/material';

export const PasswordField = forwardRef(
  ({ ...props }: TextFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
      <TextField
        ref={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
        type={showPassword ? 'text' : 'password'}
      />
    );
  },
);
