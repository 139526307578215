import { useEffect, useState } from 'react';
import { StateFilter } from '@appTypes/helpers/filters';
import { FilterParamsTanstackDto } from '@appTypes/models/common.dto';
import { SortingState, Updater } from '@tanstack/react-table';
import { useNavigate, useLocation } from 'react-router-dom';

interface UseFiltersProps {
  urlMirroringActive: boolean;
  sortingActive: boolean;
  pageSize: number;
  sortingState: SortingState;
  state?: StateFilter;
  defaultSiteId?: Nullable<number>;
  urlMirroringExcludeArray?: string[];
}

export const useFilters = ({
  urlMirroringActive,
  sortingActive,
  pageSize,
  sortingState,
  state,
  defaultSiteId = null,
  urlMirroringExcludeArray = [],
}: UseFiltersProps) => {
  const [sorting, setSortingState] = useState<SortingState>(sortingState);
  const [searchString, setSearchStringState] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize,
  });
  const [siteId, setSiteIdState] = useState<Nullable<number>>(defaultSiteId);
  const [stateFilter, setStateFilterState] = useState<Nullable<StateFilter>>(state || null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const updateUrl = () => {
      const params = new URLSearchParams();

      if (!urlMirroringExcludeArray.includes('page')) {
        params.set('page', String(pagination.pageIndex + 1));
      }

      if (!urlMirroringExcludeArray.includes('search_string') && searchString) {
        params.set('search_string', searchString);
      }

      if (sortingActive && sorting.length > 0) {
        if (!urlMirroringExcludeArray.includes('order_by')) {
          params.set('order_by', sorting[0].id);
        }
        if (!urlMirroringExcludeArray.includes('descending')) {
          params.set('descending', sorting[0].desc ? 'true' : 'false');
        }
      }

      if (!urlMirroringExcludeArray.includes('site_id') && siteId !== null && siteId > 0) {
        params.set('site_id', String(siteId));
      }

      if (!urlMirroringExcludeArray.includes('state') && stateFilter) {
        params.set('state', stateFilter);
      }

      const newUrl = `?${params.toString()}`;
      if (newUrl !== location.search) {
        navigate({ search: params.toString() }, { replace: true });
      }
    };

    if (urlMirroringActive) {
      updateUrl();
    }
  }, [
    pagination,
    sorting,
    searchString,
    siteId,
    stateFilter,
    location.search,
    navigate,
    sortingActive,
    urlMirroringActive,
    urlMirroringExcludeArray,
  ]);

  useEffect(() => {
    if (!urlMirroringActive) {
      return;
    }

    const getSite = (params: URLSearchParams) => {
      if (params.get('site_id') && !urlMirroringExcludeArray.includes('site_id')) {
        const value = Number(params.get('site_id'));
        return Number.isNaN(value) ? null : value;
      }

      if (defaultSiteId) {
        return defaultSiteId;
      }

      return null;
    };

    const getPage = (params: URLSearchParams) => {
      if (!params.get('page') || urlMirroringExcludeArray.includes('page')) {
        return 0;
      }
      const value = Number(params.get('page'));
      return Number.isNaN(value) ? 0 : value - 1;
    };

    const params = new URLSearchParams(location.search);

    const page = getPage(params);
    const search = !urlMirroringExcludeArray.includes('search_string')
      ? params.get('search_string') || ''
      : '';
    const site = getSite(params);

    setPagination((prev) => ({ ...prev, pageIndex: page }));
    setSearchStringState(search);
    setSiteIdState(site);

    if (sortingActive && !urlMirroringExcludeArray.includes('order_by')) {
      const orderBy = params.get('order_by');
      const descending = params.get('descending') === 'true';

      if (orderBy) {
        setSortingState([{ id: orderBy, desc: descending }]);
      }
    }

    if (params.get('state') && !urlMirroringExcludeArray.includes('state')) {
      setStateFilterState(params.get('state') as Nullable<StateFilter>);
    }
    // State from URL is loaded only once -> empty dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSorting = (newSorting: Updater<SortingState>) => {
    setSortingState(newSorting);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const setSearchString = (newSearchString: string) => {
    setSearchStringState(newSearchString);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const setSiteId = (newSiteId: Nullable<number>) => {
    setSiteIdState(newSiteId);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const setStateFilter = (newStateFilter: Nullable<StateFilter>) => {
    setStateFilterState(newStateFilter);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const getFilters = (): FilterParamsTanstackDto => {
    const filters: FilterParamsTanstackDto = {
      page: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
      search_string: searchString,
    };

    if (sorting.length > 0) {
      const sort = sorting[0];
      filters.order_by = [sort.id];
      filters.descending = sort.desc;
    }

    if (siteId) {
      filters.site_id = siteId;
    }

    if (stateFilter) {
      filters.state = stateFilter;
    }

    return filters;
  };

  return {
    getFilters,
    sorting,
    searchString,
    pagination,
    siteId,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setSiteId,
    setStateFilter,
  };
};
