import { AppStateType } from '@appTypes/redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import logger from '@services/logger';
import { Middleware, Dispatch, AnyAction } from 'redux';

function hasStatusProperty(payload: any): payload is { status: number } {
  return 'status' in payload && typeof payload.status === 'number';
}

const isWrongLoginError = (action: AnyAction) =>
  action.payload && hasStatusProperty(action.payload) && action.payload.status === 401;

const errorLoggingMiddleware: Middleware<{}, AppStateType> =
  () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    if (isRejectedWithValue(action) && !isWrongLoginError(action)) {
      const getErrorDetail = () => {
        const message =
          'data' in action.error
            ? (action.error.data as { message: string }).message
            : action.error.message;

        if (action.payload && hasStatusProperty(action.payload)) {
          const { status } = action.payload;

          return {
            message,
            status,
          };
        }

        return {
          message,
        };
      };

      logger.log(new Error('API request error'), {
        level: 'error',
        addDebugInfo: true,
        extra: getErrorDetail(),
      });
    }

    return next(action);
  };

export default errorLoggingMiddleware;
