import { useEffect } from 'react';
import { useGetOrganizationsAllForSelectQuery } from '@apiRtk/organizations';
import { useCreateSiteMutation } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api/error';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { CenterY, GapY } from '@components/LayoutUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { palette } from 'styles/palette';
import { z } from 'zod';
import { siteFormSchema } from './SiteFormSchema';

type FormValues = z.infer<typeof siteFormSchema>;

const defaultValues: FormValues = {
  organization_id: 0,
  name: '',
  street: '',
  city: '',
  postal_code: '',
  country: '',
  lat: '',
  lon: '',
};

const CreateSiteForm = () => {
  const { data: organizations, isLoading: isOrganizationsLoading } =
    useGetOrganizationsAllForSelectQuery();

  const [createSite, { isLoading, isSuccess, isError, error }] = useCreateSiteMutation();
  const typedError = error as ApiErrorPayload | undefined;

  const {
    handleSubmit,
    control,
    formState: { isSubmitSuccessful, errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(siteFormSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    createSite({
      ...data,
      organization_id: data.organization_id === 0 ? null : data.organization_id,
      lat: Number(data.lat),
      lon: Number(data.lon),
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful && isSuccess) {
      reset();
    }
  }, [isSubmitSuccessful, isSuccess, reset]);

  if (isOrganizationsLoading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.name ? palette.primary.light : palette.error.main}
            >
              Name *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.name}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.name && (
              <Typography variant="subtitle2" color="error">
                {errors.name.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="street"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.street ? palette.primary.light : palette.error.main}
            >
              Street *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.street}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.street && (
              <Typography variant="subtitle2" color="error">
                {errors.street.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="city"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.city ? palette.primary.light : palette.error.main}
            >
              City *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.city}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.city && (
              <Typography variant="subtitle2" color="error">
                {errors.city.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="postal_code"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.postal_code ? palette.primary.light : palette.error.main}
            >
              Postal Code *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.postal_code}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.postal_code && (
              <Typography variant="subtitle2" color="error">
                {errors.postal_code.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="country"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.country ? palette.primary.light : palette.error.main}
            >
              Country *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.country}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.country && (
              <Typography variant="subtitle2" color="error">
                {errors.country.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="lat"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.lat ? palette.primary.light : palette.error.main}
            >
              Latitude *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.lat}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.lat && (
              <Typography variant="subtitle2" color="error">
                {errors.lat.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="lon"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.lon ? palette.primary.light : palette.error.main}
            >
              Longitude *
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.lon}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.lon && (
              <Typography variant="subtitle2" color="error">
                {errors.lon.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="organization_id"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.organization_id ? palette.primary.light : palette.error.main}
            >
              Organization
            </EllipsisTypography>
            <Select
              size="medium"
              sx={{ width: '100%' }}
              value={value}
              error={!!errors.organization_id}
              onChange={onChange}
            >
              <MenuItem key={0} value={0}>
                -
              </MenuItem>
              {(organizations || []).map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors.organization_id && (
              <Typography variant="subtitle2" color="error">
                {errors.organization_id.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      {isError && (
        <>
          <Alert severity="error">
            {typedError && typeof typedError.data.detail === 'string'
              ? typedError.data.detail
              : 'We encountered an error while processing your request. Please try again later.'}
          </Alert>
          <GapY size={2} />
        </>
      )}
      {isSuccess && (
        <>
          <Alert severity="success">Site successfully created.</Alert>
          <GapY size={2} />
        </>
      )}

      <CenterY gap={3}>
        <Button size="large" type="submit" disabled={isLoading}>
          Save
        </Button>
      </CenterY>
    </form>
  );
};

export default CreateSiteForm;
