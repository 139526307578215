import { createStringValidator } from '@utils/validators/string/createStringValidator';
import { checkMax } from '@utils/validators/string/max';
import { checkMin } from '@utils/validators/string/min';
import * as Yup from 'yup';

export const checkPassword = (label: string, validator = Yup.string()) =>
  createStringValidator(checkMin(8), checkMax(40))(
    label,
    validator.matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      `${label} must include at least one uppercase letter, one lowercase letter and one digit.`,
    ),
  );
