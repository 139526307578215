import { useContext } from 'react';
import { PermissionContext } from '@providers/PermissionProvider';

export const usePermission = () => {
  const pc = useContext(PermissionContext);
  if (pc === null) {
    throw new Error('usePermissions must be inside of PermissionsProvider');
  }
  return pc;
};
