import { EcdFilters } from '@appTypes/ecds/filters';
import { EcdDevice, EcdDto, EcdSimpleRemoteDto, UpdateEcdDto } from '@appTypes/models/ecd.dto';
import _ from 'lodash';
import { apiCore } from './apiCore/apiCore';

const epHandler = apiCore.makeEndpointHandler<EcdSimpleRemoteDto>('imps');

export const ecdsAPI = {
  getEcds(params: EcdFilters) {
    return epHandler.getAll('imps', params);
  },
  getEcd: async (id: EcdSimpleRemoteDto['id']) => {
    const ecd = await epHandler.getSingle(id);

    let devices: EcdDevice[] = [];
    let apiError = null;

    try {
      const result = await epHandler.getRelated<{
        devices: EcdDevice[];
      }>(id, 'devices');
      devices = result.devices;
    } catch (error) {
      apiError = error as Nullable<Error>;
    }

    if (apiError) {
      return { ...ecd, devices, error: apiError };
    }
    return { ...ecd, devices };
  },
  getEcdStatus(id: EcdSimpleRemoteDto['id']) {
    return epHandler.getRelated<{ alive: boolean }>(id, 'healthcheck');
  },
  updateEcd: async (dto: UpdateEcdDto) => {
    const currentEcdData = await epHandler.getSingle(dto.id);

    let ecd;

    if (currentEcdData.site?.id !== dto.site_id || currentEcdData.name !== dto.name) {
      ecd = await epHandler.update(_.omit(dto, ['devices']));
    } else {
      ecd = {
        id: dto.id,
        name: dto.name,
        site: {
          id: dto.id,
        },
      };
    }

    if (dto.devices && Object.keys(dto.devices).length > 0) {
      await epHandler.updateRelated(dto.id, 'devices', dto.devices);
    }

    return ecd as EcdDto;
  },
};
