import * as Yup from 'yup';
import { createStringValidator } from './createStringValidator';

export const checkSafeStringSoft = (label: string, validator = Yup.string()) =>
  createStringValidator()(
    label,
    validator.matches(/^[^<>;\\]*$/, `${label} must not begin with special characters.`),
  );

export const checkSafeString = (label: string, validator = Yup.string()) =>
  createStringValidator()(
    label,
    validator.matches(
      /^[^0-9!@#$%^&*_;()?~`"{}|<>[\]\\]*$/,
      `${label} must not begin with special characters.`,
    ),
  );
