import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useURLQuery<TParams extends { [key: string]: TParam }, TParam = string | number>(
  onURLParamsChange?: (paramsValues: { name: keyof TParams; value: string }[]) => void,
  params: { [key: string]: TParam } = {},
) {
  const [searchParams, setSearchParams] = useSearchParams();

  function getURLParamValue<T extends string>(param: keyof TParams) {
    return (searchParams.get(param as string) || params[param as string]) as T;
  }

  const getAllURLParams = (): [string, string][] => {
    if (window.location.search === '') {
      return [];
    }

    return window.location.search
      .replace('?', '')
      .split('&')
      .map((queryVal) => {
        const [key, val] = queryVal.split('=');

        return [key, val];
      });
  };

  const updateURLParam = (key: keyof TParams, value: Nullable<string>) => {
    const existingSearchParams = getAllURLParams().filter(([existingKey]) => existingKey !== key);

    if (value === null) {
      setSearchParams(existingSearchParams);

      return;
    }

    setSearchParams([...existingSearchParams, [key as string, value]]);
  };

  const queryParams = Object.entries(params).map(
    ([key, initValue]) => getURLParamValue(key as keyof TParams) || initValue,
  ) as TParam[];

  useEffect(() => {
    const paramsKeys = Object.keys(params);

    const paramsValues = queryParams.map((value, index) => ({
      name: paramsKeys[index],
      value: value as unknown as string,
    }));

    if (onURLParamsChange) {
      onURLParamsChange(paramsValues);
    }
  }, [onURLParamsChange, params, queryParams]);

  return { updateURLParam, getAllURLParams, getURLParamValue };
}
