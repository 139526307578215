import { coreApi } from '@apiRtk/core';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { alertService } from '@services/AlertService';
import _ from 'lodash';
import createSagaMiddleware from 'redux-saga';
import { alertsReducer } from './ducks/alerts/reducer';
import { ecdsReducer } from './ducks/ecds/reducer';
import { organizationsReducer } from './ducks/organization/reducer';
import { routerHistoryReducer } from './ducks/routerHistory/reducer';
import { sitesReducer } from './ducks/sites/reducer';
import { usersReducer } from './ducks/users/reducer';
import errorLoggingMiddleware from './middlewares/errorLoggingMiddleware';
import { listenerMiddleware } from './middlewares/listenerMiddleware';
import rootSaga from './saga';
import authReducer from './slices/auth';
import profileSwitcherReducer from './slices/profileSwitch';
import './slices/profileSwitch/listeners';
import './slices/auth/listeners';

export const rootReducer = combineReducers({
  [coreApi.reducerPath]: coreApi.reducer,
  routerHistory: routerHistoryReducer,
  alerts: alertsReducer,
  sites: sitesReducer,
  organizations: organizationsReducer,
  users: usersReducer,
  ecds: ecdsReducer,
  profileSwitcher: profileSwitcherReducer,
  authData: authReducer,
});

const appReducer: typeof rootReducer = (state, action) => {
  if (action.type === 'authData/logout') {
    const nonResetableReducers = _.pick(state, ['authData', 'alerts']);

    return rootReducer(nonResetableReducers as typeof state, action);
  }

  return rootReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware({ context: { alertService } });

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false, // TODO: enable check middlewares, when migration from redux-saga is done
    })
      .prepend(listenerMiddleware.middleware)
      .concat(coreApi.middleware, sagaMiddleware, errorLoggingMiddleware),
});

sagaMiddleware.run(rootSaga);
