import {
  DeviceParameter,
  EnergyFlowDeviceData,
  EnergyFlowDto,
} from '@appTypes/models/energyFlow.dto';
import CONFIG from '@config';
import { DiagramConfig } from '../types';

export const calculateCount = (value: number, values: number[]) => {
  const normalizedValue = Math.abs(value);

  if (normalizedValue === 0) {
    return 0;
  }

  const scaledValue = normalizedValue / Math.max(...values);

  return Math.round(scaledValue * (CONFIG.ENERGY_FLOW.MAX_ENERGY_NODES - 1)) + 1;
};

export const isOnRightSide = (rotation: number) => {
  const radians = (rotation * Math.PI) / 180;
  const posX = Math.cos(radians);
  return posX > 0;
};

export const trimSmallValues = (value: number): number =>
  value >= -CONFIG.ENERGY_FLOW.MINIMAL_VALID_VALUE &&
  value <= CONFIG.ENERGY_FLOW.MINIMAL_VALID_VALUE
    ? 0
    : value;

export const getMainValues = (data: EnergyFlowDto, config: DiagramConfig) =>
  Object.entries(data).reduce<number[]>((acc, [key, item]: [string, EnergyFlowDeviceData]) => {
    const { parameters: paramObj } = item || {};
    if (!paramObj) {
      return acc;
    }

    const parametersWithName = Object.entries(paramObj).map(([name, parameter]) => ({
      ...parameter,
      name,
    }));

    const { mainParameterName } = config[key];

    if (parametersWithName?.length > 0) {
      const mainParameter = parametersWithName.find((param) => param.name === mainParameterName);
      acc.push(Math.abs(mainParameter?.value || parametersWithName[0].value || 0));
    }

    return acc;
  }, []);

// NOTE: Temporary FE fix for metering errors, when BE solve this, remove following
export const getSanitisedParameter = ({
  name,
  parameter,
  deviceName,
}: {
  name: string;
  parameter: DeviceParameter;
  deviceName: string;
}) => {
  const { value, unit } = parameter;

  let safeValue = value || 0;

  if (parameter.unit === 'W') {
    safeValue = trimSmallValues(value || 0);
  }
  // consumption and generation cannot be negative
  if (['consumption', 'generation'].includes(deviceName) && safeValue < 0) {
    safeValue = 0;
  }

  return {
    name,
    unit,
    value: safeValue,
  };
};
export const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180);
