import { BasicFormField } from '@components/BasicForm';

export const createFields: BasicFormField[] = [
  {
    name: 'name',
    label: 'Organization Name',
  },
  {
    name: 'manager_name',
    label: 'Manager Name',
  },
  {
    name: 'manager_email',
    label: 'E-mail',
  },
  {
    name: 'manager_phone',
    label: 'Phone',
    inputProps: { maxLength: 15 },
  },
  {
    name: 'street',
    label: 'Street',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'postal_code',
    label: 'Postal Code',
  },
  {
    name: 'country',
    label: 'Country',
  },
];

export const updateFields = [
  ...createFields,
  {
    name: 'id',
    label: 'Id',
    readOnly: true,
  },
  {
    name: 'creation_date',
    label: 'Creation Date',
    readOnly: true,
    isDate: true,
  },
];
