import { OrganizationSimpleDto, OrganizationDto } from '@appTypes/models/organization.dto';
import { OrganizationFilters } from '@appTypes/organization/filters';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { OrganizationsAction } from './actionCreators';
import { OrganizationActionsType } from './actionTypes';

const initialState = {
  organizations: null as Nullable<WPage<OrganizationSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  filters: {
    page: 1,
    pageSize: 5,
    search: '',
    descending: false,
    orderBy: 'name',
  } as OrganizationFilters,
  current: {
    data: null as Nullable<OrganizationDto>,
    ...defaultRequestState,
  },
  create: defaultRequestState,
  update: defaultRequestState,
};

export type OrganizationStateType = typeof initialState;

export const organizationsReducer = (
  state = initialState,
  action: OrganizationsAction,
): OrganizationStateType => {
  const reducerHandler = createReducerHandler<OrganizationStateType, OrganizationSimpleDto>(
    'organizations',
    state,
  );

  switch (action.type) {
    case OrganizationActionsType.SET_ORGANIZATION_REQUEST_LOADING:
      return reducerHandler.setRequestLoading(action.payload);
    case OrganizationActionsType.SET_ORGANIZATION_REQUEST_ERROR:
      return reducerHandler.setRequestError(action.payload);
    case OrganizationActionsType.UPDATE_ORGANIZATION_FILTER:
      return reducerHandler.updateFilter(action.payload);
    case OrganizationActionsType.REQUEST_ORGANIZATIONS:
      return reducerHandler.request();
    case OrganizationActionsType.CLEAR_ORGANIZATION_CURRENT:
      return reducerHandler.clearCurrent();
    case OrganizationActionsType.REQUEST_ORGANIZATIONS_SUCCESS:
      return reducerHandler.requestSuccess(action.payload);
    case OrganizationActionsType.REQUEST_ORGANIZATIONS_FAILED:
      return reducerHandler.requestFailed(action.payload);
    case OrganizationActionsType.GET_ORGANIZATION_SUCCESS:
      return reducerHandler.getCurrent(action.payload);
    case OrganizationActionsType.CREATE_ORGANIZATION_SUCCESS:
      return reducerHandler.createSuccess();
    case OrganizationActionsType.UPDATE_ORGANIZATION_SUCCESS:
      return reducerHandler.updateSuccess(action.payload);
    default:
      return state;
  }
};
