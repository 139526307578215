import { useMemo } from 'react';
import { UserRole } from '@appTypes/models/user.dto';
import { selectAuthSlice } from '@redux/slices/auth';
import { useSelector } from 'react-redux';

type UseCurrentRoleOptions = {
  inheritRolePermissions?: boolean;
};

export const useCurrentRole = (
  { inheritRolePermissions = true }: UseCurrentRoleOptions = {
    inheritRolePermissions: true,
  },
) => {
  const { currentUser } = useSelector(selectAuthSlice);
  const roleName = currentUser?.role?.name;

  const currentUserRoles = useMemo(() => {
    const roles = Object.values(UserRole);
    const initReduceVal = {} as Record<UserRole, boolean>;

    if (inheritRolePermissions) {
      return roles.reduce((acc, role, index, arr) => {
        const previousRole = arr[index - 1];

        if (previousRole && acc[previousRole]) {
          return {
            ...acc,
            [role]: true,
          };
        }

        return {
          ...acc,
          [role]: role === roleName,
        };
      }, initReduceVal);
    }

    return roles.reduce(
      (acc, role) => ({
        ...acc,
        [role]: role === roleName,
      }),
      initReduceVal,
    );
  }, [inheritRolePermissions, roleName]);

  return {
    hasRole(role: UserRole) {
      return currentUserRoles[role];
    },
  };
};
