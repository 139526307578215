import { useEffect, useMemo } from 'react';
import { OrganizationDto, UpdateOrganizationDto } from '@appTypes/models/organization.dto';
import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { RelationsForm } from '@components/RelationsForm/RelationsForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDataByPreviousPath } from '@hooks';
import { fetchedGetOrganization } from '@redux/ducks/organization/actionCreators';
import { getOrganizationsSelector } from '@redux/ducks/organization/selectors';
import { paths } from 'paths';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createFields, updateFields } from './fields';
import { SitesRelations } from './Relations/SitesRelations';
import { UsersRelations } from './Relations/UsersRelations';
import { validationSchema } from './validations';

type OrganizationFormProps = {
  onSubmit(data: UpdateOrganizationDto): void;
  organizationId?: OrganizationDto['id'];
  viewOnlyMode?: boolean;
};

const getRelations = (organizationId?: number) => {
  if (!organizationId) {
    return {};
  }

  return {
    [ConfiguredItem.SITES]: {
      component: () => <SitesRelations organizationId={organizationId} />,
      addTxt: 'Add related sites',
    },
    [ConfiguredItem.USERS]: {
      component: () => <UsersRelations organizationId={organizationId} />,
      addTxt: 'Add related users',
    },
  };
};

export const OrganizationForm = ({
  onSubmit,
  organizationId,
  viewOnlyMode = false,
}: OrganizationFormProps) => {
  const isCreateMode = organizationId === undefined;
  const fields = isCreateMode ? createFields : updateFields;

  const dispatch = useDispatch();

  const { current } = useSelector(getOrganizationsSelector);
  const { loading, error, data: currentData } = current;

  useEffect(() => {
    if (isCreateMode) return;

    dispatch(fetchedGetOrganization(organizationId));
  }, [dispatch, isCreateMode, organizationId]);

  const organizationForm = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = organizationForm;

  useEffect(() => {
    if (!error && currentData && !isCreateMode) {
      reset(currentData);
    }
  }, [current, currentData, error, isCreateMode, reset]);

  const relations = useMemo(() => getRelations(organizationId), [organizationId]);

  const forbiddenPaths = [paths.configurationUser, paths.configurationSite];
  const backBtnTxt = useDataByPreviousPath<string>(({ isPreviousPath, noPreviousPath }) => {
    if (isPreviousPath([paths.configuration, ...forbiddenPaths]) || noPreviousPath) {
      return 'Back to configuration list';
    }

    return 'Back';
  });

  return (
    <RelationsForm
      readOnly={viewOnlyMode}
      isCreateMode={isCreateMode}
      loading={loading}
      error={error}
      onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      relationsGridWrapperProps={{ relations }}
      basicFormProps={{ ...organizationForm, fields }}
      title="Organization"
    >
      <ButtonBack path={paths.configuration} query={{ selectedItem: ConfiguredItem.ORGANIZATIONS }}>
        {backBtnTxt}
      </ButtonBack>
    </RelationsForm>
  );
};
