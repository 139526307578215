import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

type AutocloseAlertProps = AlertProps & {
  time?: number;
  message: string;
};

const FadeAlert = styled(Alert)(({ theme }) => ({
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.standard,
  }),
}));

const AutocloseAlert = ({ time = 2000, message, ...props }: AutocloseAlertProps) => {
  const [open, setOpen] = useState(true);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, time);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  const handleExited = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Fade in={open} timeout={{ enter: 300, exit: 300 }} onExited={handleExited}>
      <FadeAlert {...props}>{message}</FadeAlert>
    </Fade>
  );
};

export default AutocloseAlert;
