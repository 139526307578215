export const createDeviceField = (deviceId: string, fieldName: string) =>
  `${deviceId}*#$*${fieldName}`
    .replaceAll('.', '/@#:')
    .replaceAll('[', '/#@;')
    .replaceAll(']', ';/#@');

export const getDeviceDetailsFromField = (field: string) => {
  const [id, fieldName] = field
    .replaceAll('/@#:', '.')
    .replaceAll('/#@;', '[')
    .replaceAll(';/#@', ']')
    .split('*#$*');

  return { id, fieldName };
};
