import { SiteDto } from '@appTypes/models/site.dto';
import { SECONDS_MINUTE } from '@settings/constants';
import { convertLocalDateToApiString, createParams } from '@utils/utils';
import { coreApi } from './core';

type AnalysisParams = {
  siteId: SiteDto['id'];
};

type GraphParams = {
  dataLink: string;
  startDate: Date;
  endDate: Date;
  limit: number;
};

type YAxis = {
  unit: string;
  min: number;
  max: number;
};

type DataPoint = {
  name: string;
  value: [string, number];
};

type Series = {
  isEmpty: boolean;
  label: string;
  uniqueName: string;
  yaxisIndex: number;
  seriesMin: number;
  seriesMax: number;
  dataPoints: DataPoint[];
};

export type GraphDto = {
  graphId: string;
  title: string;
  dataLink: string;
  yaxes: YAxis[];
  series: Series[];
  emptySeries: string[];
};

const analysisApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalysis: builder.query<GraphDto[], AnalysisParams>({
      query: ({ siteId }) => `sites/${siteId}/analysis`,
    }),
    getGraph: builder.query<GraphDto, GraphParams>({
      query: ({ dataLink, startDate, endDate, limit }) =>
        `${dataLink}?${createParams({
          limit,
          start: convertLocalDateToApiString(startDate),
          end: convertLocalDateToApiString(endDate),
          static_response: 'false',
        })}`,
      keepUnusedDataFor: SECONDS_MINUTE,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnalysisQuery, useGetGraphQuery } = analysisApi;
