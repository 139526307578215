import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useOnUpdate = (effect: EffectCallback, deps: DependencyList) => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return undefined;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
