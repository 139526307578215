import { SiteIdName } from '@appTypes/models/site.dto';
import { dictionary } from '@settings/dictionary';
import { storage } from './localStorage';

const BOOKMARKED_SITES_KEY = 'bookmarkedSites';

type BookmarkedSite = {
  index?: number;
  site: SiteIdName;
};

type ActionResult = {
  success: boolean;
  message?: string;
};

export type BookmarkedSitesService = {
  getBookmarkedSites: () => SiteIdName[];
  bookmarkSite: (site: SiteIdName) => ActionResult;
  unbookmarkSite: (site: SiteIdName) => ActionResult;
  renameSite: (siteId: number, newName: string) => ActionResult;
  isSiteBookmarked: (siteId: number) => boolean;
};

export const getBookmarkedSitesService = (maximumCapacity: number): BookmarkedSitesService => {
  const getStoredSites = (): BookmarkedSite[] => {
    const storedSites = storage.get<BookmarkedSite[]>(BOOKMARKED_SITES_KEY);
    return Array.isArray(storedSites) ? storedSites : [];
  };

  const getBookmarkedSites = (): SiteIdName[] => {
    const storedSites = getStoredSites();
    return storedSites.map((site) => site.site).sort((a, b) => a.name.localeCompare(b.name));
  };

  const getNextIndex = () => {
    const storedSites = getStoredSites();
    if (storedSites.length === 0) {
      return 1;
    }
    return Math.max(...storedSites.map((site) => site.index || 0)) + 1;
  };

  const updateSiteNameById = (siteId: number, newName: string, storedSites: BookmarkedSite[]) =>
    storedSites.map((storedSite) =>
      storedSite.site.id === siteId
        ? { ...storedSite, site: { ...storedSite.site, name: newName } }
        : storedSite,
    );

  const isSiteBookmarked = (siteId: number): boolean => {
    const storedSites = getStoredSites();
    return storedSites.some((site) => site.site.id === siteId);
  };

  const bookmarkSite = (site: SiteIdName): ActionResult => {
    const index = getNextIndex();
    const storedSite: BookmarkedSite = { index, site };
    const storedSites = getStoredSites();

    if (isSiteBookmarked(site.id)) {
      return {
        success: false,
        message: dictionary.errorSiteAlreadyBookmarked(site.name),
      };
    }

    if (storedSites.length >= maximumCapacity) {
      return {
        success: false,
        message: dictionary.errorBookmarkLimitReached(site.name, maximumCapacity),
      };
    }

    storedSites.push(storedSite);
    storage.set(BOOKMARKED_SITES_KEY, storedSites);
    dispatchEvent(new Event('bookmarked-sites-changed'));
    return {
      success: true,
      message: dictionary.successSiteBookmarked(site.name),
    };
  };

  const unbookmarkSite = (site: SiteIdName): ActionResult => {
    let storedSites = getStoredSites();

    if (!isSiteBookmarked(site.id)) {
      return {
        success: false,
        message: dictionary.errorSiteNameNotBookmarked(site.name),
      };
    }

    storedSites = storedSites.filter((storeSite) => storeSite.site.id !== site.id);

    storage.set(BOOKMARKED_SITES_KEY, storedSites);
    dispatchEvent(new Event('bookmarked-sites-changed'));
    return {
      success: true,
      message: dictionary.successSiteUnbookmarked(site.name),
    };
  };

  const renameSite = (siteId: number, newName: string): ActionResult => {
    let storedSites = getStoredSites();

    if (!isSiteBookmarked(siteId)) {
      return {
        success: false,
        message: dictionary.errorSiteNotBookmarked,
      };
    }

    storedSites = updateSiteNameById(siteId, newName, storedSites);

    storage.set(BOOKMARKED_SITES_KEY, storedSites);
    dispatchEvent(new Event('bookmarked-sites-changed'));
    return {
      success: true,
    };
  };

  return { getBookmarkedSites, bookmarkSite, unbookmarkSite, renameSite, isSiteBookmarked };
};
