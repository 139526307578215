import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, styled } from '@mui/material';
import { MAIN_MENU_WIDTH } from '@settings/constants';
import { useLocation } from 'react-router';
import { palette } from 'styles/palette';

export const Container = styled(Box)`
  height: 100%;
  overflow-y: scroll;
  background: ${palette.white};
  width: ${MAIN_MENU_WIDTH}px;
  flex-shrink: 0;
`;

const ToggleButton = styled(Box)`
  position: absolute;
  top: 32px;
  left: 6px;
  z-index: 1500;
`;

const MenuMobile = ({ children }: React.PropsWithChildren) => {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();

  React.useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <>
      <ToggleButton>
        <IconButton
          data-testid="mainmenu-toggle-button"
          onClick={() => {
            setOpen(!open);
          }}
          color="default"
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </ToggleButton>
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Container>{children}</Container>
      </Drawer>
    </>
  );
};

export default MenuMobile;
