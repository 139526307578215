import { usersAPI } from '@api/users';
import { UserDto, UserSimpleDto, CreateUserDto, UpdateUserDto } from '@appTypes/models/user.dto';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { fork } from 'redux-saga/effects';
import {
  requestUsers,
  requestUsersSuccess,
  requestUsersFailed,
  updateUserSuccess,
  getUserSuccess,
  createUserSuccess,
  setUserRequestLoading,
  setUserRequestError,
} from './actionCreators';
import { UserActionsType } from './actionTypes';
import { getUsersSelector, UsersSelectorReturn } from './selectors';

export function* usersWatcher() {
  yield fork<
    CreateSagaCrudWatcher<UsersSelectorReturn, UserDto, CreateUserDto, UpdateUserDto, UserSimpleDto>
  >(createSagaCRUDWatcher, {
    selector: getUsersSelector,
    setRequestLoading: setUserRequestLoading,
    setRequestError: setUserRequestError,
    getList: {
      actionName: UserActionsType.FETCHED_USERS,
      updateFilterActionName: UserActionsType.UPDATE_USER_FILTER,
      getCountActionName: UserActionsType.FETCHED_USERS_COUNT,
      apiCall: usersAPI.getUsers,
      request: requestUsers,
      requestSuccess: requestUsersSuccess,
      requestFailed: requestUsersFailed,
    },
    getSingle: {
      actionName: UserActionsType.FETCHED_GET_USER,
      clearCurrentActionName: UserActionsType.CLEAR_USER_CURRENT,
      apiCall: usersAPI.getUser,
      onSuccess: getUserSuccess,
    },
    create: {
      actionName: UserActionsType.FETCHED_CREATE_USER,
      apiCall: usersAPI.createUser,
      onSuccess: createUserSuccess,
    },
    update: {
      actionName: UserActionsType.FETCHED_UPDATE_USER,
      apiCall: usersAPI.updateUser,
      onSuccess: updateUserSuccess,
      cancelCurrentCleanup: true,
    },
  });
}
