import {
  create as createDefault,
  deleteItem as deleteItemDefault,
  getSingle as getSingleDefault,
  update as updateDefault,
} from './crud';
import { getAll as getAllDefault } from './getAll';
import { makeEndpointHandler } from './makeEndpointHandler';

export const apiCore = {
  makeEndpointHandler,
  getAll: getAllDefault,
  getSingle: getSingleDefault,
  create: createDefault,
  update: updateDefault,
  deleteItem: deleteItemDefault,
};
