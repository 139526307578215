import { useState, useEffect, useRef } from 'react';

interface DynamicWidthResult {
  width: number;
  parentRef: React.RefObject<HTMLDivElement>;
}

function useDynamicWidth(): DynamicWidthResult {
  const [width, setWidth] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const resizeTimeoutRef = useRef<Nullable<number>>(null);

  useEffect(() => {
    const getParentWidth = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.getBoundingClientRect().width;
        setWidth(parentWidth);
      }
    };

    getParentWidth();

    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      // NOTE: postpone reading of parent width by few ms to read correct size after rapid layout change
      resizeTimeoutRef.current = window.setTimeout(() => {
        getParentWidth();
      }, 10);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []);

  return { width, parentRef };
}

export default useDynamicWidth;
