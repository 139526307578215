import { LocalStorageItems } from '@appTypes/localStorage';

type AllowedTypes = string | object | boolean;

export interface LocalStorageService {
  get: <T extends AllowedTypes>(key: LocalStorageItems) => T | null;
  set: <T>(key: LocalStorageItems, value: T) => void;
  remove: (key: LocalStorageItems) => void;
}

export const getLocalStorageService = (): LocalStorageService => {
  const get = <T extends AllowedTypes>(key: LocalStorageItems): T | null => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    const jsonStringPattern = /^\s*(\{.*\}|\[.*\])\s*$/;
    if (jsonStringPattern.test(item)) {
      return JSON.parse(item);
    }

    if (item === 'true') {
      return true as T;
    }
    if (item === 'false') {
      return false as T;
    }

    return item as unknown as T;
  };

  const set = <T>(key: LocalStorageItems, value: T) => {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else if (typeof value === 'number') {
      localStorage.setItem(key, String(value));
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  const remove = (key: LocalStorageItems) => {
    localStorage.removeItem(key);
  };

  return { get, set, remove };
};

export const storage = getLocalStorageService();
