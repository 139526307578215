import { UpdateUserDto } from '@appTypes/models/user.dto';
import { useRequestSuccessAlert } from '@hooks';
import { fetchedUpdateUser } from '@redux/ducks/users/actionCreators';
import { getUsersSelector } from '@redux/ducks/users/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserForm } from './UserForm/UserForm';

const UpdateUser = () => {
  const params = useParams();
  const id = params.id as string;

  const userId = parseInt(id, 10);

  const dispatch = useDispatch();

  const onSubmit = (data: UpdateUserDto) => {
    dispatch(fetchedUpdateUser(data));
  };

  const { update } = useSelector(getUsersSelector);

  useRequestSuccessAlert(update, 'User successfully updated.');

  if (Number.isNaN(userId)) return null;

  return <UserForm userId={userId} onSubmit={onSubmit} />;
};

export default UpdateUser;
