import { StateFilter } from '@appTypes/helpers/filters';
import { ButtonPrimary } from '@components/Buttons/Buttons';
import { PageWrapper } from '@components/PageWrapper/PageWrapper';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useRedirect, useFilters } from '@hooks';
import { Settings } from '@mui/icons-material';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { NotificationList } from './NotificationList';

const Notifications = () => {
  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    siteId,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setSiteId,
    setStateFilter,
  } = useFilters({
    urlMirroringActive: true,
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'start', desc: true }],
  });

  const redirect = useRedirect();

  return (
    <PageWrapper title="Global notifications" headerIcon={NotificationsNoneRoundedIcon}>
      <ButtonPrimary
        endIcon={<Settings />}
        onClick={() => {
          redirect((paths) => paths.notificationRules);
        }}
      >
        Setup rules
      </ButtonPrimary>
      <>
        <Toolbar
          showSiteSelect
          siteId={siteId}
          searchString={searchString}
          stateFilter={stateFilter || StateFilter.ACTIVE}
          onSetSiteId={setSiteId}
          onSearch={setSearchString}
          onSetStateFilter={setStateFilter}
        />
        <NotificationList
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      </>
    </PageWrapper>
  );
};

export default Notifications;
