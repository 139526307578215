import { AuthorizedDto, SetPasswordDto, SignInDto, VerifyCodeDto } from '@appTypes/models/auth.dto';
import { coreApi } from './core';

const endpointRoot = 'login';

export const loginApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthorizedDto, SignInDto>({
      query: (credentials) => {
        const formUrlEncodedData = new URLSearchParams(credentials).toString();

        return {
          url: `/${endpointRoot}`,
          method: 'POST',
          data: formUrlEncodedData,
        };
      },
    }),
    refresh: builder.mutation<{ access_token: string; refresh_token: string }, string>({
      query: (refresh_token) => ({
        url: `/${endpointRoot}/refresh`,
        method: 'POST',
        data: { refresh_token },
      }),
    }),
    watcher: builder.mutation<AuthorizedDto, string>({
      query: (email) => ({
        url: `/${endpointRoot}/watcher`,
        method: 'POST',
        data: {
          email,
        },
      }),
    }),
    sendVerificationEmail: builder.mutation<string, string>({
      query: (email) => ({
        url: `/${endpointRoot}/send-verification-email`,
        method: 'POST',
        data: {
          email,
        },
      }),
    }),
    verifyCode: builder.mutation<string, VerifyCodeDto>({
      query: ({ email, code }) => ({
        url: `/${endpointRoot}/verify-code`,
        method: 'POST',
        data: {
          email,
          code,
        },
      }),
    }),
    setPassword: builder.mutation<string, SetPasswordDto>({
      query: ({ email, code, password }) => ({
        url: `/${endpointRoot}/set-password`,
        method: 'POST',
        data: {
          email,
          code,
          password,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useSendVerificationEmailMutation,
  useVerifyCodeMutation,
  useSetPasswordMutation,
} = loginApi;
