import { useGetOrganizationsAllForSelectQuery } from '@apiRtk/organizations';
import { CustomSelectBoxProps, SelectBox } from '@components/SelectBox/SelectBox';

export const OrganizationsSelect = (props: Omit<CustomSelectBoxProps<number>, 'options'>) => {
  const { data, isLoading } = useGetOrganizationsAllForSelectQuery();

  return (
    <SelectBox<number>
      formControlProps={{ sx: { width: '100%' } }}
      {...props}
      options={data || []}
      loading={isLoading || !data}
    />
  );
};
