import { ReactNode } from 'react';
import { Typography, styled } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as ReactRouterLink, NavLinkProps } from 'react-router-dom';
import { CenterX } from './LayoutUtils';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'inline-block',
  // More info: https://github.com/mui/material-ui/issues/15759
}) as typeof Link;

const StyledCenterX = styled(CenterX, {
  // prop as string: https://github.com/emotion-js/emotion/issues/1641#issuecomment-554934623
  shouldForwardProp: (prop: string) => !['noUnderline'].includes(prop),
})<{ noUnderline: boolean }>`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};

    > * {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }

  > span {
    border-bottom: ${({ noUnderline, theme }) =>
      noUnderline ? 'none' : `1px solid ${theme.palette.primary.main}`};
    font-weight: bold;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      border-bottom: ${({ noUnderline, theme }) =>
        noUnderline ? 'none' : `1px solid ${theme.palette.primary.light}`};
    }
  }
`;

export type AppLinkProps = Omit<LinkProps, 'component' | 'underline'> & {
  to?: NavLinkProps['to'];
  icon?: ReactNode;
  noUnderline?: boolean;
};

export const AppLink = ({
  children,
  icon,
  to = '#',
  noUnderline = false,
  ...props
}: AppLinkProps) => (
  <StyledLink component={ReactRouterLink} underline="none" to={to} {...props}>
    <StyledCenterX gap={0.5} noUnderline={noUnderline}>
      {icon || null}
      <Typography component="span">{children}</Typography>
    </StyledCenterX>
  </StyledLink>
);
