import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { GetAllDto } from './common.dto';
import { OrganizationDto, OrganizationSimpleDto } from './organization.dto';

/**
 * Roles are listed from most important to least
 * as it is necessary when handling role inheritance.
 * Example: when currentUser is ADMIN it is also handled as USER
 * by useCurrentRole hook (by default).
 */
export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface UserDto {
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  phone: Nullable<string>;
  street: Nullable<string>;
  city: Nullable<string>;
  postal_code: Nullable<string>;
  country: Nullable<string>;
  timezone: Nullable<string>;
  organization_id: number;
  id: number;
  email: string;
  is_active: boolean;
  role: {
    id: number;
    name: UserRole;
  };
  creation_date: string;
  last_modify_date: string;
  settings: Nullable<string>;
  organization?: OrganizationSimpleDto;
  sites: SiteSimpleDto[];
}

export type UserSimpleDto = Omit<UserDto, 'sites'>;

export type UsersResponse = {
  count: number;
  users: UserSimpleDto[];
};

export interface CreateUserDto {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  organization_id: Nullable<OrganizationDto['id']>;
  role_name: UserRole;
}

export interface UpdateUserDto extends CreateUserDto {
  id: UserDto['id'];
}

export interface UsersDto extends GetAllDto {
  users: UserDto[];
}
