import { SvgIconComponent } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { palette } from 'styles/palette';

export interface ILabeledInputProps {
  label: string;
  IconComponent: SvgIconComponent;
  renderedInputComponent: JSX.Element;
}

export const LabeledInput = ({
  label,
  renderedInputComponent,
  IconComponent,
}: ILabeledInputProps) => (
  <Box sx={{ display: 'grid', gap: 2 }}>
    <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 0.75 }}>
      <IconComponent color="secondary" />
      <Typography color={palette.primary.main}>{label}</Typography>
    </Box>
    {renderedInputComponent}
  </Box>
);
