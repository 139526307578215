export const wattstorSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    width="100%"
    height="100%"
    viewBox="0 0 49 24.06"
  >
    <defs>
      <linearGradient
        id="linear-gradient-header-logo"
        x1="6.33"
        y1="17.81"
        x2="36.49"
        y2="-.85"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff387b" />
        <stop offset=".13" stopColor="#ff4476" />
        <stop offset=".38" stopColor="#ff656b" />
        <stop offset=".71" stopColor="#ff9a57" />
        <stop offset="1" stopColor="#ffcf44" />
      </linearGradient>
    </defs>
    <path
      d="M14.53 24a3.11 3.11 0 01-2.63-1.4L.49 4.82A3.13 3.13 0 013.13 0h13.72a3.13 3.13 0 012.63 1.44l9.26 14.45L38 6.26h-9A3.13 3.13 0 1129 0h16.35a3.13 3.13 0 012.25 5.3L30.5 23.08a3.1 3.1 0 01-2.56 1 3.16 3.16 0 01-2.33-1.43L15.13 6.26H8.85l8.31 13A3.12 3.12 0 0114.53 24z"
      fill="url(#linear-gradient-header-logo)"
    />
  </svg>
);

export const icons = {
  arrowChevron: (
    <g>
      <path d="M12 17a1 1 0 0 1-.707-1.707L14.586 12l-3.293-3.293a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4A1 1 0 0 1 12 17z" />
      <path d="M8 17a1 1 0 0 1-.707-1.707L10.586 12 7.293 8.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4A1 1 0 0 1 8 17z" />
    </g>
  ),
};
