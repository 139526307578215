import ConfirmModal from '@components/Modals/ConfirmModal';
import { usePalette } from '@hooks';
import { Typography } from '@mui/material';

interface DataExportSuccessMessageProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataExportSuccessMessage = ({ open, setOpen }: DataExportSuccessMessageProps) => {
  const currentPalette = usePalette();

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      confirmButtonCaption="Close"
      dialogTitle="Data export request"
      noCancelButton
    >
      <Typography fontSize={16} color={currentPalette.text.secondary}>
        Data export request received, we will prepare your data and send it to you via email
        shortly.
      </Typography>
    </ConfirmModal>
  );
};

export default DataExportSuccessMessage;
