import { SearchInput } from '@components/SearchInput';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { DataGridProps } from '@mui/x-data-grid';

export type RelationsGridProps<TItem extends {} = {}> = {
  items: { id: number | string }[];
  columns: DataGridProps['columns'];
  searchTxt: string;
  setSearchTxt: (val: string) => void;
  searchFields: (keyof TItem)[];
};

export function RelationsGrid<TItem extends {}>({
  items,
  columns,
  searchTxt,
  setSearchTxt,
  searchFields,
}: RelationsGridProps<TItem>) {
  const itemsFiltered =
    searchTxt === ''
      ? items
      : (items as unknown as TItem[]).filter((item) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const field of searchFields) {
            const searchTxtLowercase = searchTxt.toLowerCase();
            const itemFieldVal = (item[field] as unknown as string)?.toLowerCase();

            if (itemFieldVal?.includes(searchTxtLowercase)) {
              return true;
            }
          }

          return false;
        });

  return (
    <>
      <SearchInput value={searchTxt} onValueChange={setSearchTxt} sx={{ mb: 2 }} />
      <XDataGrid
        items={itemsFiltered as TItem[]}
        itemsCount={itemsFiltered.length}
        columns={columns}
      />
    </>
  );
}
