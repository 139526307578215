import { useState } from 'react';
import { CenterX } from '@components/LayoutUtils';
import { Button } from '@mui/material';
import { DateSpanProps, DateTimePickerDate } from '../types';
import { DateTime } from './DateTime/DateTime';

export const DateSpanInputs = ({
  initStartVal,
  initEndVal,
  onApplyClick,
  displayFromInput = true,
}: DateSpanProps) => {
  const [startVal, setStartVal] = useState<DateTimePickerDate>(initStartVal);
  const [endVal, setEndVal] = useState<DateTimePickerDate>(initEndVal);

  return (
    <CenterX gap={1}>
      {displayFromInput && (
        <DateTime
          label="From"
          val={startVal}
          onChange={setStartVal}
          maxDateTime={endVal}
          size="tiny"
        />
      )}
      <DateTime label="To" val={endVal} onChange={setEndVal} minDateTime={startVal} size="tiny" />
      <Button sx={{ py: 0.5, px: 1, fontSize: 12 }} onClick={() => onApplyClick(startVal, endVal)}>
        Apply
      </Button>
    </CenterX>
  );
};
