import * as Yup from 'yup';
import { AnyObject } from 'yup';

export type StringValidator = Yup.StringSchema<any, AnyObject, any>;

type StringValidatorFn = (label: string, validator?: StringValidator) => StringValidator;

export function createStringValidator(...validatorFns: StringValidatorFn[]) {
  return (label: string, validator?: StringValidator) =>
    validatorFns.reduce((acc, validatorFn) => validatorFn(label, acc), validator || Yup.string());
}
