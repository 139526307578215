import {
  Pagination as MuiPagination,
  PaginationItem as MuiPaginationItem,
  Stack,
} from '@mui/material';
import { withStyles } from '@mui/styles';

interface PaginationProps {
  currentPage: number | string;
  count?: number;
  onChange?(value: number): void;
  updateURLParam?(param: 'page', value: Nullable<string>): void;
}

const PaginationItem = withStyles(
  (theme) => ({
    page: {
      background: 'none !important',
      borderRadius: '0px !important',
    },
    selected: {
      borderBottom: `2px solid ${theme.palette.primary.light} !important`,
      pb: 2,
    },
    previousNext: {
      background: `${theme.palette.background.paper} !important`,
      borderRadius: '5px !important',
      color: `${theme.palette.secondary.main} !important`,
      margin: '0px 25px !important',
    },
    disabled: {
      opacity: '1 !important',
    },
  }),
  { withTheme: true },
)(MuiPaginationItem);

export const Pagination = ({
  currentPage,
  count = 10,
  onChange,
  updateURLParam,
}: PaginationProps) => {
  const page = typeof currentPage === 'string' ? parseInt(currentPage, 10) : currentPage;

  return (
    <Stack spacing={4}>
      <MuiPagination
        page={page}
        count={count}
        sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
        onChange={(_, value) => {
          if (updateURLParam) {
            updateURLParam('page', value === 1 ? null : `${value}`);
          }

          if (onChange) {
            onChange(value);
          }
        }}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            data-testid={item.type === 'page' ? `pagination-item-${item.page}` : undefined}
            data-cy={item.type === 'page' ? `pagination-item` : undefined}
            size="large"
          />
        )}
      />
    </Stack>
  );
};
