import { AuthorizedDto } from '@appTypes/models/auth.dto';
import { UserDto } from '@appTypes/models/user.dto';
import { AppStateType } from '@appTypes/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isAuth: false,
  currentUser: null as Nullable<UserDto>,
  loading: true,
};

export type AuthStateType = typeof initialState;

const authSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    // Note: Empty actions handled by listeners
    // eslint-disable-next-line
    authorise: (_, action: PayloadAction<AuthorizedDto>) => {},
    reauthorise: () => {},
    logout: () => {},
    setIsAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    authSuccess: (state, action: PayloadAction<UserDto>) => {
      state.currentUser = action.payload;
      state.isAuth = true;
    },
    logoutSuccess: (state) => {
      state.isAuth = false;
      state.currentUser = null;
      state.loading = false;
    },
  },
});

export const selectAuthSlice = (state: AppStateType) => state.authData;

export const authActions = authSlice.actions;

export default authSlice.reducer;
