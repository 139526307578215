import { SetStateAction, useState } from 'react';
import { ButtonCancel, ButtonSave } from '@components/Buttons/Buttons';
import { CenterXY, CenterY, PaperLight } from '@components/LayoutUtils';
import { BoxModal } from '@components/Modals/BoxModal';
import { Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { endOfDay, isBefore, startOfDay, subDays } from 'date-fns';

type DateRangePickerProps = {
  setCalendarOpen: React.Dispatch<SetStateAction<boolean>>;
  onChange: (start: Date, end: Date) => void;
};

const DateRangePicker = ({ setCalendarOpen, onChange }: DateRangePickerProps) => {
  const today = new Date();

  const [datetimeRange, setDatetimeRange] = useState({
    start: startOfDay(subDays(today, 3)),
    end: endOfDay(today),
  });

  const handleStartDateChange = (start: Date) => {
    setDatetimeRange({
      start: startOfDay(start),
      end: datetimeRange.end,
    });
  };

  const handleEndDateChange = (end: Date) => {
    // NOTE: Disallow selecting lower endDate than startDate
    if (isBefore(end, datetimeRange.start)) {
      setDatetimeRange({
        start: startOfDay(end),
        end: endOfDay(end),
      });

      return;
    }

    setDatetimeRange({
      start: datetimeRange.start,
      end: endOfDay(end),
    });
  };

  const handleConfirmClick = () => {
    setCalendarOpen(false);
    onChange(datetimeRange.start, datetimeRange.end);
  };

  return (
    <BoxModal open onClose={() => setCalendarOpen(false)}>
      <>
        <Typography variant="h5" pb={2}>
          Please select date range
        </Typography>
        <CenterXY gap={2} flexWrap="wrap">
          <PaperLight sx={{ p: 1 }}>
            <Typography variant="h6">Start date</Typography>
            <DateCalendar
              value={datetimeRange.start}
              onChange={handleStartDateChange}
              maxDate={datetimeRange.end}
            />
          </PaperLight>
          <PaperLight sx={{ p: 1 }}>
            <Typography variant="h6">End date</Typography>
            <DateCalendar
              value={datetimeRange.end}
              onChange={handleEndDateChange}
              maxDate={today}
            />
          </PaperLight>
        </CenterXY>
        <CenterY pt={2} gap={1}>
          <ButtonSave onClick={handleConfirmClick}>Apply</ButtonSave>
          <ButtonCancel onClick={() => setCalendarOpen(false)}>Close</ButtonCancel>
        </CenterY>
      </>
    </BoxModal>
  );
};

export default DateRangePicker;
