import { ApiFetchListParams } from '@appTypes/helpers/api';
import {
  CreateOrganizationDto,
  OrganizationDto,
  OrganizationSimpleDto,
  UpdateOrganizationDto,
} from '@appTypes/models/organization.dto';
import { OrganizationFilters } from '@appTypes/organization/filters';
import { apiCore } from './apiCore/apiCore';

const epHandler = apiCore.makeEndpointHandler<OrganizationDto, OrganizationSimpleDto>(
  'organizations',
);

export const organizationsAPI = {
  createOrganization(dto: CreateOrganizationDto) {
    return epHandler.create(dto);
  },
  getOrganizations(params: ApiFetchListParams<OrganizationFilters>) {
    return epHandler.getAll('organizations', params);
  },
  getOrganization(id: number) {
    return epHandler.getSingle(id);
  },
  updateOrganization(dto: UpdateOrganizationDto) {
    return epHandler.update(dto);
  },
};
