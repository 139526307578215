/* eslint-disable prefer-destructuring */
import { AppDispatch, AppStateType } from '@appTypes/redux';
import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit';

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<AppStateType, AppDispatch>; // TODO: infer proper AppDispatch with possibility to use RTK API queries inside listeners

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<AppStateType, AppDispatch>;
