import { OrganizationSimpleDto } from '@appTypes/models/organization.dto';
import { AppStateType } from '@appTypes/redux';
import { createCurrentPageSelector } from '@redux/common/createCurrentPageSelector';

export const getOrganizationsSelector = createCurrentPageSelector<
  AppStateType['organizations'],
  'organizations',
  OrganizationsSelectorReturn
>('organizations', 'currentPageOrganizations');

export type OrganizationsSelectorReturn = AppStateType['organizations'] & {
  currentPageOrganizations: WPage<OrganizationSimpleDto>[];
};
