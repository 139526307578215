import { useMemo } from 'react';
import { OrganizationSimpleDto } from '@appTypes/models/organization.dto';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { useRedirect, useXDataGridConnect } from '@hooks';
import { GridCellParams } from '@mui/x-data-grid';
import {
  fetchedOrganizations,
  updateOrganizationsFilter,
} from '@redux/ducks/organization/actionCreators';
import {
  getOrganizationsSelector,
  OrganizationsSelectorReturn,
} from '@redux/ducks/organization/selectors';

type OrganizationListItemDto = WPage<OrganizationSimpleDto>;

type OrganizationGridCellProps = GridCellParams<string, OrganizationListItemDto>;

export const OrganizationsList = () => {
  const redirect = useRedirect();

  const dataGridColumns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          flex: 0.5,
        },
        {
          field: 'manager_name',
          headerName: 'Main Contact',
          flex: 0.5,
        },
        {
          field: 'city',
          headerName: 'City',
          flex: 0.5,
        },
        {
          field: 'country',
          headerName: 'Country',
          minWidth: 200,
          renderCell: ({ row }: OrganizationGridCellProps) => (
            <DetailCell
              onDetailClick={() => redirect((paths) => paths.configurationOrganization(row.id))}
            >
              {row.country}
            </DetailCell>
          ),
        },
      ].map((column) => ({
        minWidth: 100,
        flex: 1,
        ...column,
      })),
    [redirect],
  );

  const organizationsGridConnection = useXDataGridConnect<
    OrganizationsSelectorReturn,
    OrganizationListItemDto
  >({
    selector: getOrganizationsSelector,
    currentItemsField: 'currentPageOrganizations',
    fetchPageAction: fetchedOrganizations,
    updateFilterAction: updateOrganizationsFilter,
  });

  return (
    <XDataGrid<OrganizationListItemDto>
      {...organizationsGridConnection}
      columns={dataGridColumns}
    />
  );
};
