import { DefaultRequestState } from '@redux/common/defaultRequestState';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { CreateAlert } from '@redux/ducks/alerts/actionTypes';
import { useDispatch } from 'react-redux';
import { useRequestSuccess } from './useRequestSuccess';

export const useRequestSuccessAlert = (
  requestState: DefaultRequestState,
  message: string,
  alertOptions?: Omit<CreateAlert, 'message'>,
) => {
  const dispatch = useDispatch();

  useRequestSuccess(requestState, () => {
    dispatch(
      createAlert({
        variant: 'success',
        ...(alertOptions || {}),
        message,
      }),
    );
  });
};
