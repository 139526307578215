import { useGetOrganizationQuery } from '@apiRtk/organizations';
import { UserRole, UserSimpleDto } from '@appTypes/models/user.dto';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useFilters, useRedirect } from '@hooks';
import { Box, Button } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';
import { createFullName } from '@utils/data/createFullName';

type UsersGridCellProps = {
  roleName: string;
  id: number;
};

const CellWithRedirectButton = ({ roleName, id }: UsersGridCellProps) => {
  const redirect = useRedirect();

  const roleDisplay = (() => {
    if (roleName === UserRole.ADMIN) {
      return 'manager';
    }

    if (roleName === UserRole.USER) {
      return 'technician';
    }

    return roleName;
  })();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{roleDisplay}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationUser(id))}>
        User detail
      </Button>
    </Box>
  );
};

const columns: ColumnDef<UserSimpleDto>[] = [
  {
    header: 'User',
    accessorKey: 'user',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1 }}>
        <strong>{createFullName(row.original.first_name, row.original.last_name)}</strong>
        <div>{row.original.email}</div>
      </div>
    ),
  },
  {
    header: 'Role',
    accessorKey: 'role',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1, minWidth: 220 }}>
        <CellWithRedirectButton roleName={row.original.role.name} id={row.original.id} />
      </div>
    ),
  },
];

interface UsersRelationsProps {
  organizationId: number;
}

export const UsersRelations = ({ organizationId }: UsersRelationsProps) => {
  const searchFields = ['first_name', 'last_name', 'email'];

  const { data } = useGetOrganizationQuery(organizationId);

  const { sorting, searchString, pagination, setSearchString, setPagination } = useFilters({
    urlMirroringActive: false,
    sortingActive: false,
    pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE_SMALL,
    sortingState: [{ id: 'name', desc: false }],
  });

  const users = data?.users || [];

  const filteredData =
    searchString === ''
      ? users
      : users.filter((item) =>
          searchFields.some((field) => {
            const searchTxtLowercase = searchString.toLowerCase();
            const itemFieldVal = (item[field as keyof UserSimpleDto] as string)?.toLowerCase();
            return itemFieldVal?.includes(searchTxtLowercase);
          }),
        );

  const paginatedData = filteredData.slice(
    pagination.pageIndex * pagination.pageSize,
    (pagination.pageIndex + 1) * pagination.pageSize,
  );

  return (
    <>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<UserSimpleDto>
        columns={columns}
        rows={paginatedData}
        itemsCount={filteredData.length}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={() => {}}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};
