import { createContext, useContext, useRef, useState } from 'react';

const FormGroupContext = createContext(
  {} as {
    subscribeToFormGroup: (name: string) => void;
    onRendered: (name: string) => void;
  },
);

type FormGroupProviderProps = {
  onAllFieldsRendered?: () => void;
  children: React.ReactNode | ((args: { allFieldsRendered: boolean }) => React.ReactNode);
};

export const useFormGroupContext = () => useContext(FormGroupContext);

export const FormGroupProvider = ({ children, onAllFieldsRendered }: FormGroupProviderProps) => {
  const allFields = useRef<{ [name: string]: boolean }>({});
  const lastField = useRef<string>();
  const [allFieldsRendered, setAllFieldsRendered] = useState(false);

  const subscribeToFormGroup = (name: string) => {
    if (allFields.current[name]) return;

    allFields.current[name] = true;
    lastField.current = name;
  };

  const onRendered = (name: string) => {
    if (lastField.current !== name) {
      return;
    }

    setAllFieldsRendered(true);

    if (onAllFieldsRendered) {
      onAllFieldsRendered();
    }
  };

  return (
    <FormGroupContext.Provider value={{ subscribeToFormGroup, onRendered }}>
      {typeof children === 'function' ? children({ allFieldsRendered }) : children}
    </FormGroupContext.Provider>
  );
};
