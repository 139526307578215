import React from 'react';
import CustomReactApexcharts from '@components/CustomReactApexcharts';
import { ApexOptions } from 'apexcharts';

type AreaGraphProps = {
  options: ApexOptions;
  series: ApexAxisChartSeries;
};

const AreaGraph = ({ options, series }: AreaGraphProps) => (
  <CustomReactApexcharts options={options} series={series} type="area" height={87} width="100%" />
);

export default AreaGraph;
