import { useEffect, useState } from 'react';
import { useLazyGetHistoricalDataForExportQuery } from '@apiRtk/historicalData';
import { HistoricalDataFetchedParams } from '@appTypes/models/historicalData.dto';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Typography, styled } from '@mui/material';

import { subDays, startOfDay, endOfDay } from 'date-fns';
import { mkConfig, generateCsv, download } from 'export-to-csv';

type DateRange = {
  start: Date;
  end: Date;
};

const getDateRange = (): DateRange => {
  const endDate = endOfDay(subDays(new Date(), 1));
  const startDate = startOfDay(subDays(endDate, 30));

  return { start: startDate, end: endDate };
};

const ExportButton = styled('span')`
  display: flex;
  align-items: end;
  gap: 8px;
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
`;

export const DataExportButton = ({
  ecdId,
  deviceId,
  sourceType,
}: Pick<HistoricalDataFetchedParams, 'deviceId' | 'ecdId' | 'sourceType'>) => {
  const [fetchHistoricalData, { isLoading, data: response }] =
    useLazyGetHistoricalDataForExportQuery();
  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `${ecdId}.${sourceType}-data-export`,
  });

  const [downloadDone, setDownloadDone] = useState(false);

  useEffect(() => {
    if (response?.data && response.data.length > 0 && !downloadDone) {
      const csv = generateCsv(csvConfig)(response.data);
      download(csvConfig)(csv);
      setDownloadDone(true);
    }
  }, [response, downloadDone, csvConfig]);

  const handleDownload = () => {
    const { start, end } = getDateRange();

    fetchHistoricalData({
      ecdId,
      deviceId,
      sourceType,
      groupBy: '30m',
      startDate: start.toISOString().slice(0, -5),
      endDate: end.toISOString().slice(0, -5),
    });
    setDownloadDone(false);
  };

  return (
    <ExportButton onClick={!isLoading ? handleDownload : undefined}>
      <CloudDownloadIcon color="secondary" />
      <Typography variant="caption">{isLoading ? 'Loading...' : 'Export to CSV'}</Typography>
    </ExportButton>
  );
};
