import { usePalette, useWindowSize } from '@hooks';
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { styled, Tab, Tabs, Typography, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { palette } from 'styles/palette';

export enum TabItemId {
  OVERVIEW = 'OVERVIEW',
  DATA_ANALYSIS = 'DATA_ANALYSIS',
  ALARMS = 'ALARMS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CONFIGURATION = 'CONFIGURATION',
}

type TabItem = {
  id: TabItemId;
  label: string;
  path: string;
};

interface SiteHeaderProps {
  siteName: string;
  headerIcon: Nullable<React.ElementType>;
  tabItems: TabItem[];
}

const SiteHeader = ({ siteName, headerIcon: HeaderIcon, tabItems }: SiteHeaderProps) => {
  const { isMobile, isTablet, isSiteHeaderTablet, isSiteHeaderDesktop } = useWindowSize();
  const location = useLocation();
  const currentPalette = usePalette();

  const SiteHeaderWrapper = styled('div')`
    background-color: ${palette.white};
    padding: ${isMobile ? '0' : '0 20px'};
    border-bottom: 1px solid ${palette.neutral.main};
  `;

  const iconStyle = {
    width: '24px',
    height: '24px',
    padding: '0 0 1px 0',
  };

  const iconMap = {
    [TabItemId.OVERVIEW]: <HomeIcon style={iconStyle} />,
    [TabItemId.DATA_ANALYSIS]: <BarChartRoundedIcon style={iconStyle} />,
    [TabItemId.ALARMS]: <AlarmRoundedIcon style={iconStyle} />,
    [TabItemId.NOTIFICATIONS]: <NotificationsRoundedIcon style={iconStyle} />,
    [TabItemId.CONFIGURATION]: <SettingsRoundedIcon style={iconStyle} />,
  };

  const getIcon = (id: TabItemId) => iconMap[id];

  const getIconPadding = () => {
    if (isMobile) {
      return '0 8px 0 58px';
    }

    if (isTablet) {
      return '0 8px 0 38px';
    }

    return '0 8px 0 8px';
  };

  const getLocation = () => location.pathname.replace('/notification/rules', '/notifications');

  return (
    <SiteHeaderWrapper>
      <div>
        <Typography
          component="h1"
          variant={isMobile ? 'h6' : 'h4'}
          sx={{
            padding: '16px 0',
            display: 'flex',
            alignItems: 'center',
          }}
          data-cy="site-header-title"
        >
          {HeaderIcon && (
            <HeaderIcon
              style={{
                width: isMobile ? '28px' : '36px',
                height: isMobile ? '28px' : '36px',
                padding: getIconPadding(),
              }}
            />
          )}
          {siteName}
        </Typography>
      </div>
      {tabItems.length > 0 && (
        <div>
          <Tabs
            value={getLocation()}
            TabIndicatorProps={{
              style: {
                background: palette.primary.light,
              },
            }}
          >
            {tabItems.map((item) => (
              <Tab
                key={item.id}
                value={item.path}
                component={Link}
                to={item.path}
                data-testid={`tab-${item.id.toLowerCase()}`}
                icon={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isMobile && getIcon(item.id)}
                    {isSiteHeaderTablet && (
                      <>
                        {getIcon(item.id)}
                        {getLocation() === item.path && (
                          <Typography
                            component="span"
                            sx={{
                              marginLeft: '6px',
                              fontSize: '14px',
                              fontWeight: 'bold',
                            }}
                          >
                            {item.label.toUpperCase()}
                          </Typography>
                        )}
                      </>
                    )}

                    {isSiteHeaderDesktop && (
                      <Typography
                        component="span"
                        color={
                          getLocation() === item.path ? 'primary' : currentPalette.text.secondary
                        }
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                      >
                        {item.label.toUpperCase()}
                      </Typography>
                    )}
                  </Box>
                }
                sx={{
                  minWidth: 'auto',
                  padding: '9px 16px',
                  color: getLocation() === item.path ? palette.primary.dark : palette.neutral.dark,
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    borderBottom: `2px solid ${palette.neutral.main}`,
                  },
                }}
              />
            ))}
          </Tabs>
        </div>
      )}
    </SiteHeaderWrapper>
  );
};

export default SiteHeader;
