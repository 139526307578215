import { PropsWithChildren, useState } from 'react';
import { useGetPerformanceOverTimeQuery } from '@apiRtk/performanceOverTime';
import { useGetSiteOptimizationQuery } from '@apiRtk/sites';
import { FEATURE_FLAGS } from '@appTypes/common';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import FeatureFlagGuard from '@components/FeatureFlagGuard';
import { Flex } from '@components/LayoutUtils';
import { getPerformanceOverTimeStaticMock } from '@mocks/performanceOverTime';
import { Grid, Typography } from '@mui/material';
import CONFIG from '@settings/config';
import DataExport from '../DataExport/DataExport';
import CardBattery from './CardBattery';
import CardBenefitCalculation from './CardBenefitCalculation';
import CardConsumption from './CardConsumption';
import CardRenewables from './CardRenewables';
import { DateRangeDisplay } from './DateRangeDisplay';
import PeriodPicker, { periods } from './PeriodPicker';

const PerformanceOverTime = ({ siteId }: { siteId: number }) => {
  const [datetimeRange, setDatetimeRange] = useState({
    start: periods[0].start,
    end: periods[0].end,
  });

  const { error, isLoading, isFetching, data, refetch } = useGetPerformanceOverTimeQuery(
    {
      siteId,
      startDate: datetimeRange.start,
      endDate: datetimeRange.end,
      count: CONFIG.CARDS.GRAPHS_DATAPOINTS_COUNT,
    },
    {
      skip: !siteId,
    },
  );

  const { data: siteOptimizationData, isLoading: siteOptimizationLoading } =
    useGetSiteOptimizationQuery(siteId, {
      skip: !siteId,
    });

  const loading = isLoading || isFetching || !data;

  const useApiData = siteOptimizationData?.enabled;
  const showROIComingSoonOverlay = !useApiData;
  const staticMock = getPerformanceOverTimeStaticMock(5).returnOnInvestment;

  const dataFinal = useApiData ? data?.savings : staticMock;

  const handleChange = (start: Date, end: Date) => {
    setDatetimeRange({ start, end });
  };

  const heading = (
    <>
      <Typography variant="h5" pt={4} pb={2}>
        Performance over time
      </Typography>
      <Flex cX justifyContent="space-between" flexWrap="wrap" gap={1} pb={2}>
        <PeriodPicker onChange={handleChange} />
        <DateRangeDisplay start={datetimeRange.start} end={datetimeRange.end} />
        <DataExport />
      </Flex>
    </>
  );

  if (error) {
    return (
      <>
        {heading}
        <AlertWithRefetch severity="error" onRetryClick={refetch}>
          Performance over time data is not available for now, try again later.
        </AlertWithRefetch>
      </>
    );
  }

  return (
    <>
      {heading}
      <Grid container spacing={2}>
        <FeatureFlagGuard flagName={FEATURE_FLAGS.BENEFIT_CALCULATION}>
          {!showROIComingSoonOverlay && (
            <GridItem>
              <CardBenefitCalculation
                loading={loading || siteOptimizationLoading}
                data={dataFinal}
                comingSoon={showROIComingSoonOverlay}
              />
            </GridItem>
          )}
        </FeatureFlagGuard>
        <GridItem>
          <CardRenewables data={data?.renewables} loading={loading} />
        </GridItem>
        <GridItem>
          <CardBattery data={data?.battery} loading={loading} />
        </GridItem>
        <GridItem>
          <CardConsumption data={data?.consumption} loading={loading} />
        </GridItem>
      </Grid>
    </>
  );
};

function GridItem({ children }: PropsWithChildren) {
  return (
    <Grid item xs={12} sm={6} md={6} sx={{ minWidth: '356px' }}>
      {children}
    </Grid>
  );
}

export default PerformanceOverTime;
