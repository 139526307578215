import { DefaultParams, GetAllParams } from '@api/types';
import { DefaultFilters } from '@appTypes/helpers/redux';
import { instance } from '../instance';

export const getAll = <TItem>(
  endpoint: GetAllParams['endpoint'],
  itemsResponseName: GetAllParams['itemsResponseName'],
  params: GetAllParams['params'],
  config: GetAllParams['config'] = {},
) =>
  instance
    .get<{ [key: string]: TItem[] } & { count: number }>(endpoint, {
      ...config,
      params: (() => {
        const urlParams = new URLSearchParams();

        let paramsMapped;

        if (params.pageSize === 0 && params.page === 0) {
          paramsMapped = {
            search_string: params.search,
            order_by: params.orderBy,
            page: 1,
          };
        } else {
          paramsMapped = {
            page_size: params.pageSize,
            search_string: params.search,
            order_by: params.orderBy,
          };
        }

        const paramsToOmit = ['pageSize', 'search', 'orderBy'];

        const p: Partial<DefaultFilters> & DefaultParams = {
          ...params,
          ...paramsMapped,
        };

        Object.keys(p).forEach((param) => {
          const isTruthyParam = p[param] !== '' && p[param] !== undefined && p[param] !== null;

          if (!isTruthyParam || paramsToOmit.includes(param)) {
            return;
          }

          const paramVal = p[param] as string;

          if (param === 'order_by' && paramVal.includes('&')) {
            paramVal.split('&').forEach((orderByPhrase) => {
              urlParams.append(param, orderByPhrase);
            });

            return;
          }

          urlParams.append(param, paramVal);
        });

        return urlParams;
      })(),
    })
    .then((res) => ({
      items: res.data[itemsResponseName],
      itemsCount: res.data.count,
    }));
