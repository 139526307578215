import { useCallback, useEffect, useState } from 'react';
import CONFIG from '@config';
import { SearchSharp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  debounce,
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';

interface SearchInputProps {
  value: Nullable<string>;
  onValueChange(value: string): void;
  onValueChangeDependencies?: unknown[];
  sx?: StandardTextFieldProps['sx'];
  debounceTime?: Nullable<number>;
  size?: StandardTextFieldProps['size'];
}

export const SearchInput = ({
  value,
  onValueChange,
  size,
  sx = {},
  debounceTime = 800,
  onValueChangeDependencies = [],
}: SearchInputProps) => {
  const [noDebounceVal, setNoDebounceVal] = useState(value);

  // TODO: Fix rules of hooks here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onValueChangeDebounced = useCallback(
    debounce((currentVal: string) => {
      onValueChange(currentVal);
    }, debounceTime || 0),
    [debounceTime, ...onValueChangeDependencies],
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: currentVal } = event.target;

    setNoDebounceVal(currentVal);

    if (debounceTime === null) {
      onValueChange(currentVal);
    } else {
      onValueChangeDebounced(currentVal);
    }
  };

  useEffect(() => {
    setNoDebounceVal(value);
  }, [value]);

  return (
    <TextField
      id="search-input"
      data-testid="search-input"
      data-cy="search-input"
      label="Search"
      autoComplete="Search"
      sx={{ width: '100%', ...sx }}
      size={size}
      fullWidth
      value={noDebounceVal}
      onChange={handleChange}
      InputProps={{
        inputProps: { maxLength: CONFIG.SEARCH_TEXT_MAX_LENGTH },
        startAdornment: (
          <InputAdornment position="start">
            <SearchSharp />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              size="small"
              onClick={() => {
                onValueChange('');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
