import { PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import CardHead from '@components/Cards/CardHead';
import CardListItem from '@components/Cards/CardListItem';
import CardSummaryItem from '@components/Cards/CardSummaryItem';
import { CardContentWithLoader } from '@components/Cards/CardWithLoader';
import { CardContainerColumn } from '@components/Cards/components';
import { GapY } from '@components/LayoutUtils';
import { ReactComponent as IconPlug } from '@icons/plug.svg';
import { Box, Grid } from '@mui/material';
import { generateCardGraphColors, optionsAreaChart } from '@settings/apexOptions';
import AreaGraph from '../AreaGraph';
import { CardTooltip } from './CardTooltip';

const keyToTitleMap: Record<string, string> = {
  grid: 'Grid',
  renewables: 'Renewables',
};

interface CardConsumptionProps {
  data?: PerformanceOverTimeDto['consumption'];
  loading?: boolean;
}

const CardConsumption = ({ data, loading }: CardConsumptionProps) => {
  const colors = generateCardGraphColors('skyBlue', true, data?.items?.length);

  return (
    <CardContainerColumn>
      <CardHead
        title="Consumption mix"
        subtitle="The makeup of your energy"
        icon={<IconPlug />}
        rightSlot={<CardTooltip textKey="consumptionMix" />}
      />
      <CardContentWithLoader
        loading={loading}
        sx={{ marginBottom: -2, marginX: -2 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {data ? (
          <>
            <Grid container px={2}>
              <Grid item xs={5}>
                <CardSummaryItem value={data.total} title="Total consumption" unit="Wh" />
              </Grid>
              <GapY />
              <Grid item xs={7}>
                {data.items.map(({ key, value }, index) => (
                  <CardListItem
                    key={key}
                    light
                    color={colors[index]}
                    value={value}
                    suffix={`Wh ${keyToTitleMap[key] || key}`}
                  />
                ))}
              </Grid>
            </Grid>
            <GapY size={3} />
            {data?.items.length ? (
              <AreaGraph
                series={data.items.map((item) => ({
                  name: item.key,
                  data: item.dataPoints,
                }))}
                options={{
                  ...optionsAreaChart,
                  colors,
                }}
              />
            ) : null}
          </>
        ) : (
          <Box sx={{ height: '100px' }} />
        )}
      </CardContentWithLoader>
    </CardContainerColumn>
  );
};

export default CardConsumption;
