import { ReactNode } from 'react';
import { Grid, Typography, GridProps, styled } from '@mui/material';

const InputWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media (min-width: 850px) and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

type EcdFormWrapperProps = {
  title?: string;
  children: ReactNode;
  visible?: boolean;
  wrapperProps?: GridProps;
};

export const EcdFormWrapper = ({
  title,
  children,
  visible = true,
  wrapperProps,
}: EcdFormWrapperProps) => (
  <Grid
    gap={4}
    my={4}
    p={4}
    borderRadius={6}
    display={visible ? undefined : 'none'}
    {...wrapperProps}
  >
    {title ? <Typography variant="h6">{title}</Typography> : null}
    <InputWrapper mt={1.5} gap={2}>
      {children}
    </InputWrapper>
  </Grid>
);
