import { useCallback, useEffect, useRef } from 'react';
import { useOnUpdate } from '@hooks';
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  SelectProps,
  FormControlProps,
  CircularProgress,
  styled,
} from '@mui/material';
import { space, cssImp } from '@utils/utils';
import { palette } from 'styles/palette';

type OnChangeArgs = React.ChangeEvent<HTMLInputElement>;

export type Content = JSX.Element | string;

type DeterminedVal<TId extends string | number> = TId extends number ? Nullable<number> : string;

export type CustomSelectBoxProps<TId extends string | number = string> = {
  value: DeterminedVal<TId>;
  name?: string;
  onValueChange?: (value: DeterminedVal<TId>) => void;
  onChange?: (event: OnChangeArgs) => void;
  options: { id: TId; name: Content }[];
  disableEmptyValue?: boolean;
  preventEmptyDisabledAutoUpdate?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  emptyValueContent?: Content;
  customItemRender?: (content: Content, id: TId) => Content;
  formControlProps?: FormControlProps;
  onScrolledBottom?: () => void;
  ['data-testid']?: string;
} & SelectProps;

const StyledFormControl = styled(FormControl)`
  & fieldset {
    border-radius: 12px;
  }
`;

export function SelectBox<TId extends string | number = string>({
  value,
  name,
  onValueChange,
  onChange,
  options,
  disableEmptyValue,
  preventEmptyDisabledAutoUpdate,
  emptyValueContent,
  loading,
  readOnly = false,
  customItemRender,
  onScrolledBottom,
  formControlProps,
  color = 'primary',
  ...selectProps
}: CustomSelectBoxProps<TId>) {
  const firstOptionNonNullIdSet = useRef(false);

  const firstOptionId = options[0]?.id;

  const handleChange = useCallback(
    (event: OnChangeArgs) => {
      const targetVal = event.target.value;
      const isCurrentValueNumberType = typeof value === 'number' || value === null;
      const isValueNumberType = options[0] ? typeof firstOptionId : isCurrentValueNumberType;

      const isNullable = isValueNumberType && targetVal === '';

      const handlerEvent = {
        ...event,
        target: { ...event.target, value: isNullable ? null : targetVal },
      } as OnChangeArgs;

      if (onChange) {
        onChange(handlerEvent);
      }

      if (onValueChange) {
        onValueChange(handlerEvent.target.value as DeterminedVal<TId>);
      }
    },
    [firstOptionId, onChange, onValueChange, options, value],
  );

  const setFirstOptionIdAsValue = useCallback(() => {
    handleChange({
      target: { value: `${firstOptionId}`, name },
    } as OnChangeArgs);
  }, [firstOptionId, handleChange, name]);

  // on options update set first option as value
  useOnUpdate(() => {
    if (firstOptionId === undefined) {
      return;
    }

    if (!firstOptionNonNullIdSet.current) {
      firstOptionNonNullIdSet.current = true;
      return;
    }

    setFirstOptionIdAsValue();
  }, [firstOptionId]);

  useEffect(() => {
    if (
      !disableEmptyValue ||
      firstOptionId === undefined ||
      value ||
      preventEmptyDisabledAutoUpdate
    ) {
      return;
    }

    setFirstOptionIdAsValue();
  }, [
    disableEmptyValue,
    firstOptionId,
    preventEmptyDisabledAutoUpdate,
    setFirstOptionIdAsValue,
    value,
  ]);

  const renderMenuItem = (id: TId | '', content: Content) => (
    <MenuItem key={id} value={id}>
      {customItemRender ? (
        customItemRender(content, id as TId)
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: palette.primary.main,
          }}
        >
          {content}
        </Box>
      )}
    </MenuItem>
  );

  const renderSelectVal = !loading
    ? undefined
    : () => (
        <Box sx={{ transform: `translateY(${space(0.5)})` }}>
          <CircularProgress
            color="primary"
            sx={{
              height: cssImp(space(2)),
              width: cssImp(space(2)),
            }}
          />
        </Box>
      );

  return (
    <StyledFormControl {...(formControlProps || {})}>
      <Select
        {...selectProps}
        renderValue={renderSelectVal}
        color={color}
        value={value === null || firstOptionId === undefined ? '' : value}
        name={name}
        onChange={(e) => handleChange(e as unknown as OnChangeArgs)}
        MenuProps={{
          style: { maxHeight: 600 },
          onScrollCapture: ({ target }) => {
            const { scrollTop, scrollHeight, offsetHeight } = target as HTMLDivElement;

            if (scrollTop === scrollHeight - offsetHeight && onScrolledBottom) {
              onScrolledBottom();
            }
          },
          PopoverClasses: { paper: 'appScrollBar' },
        }}
        disabled={selectProps.disabled || readOnly}
        displayEmpty
        variant="outlined"
      >
        {!disableEmptyValue && renderMenuItem('', emptyValueContent || '')}
        {options.map(({ id, name: optName }) => renderMenuItem(id, optName))}
      </Select>
    </StyledFormControl>
  );
}
