import { BasicFormField } from '@components/BasicForm';
import { OrganizationsSelect } from '@components/OrganizationsSelect/OrganizationsSelect';
import { RolesSelect } from '@components/RolesSelect';

export const getUserFormFields = (isCreateMode: boolean, isUserRole: boolean): BasicFormField[] => {
  const isEditedByUser = !isCreateMode && isUserRole;
  return [
    {
      name: 'first_name',
      label: 'First Name',
    },
    {
      name: 'last_name',
      label: 'Last Name',
    },
    {
      name: 'email',
      label: 'E-mail',
      readOnly: !isCreateMode,
    },
    {
      name: 'phone',
      label: 'Phone',
      inputProps: { maxLength: 15 },
    },
    {
      name: 'role_name',
      label: 'Role',
      readOnly: isEditedByUser,
      CustomComponent: RolesSelect,
    },
    {
      name: 'organization_id',
      label: 'Organization',
      required: false,
      readOnly: isEditedByUser,
      CustomComponent: ({ ...props }) => (
        // @ts-ignore
        <OrganizationsSelect
          label={undefined}
          {...props}
          disableEmptyValue={false}
          emptyValueContent="-"
          formControlProps={{ sx: { width: '100%' } }}
        />
      ),
    },
    ...(isCreateMode
      ? []
      : [
          {
            name: 'id',
            label: 'Id',
            readOnly: true,
          },
          {
            name: 'creation_date',
            label: 'Creation Date',
            readOnly: true,
            isDate: true,
          },
        ]),
  ];
};
