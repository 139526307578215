import React, { ReactNode } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  renderWrapper?: (children: ReactNode) => JSX.Element;
  Wrapper?: React.ComponentType<any>;
  children: ReactNode;
}

const ConditionalWrapper = ({
  condition,
  renderWrapper,
  Wrapper,
  children,
}: ConditionalWrapperProps) => {
  if (condition) {
    if (renderWrapper) {
      return renderWrapper(children);
    }
    if (Wrapper) {
      return <Wrapper>{children}</Wrapper>;
    }
  }
  return children as JSX.Element;
};

export default ConditionalWrapper;
