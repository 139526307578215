import { QueryParams, createQueryParamsStr } from '@utils/routing/createQueryParamsStr';
import { paths, PathsType } from 'paths';
import { NavigateOptions, useNavigate } from 'react-router';

export const useRedirect = () => {
  const navigate = useNavigate();

  const redirect = (
    pathCb: (pathsParam: PathsType) => string,
    options?: {
      query?: QueryParams;
    } & NavigateOptions,
  ) => {
    const { state, replace, query = {} } = options || {};

    const navigatePath = `${pathCb(paths)}${createQueryParamsStr(query)}`;

    navigate(navigatePath, { state, replace });

    return null;
  };

  return redirect;
};
