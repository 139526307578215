import { useCallback, useEffect, useMemo, useState } from 'react';
import { GraphDto } from '@apiRtk/analysis';
import { getChartColors } from '@components/Chart/utils/helpers';
import { CenterXY } from '@components/LayoutUtils';
import { GraphLoader } from '@components/Spinner';
import { Alert, Typography } from '@mui/material';
import { dictionary } from '@settings/dictionary';
import ReactECharts from 'echarts-for-react';
import useLocalStorageState from 'use-local-storage-state';
import ChartLegend, { SeriesItem } from './ChartLegend';
import { createDynamicOptions, DynamicOptions, getStaticOptions } from './options';
import useChartPostRenderEffect from './useChartPostRenderEffect';

const legendRowHeight = 21;

type EChartsProps = {
  groupRef: React.LegacyRef<ReactECharts>;
  refs: React.MutableRefObject<Nullable<ReactECharts>[]>;
  height: number;
  deviceId: string;
  loading: boolean;
  graphData: GraphDto | undefined;
};

const ECharts = ({
  groupRef,
  loading,
  refs,
  deviceId,
  graphData,
  height,
  ...props
}: EChartsProps) => {
  const [hiddenSeries, setHiddenSeries] = useLocalStorageState<string[]>(
    `${deviceId}.hiddenSeries`,
    {
      defaultValue: [],
      storageSync: true,
    },
  );
  const [dynamicOptions, setDynamicOptions] = useState<Nullable<DynamicOptions>>(null);
  const [isPreparingData, setIsPreparingData] = useState(true);

  const memoizedCreateDynamicOptions = useCallback(() => {
    if (graphData) {
      return createDynamicOptions(graphData, hiddenSeries);
    }
    return null;
  }, [graphData, hiddenSeries]);

  useEffect(() => {
    setIsPreparingData(true);
    const options = memoizedCreateDynamicOptions();
    setDynamicOptions(options);
    setIsPreparingData(false);
  }, [memoizedCreateDynamicOptions]);

  useChartPostRenderEffect({
    refs,
    dependencies: [dynamicOptions],
    ready: Boolean(dynamicOptions),
  });

  const options = useMemo(
    () => ({
      ...getStaticOptions(),
      ...dynamicOptions,
    }),
    [dynamicOptions],
  );

  const allSeries = useMemo(
    () =>
      graphData?.series.map<SeriesItem>((s, index) => ({
        name: s.uniqueName || 'default-series',
        label: s.label || 'default series',
        color: getChartColors()[index],
        yAxisIndex: s.yaxisIndex || 0,
      })) || [],
    [graphData],
  );

  const hasValidOptions =
    dynamicOptions && allSeries.length > 0 && dynamicOptions?.yAxis?.length > 0;

  if (isPreparingData) {
    return (
      <CenterXY gap={2}>
        <Typography variant="subtitle2">{dictionary.preparingGraphData}</Typography>
        <GraphLoader noAnimation />
      </CenterXY>
    );
  }

  return hasValidOptions ? (
    <>
      <ReactECharts
        ref={groupRef}
        option={options}
        notMerge
        {...props}
        style={{ height: `${height - legendRowHeight}px` }}
      />
      <ChartLegend
        series={allSeries}
        emptySeries={graphData?.emptySeries || []}
        hiddenSeries={hiddenSeries}
        setHiddenSeries={setHiddenSeries}
      />
    </>
  ) : (
    <Alert severity="info">{dictionary.errorNoData}</Alert>
  );
};

export default ECharts;
