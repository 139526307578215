import { sitesAPI } from '@api/sites';
import { SiteDto, SiteSimpleDto, CreateSiteDto, UpdateSiteDto } from '@appTypes/models/site.dto';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { fork } from 'redux-saga/effects';
import {
  requestSites,
  requestSitesSuccess,
  requestSitesFailed,
  setSiteRequestLoading,
  setSiteRequestError,
  getSiteSuccess,
} from './actionCreators';
import { SitesActionsTypes } from './actionTypes';
import { getSitesSelector, SitesSelectorReturn } from './selectors';

export function* sitesWatcher() {
  yield fork<
    CreateSagaCrudWatcher<SitesSelectorReturn, SiteDto, CreateSiteDto, UpdateSiteDto, SiteSimpleDto>
  >(createSagaCRUDWatcher, {
    selector: getSitesSelector,
    setRequestLoading: setSiteRequestLoading,
    setRequestError: setSiteRequestError,
    getList: {
      actionName: SitesActionsTypes.FETCHED_SITES,
      updateFilterActionName: SitesActionsTypes.UPDATE_SITES_FILTER,
      getCountActionName: SitesActionsTypes.FETCHED_SITES_COUNT,
      apiCall: sitesAPI.getSites,
      request: requestSites,
      requestSuccess: requestSitesSuccess,
      requestFailed: requestSitesFailed,
    },
    getSingle: {
      actionName: SitesActionsTypes.FETCHED_GET_SITE,
      clearCurrentActionName: SitesActionsTypes.CLEAR_SITE_CURRENT,
      apiCall: sitesAPI.getSite,
      onSuccess: getSiteSuccess,
    },
  });
}
