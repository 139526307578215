import { convertUtcStringToLocalString } from '@utils/utils';
import isValid from 'date-fns/isValid';
import lightFormat from 'date-fns/lightFormat';

export const formatDate = (inputDate: string) => {
  if (!isValid(new Date(inputDate))) {
    return inputDate;
  }

  const dateLocal = new Date(convertUtcStringToLocalString(inputDate));

  return lightFormat(dateLocal, 'yyyy-MM-dd HH:mm:ss');
};
