import SiteHeader, { TabItemId } from '@components/SiteHeader/SiteHeader';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Box } from '@mui/material';

interface SiteHeaderWrapperProps {
  siteName: string;
  siteId: number;
  children: React.ReactNode;
}

export const SiteHeaderWrapper = ({ siteName, siteId, children }: SiteHeaderWrapperProps) => {
  const getTabItems = (id: number) => {
    if (!id) {
      return [];
    }

    return [
      {
        id: TabItemId.OVERVIEW,
        label: 'Overview',
        path: `/sites/${id}/overview`,
      },
      {
        id: TabItemId.DATA_ANALYSIS,
        label: 'Data Analysis',
        path: `/sites/${id}/data-analysis`,
      },
      {
        id: TabItemId.ALARMS,
        label: 'Alarms',
        path: `/sites/${id}/alarms`,
      },
      {
        id: TabItemId.NOTIFICATIONS,
        label: 'Notifications',
        path: `/sites/${id}/notifications`,
      },
      {
        id: TabItemId.CONFIGURATION,
        label: 'Configuration',
        path: `/sites/${id}/configuration`,
      },
    ];
  };

  return (
    <div>
      <SiteHeader
        siteName={siteName}
        headerIcon={BusinessRoundedIcon}
        tabItems={getTabItems(siteId)}
      />
      <Box px={6} py={4} flexGrow={1} overflow="hidden">
        {children}
      </Box>
    </div>
  );
};
