export const wattstorSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    width="100%"
    height="100%"
    viewBox="0 0 49 24.06"
  >
    <defs>
      <linearGradient
        id="linear-gradient-header-logo"
        x1="6.33"
        y1="17.81"
        x2="36.49"
        y2="-.85"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff387b" />
        <stop offset=".13" stopColor="#ff4476" />
        <stop offset=".38" stopColor="#ff656b" />
        <stop offset=".71" stopColor="#ff9a57" />
        <stop offset="1" stopColor="#ffcf44" />
      </linearGradient>
    </defs>
    <path
      d="M14.53 24a3.11 3.11 0 01-2.63-1.4L.49 4.82A3.13 3.13 0 013.13 0h13.72a3.13 3.13 0 012.63 1.44l9.26 14.45L38 6.26h-9A3.13 3.13 0 1129 0h16.35a3.13 3.13 0 012.25 5.3L30.5 23.08a3.1 3.1 0 01-2.56 1 3.16 3.16 0 01-2.33-1.43L15.13 6.26H8.85l8.31 13A3.12 3.12 0 0114.53 24z"
      fill="url(#linear-gradient-header-logo)"
    />
  </svg>
);

export const icons = {
  solar: (
    <svg
      focusable="false"
      width="38"
      aria-hidden="true"
      viewBox="0 0 24 24"
      stroke="#666"
      strokeWidth="1"
      fill="transparent"
    >
      <path d="M3.33 16H11v-3H4zM13 16h7.67L20 13h-7zm8.11 2H13v4h9zM2 22h9v-4H2.89zm9-14h2v3h-2zm4.7644-.7948 1.4143-1.4142L19.3 7.9123l-1.4142 1.4142zm-11.0596.7076 2.1213-2.1213 1.4143 1.4142L6.119 9.327zM3 2h3v2H3zm15 0h3v2h-3zm-6 5c2.76 0 5-2.24 5-5H7c0 2.76 2.24 5 5 5z" />
    </svg>
  ),
  battery: (
    <svg width="38px" height="38px" viewBox="0 0 35 32" enableBackground="new 0 0 35 32">
      <g>
        <path
          fill="#333"
          d="M1.5,25H11c0.276,0,0.5-0.224,0.5-0.5S11.276,24,11,24H1.5C1.225,24,1,23.776,1,23.5v-15
		C1,8.224,1.225,8,1.5,8H11c0.276,0,0.5-0.224,0.5-0.5S11.276,7,11,7H1.5C0.673,7,0,7.673,0,8.5v15C0,24.327,0.673,25,1.5,25z"
        />
        <path
          fill="#333"
          d="M31,12V8.5C31,7.673,30.327,7,29.5,7H20c-0.276,0-0.5,0.224-0.5,0.5S19.724,8,20,8h9.5
		C29.775,8,30,8.224,30,8.5v15c0,0.276-0.225,0.5-0.5,0.5H19c-0.276,0-0.5,0.224-0.5,0.5S18.724,25,19,25h10.5
		c0.827,0,1.5-0.673,1.5-1.5V21h2.5c0.827,0,1.5-0.673,1.5-1.5v-6c0-0.827-0.673-1.5-1.5-1.5H31z M34,13.5v6
		c0,0.276-0.225,0.5-0.5,0.5H31v-7h2.5C33.775,13,34,13.224,34,13.5z"
        />
        <path
          fill="#333"
          d="M12.909,31.733c0.06,0.023,0.122,0.035,0.183,0.035c0.176,0,0.344-0.093,0.435-0.253l10.65-18.768
		c0.088-0.155,0.087-0.344-0.003-0.498C24.085,12.095,23.92,12,23.742,12h-7.076l3.062-11.605c0.062-0.236-0.056-0.483-0.278-0.583
		c-0.221-0.099-0.484-0.025-0.621,0.178L7.326,17.222c-0.103,0.154-0.112,0.351-0.024,0.514C7.389,17.898,7.558,18,7.742,18h7.947
		l-3.084,13.153C12.548,31.396,12.678,31.643,12.909,31.733z M16.807,17.614c0.035-0.149,0-0.305-0.095-0.425S16.473,17,16.32,17
		H8.677l9.32-13.961l-2.464,9.334c-0.04,0.15-0.008,0.31,0.087,0.433S15.861,13,16.017,13h6.867l-8.512,14.999L16.807,17.614z"
        />
      </g>
    </svg>
  ),
  batteryCharging: (
    <svg width="38px" height="38px" viewBox="0 0 35 32" enableBackground="new 0 0 35 32">
      <g>
        <path
          fill="#333"
          d="M1.5,25H11c0.276,0,0.5-0.224,0.5-0.5S11.276,24,11,24H1.5C1.225,24,1,23.776,1,23.5v-15
		C1,8.224,1.225,8,1.5,8H11c0.276,0,0.5-0.224,0.5-0.5S11.276,7,11,7H1.5C0.673,7,0,7.673,0,8.5v15C0,24.327,0.673,25,1.5,25z"
        />
        <path
          fill="#333"
          d="M31,12V8.5C31,7.673,30.327,7,29.5,7H20c-0.276,0-0.5,0.224-0.5,0.5S19.724,8,20,8h9.5
		C29.775,8,30,8.224,30,8.5v15c0,0.276-0.225,0.5-0.5,0.5H19c-0.276,0-0.5,0.224-0.5,0.5S18.724,25,19,25h10.5
		c0.827,0,1.5-0.673,1.5-1.5V21h2.5c0.827,0,1.5-0.673,1.5-1.5v-6c0-0.827-0.673-1.5-1.5-1.5H31z M34,13.5v6
		c0,0.276-0.225,0.5-0.5,0.5H31v-7h2.5C33.775,13,34,13.224,34,13.5z"
        />
        <path
          fill="#fc0"
          stroke="#fc0"
          d="M12.909,31.733c0.06,0.023,0.122,0.035,0.183,0.035c0.176,0,0.344-0.093,0.435-0.253l10.65-18.768
		c0.088-0.155,0.087-0.344-0.003-0.498C24.085,12.095,23.92,12,23.742,12h-7.076l3.062-11.605c0.062-0.236-0.056-0.483-0.278-0.583
		c-0.221-0.099-0.484-0.025-0.621,0.178L7.326,17.222c-0.103,0.154-0.112,0.351-0.024,0.514C7.389,17.898,7.558,18,7.742,18h7.947
		l-3.084,13.153C12.548,31.396,12.678,31.643,12.909,31.733z M16.807,17.614c0.035-0.149,0-0.305-0.095-0.425S16.473,17,16.32,17
		H8.677l9.32-13.961l-2.464,9.334c-0.04,0.15-0.008,0.31,0.087,0.433S15.861,13,16.017,13h6.867l-8.512,14.999L16.807,17.614z"
        />
      </g>
    </svg>
  ),
  ev: (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
    >
      <path
        d="M372.7 707.8c-17.5-53.6-42.1-80.9-78-108.9-10.6-8.3-21.7-15.6-36-24.2-0.3-0.2-27.8-16.2-35.1-20.8-22.1-14-35.9-26.7-44.5-44.3l-2.9-5.8V343.2c0-46.6 37.5-84.5 84-84.5h84.5V90.2c0-15.5 12.5-28 28.1-28 15.5 0 28.1 12.5 28.1 28v168.5h224.5V90.2c0-15.5 12.5-28 28.1-28 15.5 0 28.1 12.5 28.1 28v168.5h84.5c46.5 0 84 37.7 84 84.5v160.6l-2.9 5.8c-8 16.4-20.8 28.9-40.5 42.5 4.6-3.2-57.9 36.3-75.8 50.7-35.4 28.7-59.7 54.6-77.4 105v56.1s0 28.1-56.1 28.1v168.4H428.8l-0.1-168.4c-56.1 0-56.1-28.1-56.1-28.1v-56.1h0.1z"
        fill="#808184"
      />
      <path
        d="M484.9 792H541v112.3h-56.1zM428.8 735.9h168.4V703l1.6-4.5c21.3-60.5 53.3-104.3 96.6-139.3 13.3-10.8 26.7-20.1 44-31 4.5-2.8 19.2-11.9 19.2-11.9 6.9-4.3 11.7-7.4 16-10.4 12.4-8.6 16.2-16.7 19.1-22.6V343.2c0-15.9-12.4-28.3-27.8-28.3H260.1c-15.3 0-27.8 12.6-27.8 28.3v140.1c3 6.1 7.2 14.3 21.3 23.2 6.3 4 32.9 19.5 33.9 20.1 16.1 9.6 29 18.2 41.7 28.1 44.6 34.8 77.3 80 98.3 144.5l1.4 4.2v32.5h-0.1z"
        fill=""
      />
      <path
        d="M428.8 399.1h168.4v28.1H428.8zM428.8 511.3h168.4v28.1H428.8zM400.7 455.2h224.5v28.1H400.7z"
        fill="#808184"
      />
    </svg>
  ),
  grid: (
    <svg
      height="38px"
      width="38px"
      viewBox="0 0 59.831 59.831"
      fill="#555
   "
    >
      <g>
        <path
          d="M50.651,10.354c0.12-0.86-0.858-1.239-1.187-1.364L34.677,3.347
		c-0.749-0.285-1.852-1.082-2.356-1.705l-0.632-0.778C31.235,0.307,30.623,0,29.967,0c-0.688,0-1.335,0.348-1.778,0.95l-0.336,0.457
		c-0.468,0.637-1.523,1.444-2.261,1.728L10.366,8.986C10.04,9.113,9.065,9.493,9.182,10.35c-0.001,0.025-0.015,0.046-0.015,0.072
		v5.692c0,0.481,0.389,0.869,0.87,0.869s0.869-0.389,0.869-0.869v-4.823h13.485l-8.412,47.52c-0.084,0.473,0.232,0.924,0.706,1.006
		c0.47,0.087,0.922-0.231,1.006-0.703l2.003-11.312h20.537l2.265,11.331c0.084,0.412,0.446,0.698,0.852,0.698
		c0.057,0,0.114-0.005,0.171-0.018c0.471-0.094,0.777-0.551,0.684-1.021l-9.489-47.501h14.211v4.823c0,0.481,0.39,0.869,0.869,0.869
		c0.482,0,0.871-0.389,0.871-0.869v-5.692C50.666,10.397,50.653,10.377,50.651,10.354z M23.59,25.801h4.238l-5.432,6.736
		L23.59,25.801z M22.769,34.847l6.891-8.548l7.181,8.548H22.769z M34.925,36.585l-5.21,3.049l-5.437-3.049H34.925z M21.593,37.073
		l6.383,3.578l-7.825,4.578L21.593,37.073z M22.174,46.061l7.559-4.424l7.888,4.424H22.174z M39.723,45.247l-8.251-4.627
		l6.561-3.839L39.723,45.247z M37.19,32.56l-5.679-6.758h4.328L37.19,32.56z M35.492,24.062H23.897l2.144-12.112h7.032
		L35.492,24.062z M13.74,9.553l12.475-4.796c1.043-0.4,2.379-1.421,3.04-2.321L29.59,1.98c0.225-0.308,0.512-0.309,0.75-0.018
		l0.632,0.777c0.698,0.859,2.055,1.84,3.086,2.233l12.006,4.581H13.74z"
        />
      </g>
    </svg>
  ),
  consumption: (
    <svg height="38px" width="38px" viewBox="0 0 512 512" fill="#555">
      <g>
        <g>
          <g>
            <path
              d="M512,306.387v-3.32c0-11.906-9.687-21.593-21.593-21.593h-7.84l-6.738-142.151c-0.211-4.458-3.94-7.907-8.458-7.692
				c-4.459,0.21-7.903,3.998-7.692,8.458l6.701,141.384h-66.044l9.448-199.34h47.148l1.402,29.583
				c0.211,4.459,3.994,7.929,8.458,7.692c4.459-0.21,7.903-3.998,7.692-8.458l-1.728-36.46c0.015-0.275,0.016-0.552,0.003-0.829
				l-1.457-30.338c-0.008-0.158-0.029-0.313-0.045-0.469l-1.266-26.685h2.193c4.466,0,8.084-3.619,8.084-8.084S476.652,0,472.186,0
				h-10.669h-56.316h-10.669c-4.466,0-8.084,3.619-8.084,8.084s3.619,8.084,8.084,8.084h2.194l-1.264,26.688
				c-0.016,0.155-0.038,0.309-0.045,0.467l-1.457,30.338c-0.013,0.277-0.012,0.554,0.003,0.829l-9.811,206.983h-10.64L363.7,74.489
				c0.015-0.275,0.016-0.551,0.003-0.828l-1.457-30.338c-0.008-0.158-0.029-0.312-0.045-0.468l-1.266-26.686h2.194
				c4.466,0,8.084-3.619,8.084-8.084S367.594,0,363.129,0H352.46h-56.316h-10.669c-4.466,0-8.084,3.619-8.084,8.084
				s3.618,8.084,8.084,8.084h2.194l-1.265,26.688c-0.016,0.155-0.038,0.309-0.045,0.467l-1.457,30.338
				c-0.013,0.277-0.012,0.554,0.003,0.829l-9.81,206.983h-12.189v-18.032c0-2.974-1.633-5.707-4.251-7.118
				c-2.618-1.411-5.798-1.27-8.283,0.369l-64.86,42.758V263.44c0-2.974-1.633-5.707-4.251-7.118
				c-2.618-1.411-5.798-1.27-8.283,0.369l-64.86,42.758v-36.009c0-2.974-1.633-5.707-4.251-7.118
				c-2.618-1.411-5.798-1.27-8.283,0.369L18.192,307.71c-2.269,1.496-3.635,4.031-3.635,6.75v152.231
				C6.099,469.621,0,477.646,0,487.087v3.32C0,502.313,9.687,512,21.593,512h468.813c11.906,0,21.593-9.687,21.593-21.593v-3.32
				c0-9.835-6.616-18.138-15.625-20.737V327.124C505.384,324.525,512,316.222,512,306.387z M412.91,16.168h40.895l0.923,19.458
				h-42.74L412.91,16.168z M411.222,51.795h44.272l0.672,14.17h-45.615L411.222,51.795z M303.854,16.168h40.894l0.923,19.458h-42.74
				L303.854,16.168z M302.165,51.795h44.272l0.672,14.17h-45.615L302.165,51.795z M300.727,82.133h47.148l9.448,199.34h-66.044
				L300.727,82.133z M262.903,327.981h138.614c4.466,0,8.084-3.618,8.084-8.084c0-4.466-3.619-8.084-8.084-8.084H262.903v-14.17
				h19.899H365.8h26.059h82.998h15.55c2.991,0,5.425,2.434,5.425,5.425v3.32c0,2.991-2.434,5.425-5.425,5.425h-2.116h-59.109
				c-4.466,0-8.084,3.619-8.084,8.084s3.619,8.084,8.084,8.084h51.024v137.511H262.903V327.981z M185.509,318.816l61.226-40.362
				v11.103v30.338v145.596h-61.226V318.816z M108.116,318.816l61.226-40.362v36.008v151.029h-61.226V318.816z M30.724,318.816
				l61.223-40.362v36.008v151.029H30.724V318.816z M495.833,490.407h-0.001c0,2.991-2.434,5.425-5.425,5.425H21.593
				c-2.991,0-5.425-2.434-5.425-5.425v-3.32c0-2.991,2.434-5.425,5.425-5.425h1.047h77.392h77.394h77.394h233.472h2.116
				c2.991,0,5.425,2.434,5.425,5.425V490.407z"
            />
            <path
              d="M457.925,342.538h-57.297h-57.297h-57.297c-4.466,0-8.084,3.618-8.084,8.084v46.115v46.115
				c0,4.466,3.619,8.084,8.084,8.084h57.297h57.297h57.297c4.466,0,8.084-3.618,8.084-8.084v-46.115v-46.115
				C466.009,346.156,462.391,342.538,457.925,342.538z M335.248,434.767H294.12v-29.946h41.128V434.767z M335.248,388.652H294.12
				v-29.946h41.128V388.652z M392.544,434.767h-41.128v-29.946h41.128V434.767z M392.546,388.652h-41.128v-29.946h41.128V388.652z
				 M449.842,434.768h-41.128v-29.946h41.128V434.768z M449.842,388.652h-41.128v-29.946h41.128V388.652z"
            />
            <path
              d="M228.697,342.538h-25.152c-4.466,0-8.084,3.618-8.084,8.084c0,4.466,3.618,8.084,8.084,8.084h25.152
				c4.466,0,8.084-3.618,8.084-8.084C236.781,346.156,233.163,342.538,228.697,342.538z"
            />
            <path
              d="M228.697,368.829h-25.152c-4.466,0-8.084,3.618-8.084,8.084c0,4.465,3.618,8.084,8.084,8.084h25.152
				c4.466,0,8.084-3.618,8.084-8.084C236.781,372.447,233.163,368.829,228.697,368.829z"
            />
            <path
              d="M151.303,342.538h-25.149c-4.466,0-8.084,3.618-8.084,8.084c0,4.466,3.62,8.084,8.084,8.084h25.149
				c4.466,0,8.084-3.618,8.084-8.084C159.387,346.156,155.769,342.538,151.303,342.538z"
            />
            <path
              d="M151.303,368.829h-25.149c-4.466,0-8.084,3.618-8.084,8.084c0,4.465,3.62,8.084,8.084,8.084h25.149
				c4.466,0,8.084-3.618,8.084-8.084C159.387,372.447,155.769,368.829,151.303,368.829z"
            />
            <path
              d="M73.911,342.538H48.76c-4.466,0-8.084,3.618-8.084,8.084c0,4.466,3.618,8.084,8.084,8.084h25.152
				c4.466,0,8.084-3.618,8.084-8.084C81.995,346.156,78.377,342.538,73.911,342.538z"
            />
            <path
              d="M73.911,368.829H48.76c-4.466,0-8.084,3.618-8.084,8.084c0,4.466,3.618,8.084,8.084,8.084h25.152
				c4.466,0,8.084-3.618,8.084-8.084S78.377,368.829,73.911,368.829z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  arrowChevron: (
    <g>
      <path d="M12 17a1 1 0 0 1-.707-1.707L14.586 12l-3.293-3.293a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4A1 1 0 0 1 12 17z" />
      <path d="M8 17a1 1 0 0 1-.707-1.707L10.586 12 7.293 8.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4A1 1 0 0 1 8 17z" />
    </g>
  ),
};
