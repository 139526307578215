import { Modal, ModalProps } from '@mui/material';

export type CommonModalProps = {
  open: boolean;
  onClose(): void;
} & Omit<ModalProps, 'open' | 'onClose'>;

export const CommonModal = ({ open, onClose, ...props }: CommonModalProps) => (
  <Modal
    disableAutoFocus
    sx={{
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
    }}
    {...props}
    open={open}
    onClose={onClose}
  />
);
