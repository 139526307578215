import { UserSimpleDto } from '@appTypes/models/user.dto';
import { AppStateType } from '@appTypes/redux';
import { createCurrentPageSelector } from '@redux/common/createCurrentPageSelector';

export const getUsersSelector = createCurrentPageSelector<
  AppStateType['users'],
  'users',
  UsersSelectorReturn
>('users', 'currentPageUsers');

export type UsersSelectorReturn = AppStateType['users'] & {
  currentPageUsers: WPage<UserSimpleDto>[];
};
