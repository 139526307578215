import { SiteDto, SiteSimpleDto } from '@appTypes/models/site.dto';
import { SiteFilters } from '@appTypes/sites/filters';
import { apiCore } from './apiCore/apiCore';

const endpoint = 'sites';
const epHandler = apiCore.makeEndpointHandler<SiteDto, SiteSimpleDto>(endpoint);

export const sitesAPI = {
  getSites(params: SiteFilters) {
    return epHandler.getAll('sites', params);
  },
  getSite(id: SiteDto['id']) {
    return epHandler.getSingle(id);
  },
};
