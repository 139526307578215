import { BoxModal } from '../Modals/BoxModal';
import { ConfigurationList, ConfigurationListProps } from './ConfigurationList';

type ConfigurationListModalProps = {
  open: boolean;
  onClose(): void;
} & Pick<ConfigurationListProps, 'visibleTabs' | 'initSelectedItem'>;

export const ConfigurationListModal = ({
  open,
  onClose,
  ...configurationListProps
}: ConfigurationListModalProps) => (
  <BoxModal open={open} onClose={onClose}>
    <ConfigurationList {...configurationListProps} />
  </BoxModal>
);
