import { instance } from '@api/instance';
import { ApiErrorPayload } from '@appTypes/api/error';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';

export const rtkApiTags = [
  'EcdHealth',
  'Ecds',
  'GraphCustomSettings',
  'Headers',
  'NotificationRules',
  'PerformanceOverTime',
  'Site',
  'SiteOptimization',
  'Sites',
  'SitesAll',
];

const getRequestConfig = (args: string | AxiosRequestConfig) => {
  if (typeof args === 'string') {
    return { url: args };
  }

  return args;
};

type BaseQueryArgs =
  | {
      url: string;
      method: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    }
  | string;

type AxiosBaseQueryFn = BaseQueryFn<BaseQueryArgs, unknown, unknown>;

const axiosBaseQuery: AxiosBaseQueryFn = async (args) => {
  const requestConfig = getRequestConfig(args);

  try {
    const result = await instance({
      ...requestConfig,
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    const apiError: ApiErrorPayload = {
      // TODO: Fix Error type inheritance, diff between general Axios error and FastAPI error
      status: err.response?.status as any,
      data: err.response?.data as any,
    };

    return {
      error: apiError,
    };
  }
};

export const extraParams = {
  drop_calculator: true,
};

export const coreApi = createApi({
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),

  tagTypes: rtkApiTags,
});
