import { useState, useEffect } from 'react';
import DateRangePickerPopover from '@components/DateRangePickerPopover/DateRangePickerPopover';
import { hotbarPresets } from '@components/DateRangePickerPopover/rangePickerPresets';
import { CenterX } from '@components/LayoutUtils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Chip } from '@mui/material';
import { format, differenceInMinutes } from 'date-fns';
import { useDateTimeRangeContext } from './DateTimeRangeContext';
import ZoomButtons from './ZoomButtons';

interface ChartDateTimeRangePickerProps {
  onChange?: (start: Date, end: Date) => void;
}

const DateRangePanel = ({ onChange }: ChartDateTimeRangePickerProps) => {
  const { dateTimeRange, setDateTimeRange } = useDateTimeRangeContext();

  const [selectedPreset, setSelectedPreset] = useState<string>();

  useEffect(() => {
    const checkIfPresetSelected = (startDateParam: Date, endDateParam: Date) => {
      const matchingPreset = hotbarPresets.find((presetItem) => {
        const { startDate, endDate } = presetItem.range();
        const startDifference = Math.abs(differenceInMinutes(startDate, startDateParam));
        const endDifference = Math.abs(differenceInMinutes(endDate, endDateParam));
        return startDifference === 0 && endDifference === 0;
      });
      if (matchingPreset) {
        setSelectedPreset(matchingPreset.label);
      } else {
        setSelectedPreset('Custom');
      }
    };
    checkIfPresetSelected(dateTimeRange.start, dateTimeRange.end);
  }, [dateTimeRange]);

  const handlePresetClick = (
    presetLabel: string,
    getRange: () => { startDate: Date; endDate: Date },
  ) => {
    const { startDate, endDate } = getRange();
    setDateTimeRange({ start: startDate, end: endDate });
    if (onChange) {
      onChange(startDate, endDate);
    }
    setSelectedPreset(presetLabel);
  };

  const handleZoom = (newStartDate: Nullable<Date>, newEndDate: Nullable<Date>) => {
    if (newStartDate && newEndDate) {
      const updatedRange = { start: newStartDate, end: newEndDate };
      setDateTimeRange(updatedRange);
      if (onChange) {
        onChange(newStartDate, newEndDate);
      }
    }
  };

  const handleApplyCustomRange = (startDate: Date, endDate: Date) => {
    setDateTimeRange({ start: startDate, end: endDate });
    if (onChange) {
      onChange(startDate, endDate);
    }
    setSelectedPreset('Custom');
  };

  return (
    <Box>
      <CenterX gap={2} my={1} justifyContent="space-between" flexWrap="wrap">
        <CenterX gap={1}>
          {hotbarPresets.map((presetItem) => (
            <Chip
              size="small"
              key={presetItem.label}
              label={presetItem.label}
              onClick={() => handlePresetClick(presetItem.label, presetItem.range)}
              variant={selectedPreset === presetItem.label ? 'filled' : 'outlined'}
              color={selectedPreset === presetItem.label ? 'primary' : 'default'}
            />
          ))}
          <DateRangePickerPopover
            selectedRange={{ startDate: dateTimeRange.start, endDate: dateTimeRange.end }}
            onApply={handleApplyCustomRange}
            triggerComponent={
              <Chip
                size="small"
                style={{ marginLeft: '16px', cursor: 'pointer' }}
                label={
                  <CenterX>
                    Custom range <ExpandMoreIcon />
                  </CenterX>
                }
                variant={selectedPreset === 'Custom' ? 'filled' : 'outlined'}
                color={selectedPreset === 'Custom' ? 'primary' : 'default'}
              />
            }
          />
        </CenterX>
        <CenterX gap={1}>
          <Typography variant="body2">
            <strong>{format(dateTimeRange.start, 'dd MMM yyyy')}</strong>{' '}
            {format(dateTimeRange.start, 'HH:mm')}
          </Typography>
          -
          <Typography variant="body2">
            <strong>{format(dateTimeRange.end, 'dd MMM yyyy')}</strong>{' '}
            {format(dateTimeRange.end, 'HH:mm')}
          </Typography>
          <ZoomButtons
            startDate={dateTimeRange.start}
            endDate={dateTimeRange.end}
            onChange={handleZoom}
          />
        </CenterX>
      </CenterX>
    </Box>
  );
};

export default DateRangePanel;
