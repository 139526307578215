import React from 'react';
import { CenterX } from '@components/LayoutUtils';
import { usePalette } from '@hooks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Typography, styled } from '@mui/material';
import { space } from '@utils/utils';

const ContainerInline = styled(CenterX)`
  gap: ${space(0.5)};
`;

export const ComingSoonSmall = () => {
  const currentPalette = usePalette();

  return (
    <ContainerInline>
      <AccessTimeIcon sx={{ width: '15px', height: '15px' }} />
      <Typography fontSize="12px" color={currentPalette.text.secondary}>
        More details coming soon
      </Typography>
    </ContainerInline>
  );
};

export const ComingSoonMedium = ({ featureName }: { featureName: string }) => {
  const currentPalette = usePalette();

  return (
    <CenterX justifyContent="space-between">
      <CenterX gap={1}>
        <AccessTimeIcon />
        <Box>
          <Typography fontSize="14px" color={currentPalette.text.secondary}>
            Coming soon:
          </Typography>
          <Typography fontSize="14px" color={currentPalette.text.secondary}>
            {featureName}
          </Typography>
        </Box>
      </CenterX>
    </CenterX>
  );
};

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const ComingSoonBig = ({ featureName }: { featureName: string }) => (
  <Container>
    <AccessTimeIcon />
    <Typography>Coming soon:</Typography>
    <Typography>{featureName}</Typography>
  </Container>
);

export default ComingSoonBig;
