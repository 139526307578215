import { ButtonBack } from '@components/Buttons/Buttons';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { paths } from 'paths';
import CreateSiteForm from './CreateSiteForm';

const CreateSite = () => (
  <>
    <ButtonBack path={paths.configuration}>Back to configuration list</ButtonBack>
    <TitleBar my={4} title="Create new site" titleVariant="h5" />
    <CreateSiteForm />
  </>
);

export default CreateSite;
