import { useCallback, useEffect, useMemo, useState } from 'react';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { clearEcdCurrent, fetchedGetEcd, fetchedUpdateEcd } from '@redux/ducks/ecds/actionCreators';
import { getEcdsSelector } from '@redux/ducks/ecds/selectors';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EcdDetailField } from './EcdDetails/EcdDetails';
import { DeviceWFields } from './EcdDevices/EcdDevices';
import { getDevicesToUpdate } from './utils/getDevicesToUpdate';
import { prepareDevicesForm } from './utils/prepareDevicesForm';
import { prepareEcdDetailsForm } from './utils/prepareEcdDetailsForm';

export const useEcdConfigurationForm = () => {
  const [sentOnlyEcdDetails, setSentOnlyEcdDetails] = useState(false);
  const [updatedEcdDetails, setUpdatedEcdDetails] = useState<{
    name?: string;
    site_id?: Nullable<number>;
  }>({});
  const [updatedDevices, setUpdatedDevices] = useState({});

  const [ecdDetailsFields, setEcdDetailsFields] = useState<Nullable<EcdDetailField[]>>(null);

  const [devicesWFields, setDevicesWFields] = useState<Nullable<DeviceWFields[]>>(null);

  const { id: ecdId } = useParams();

  const dispatch = useDispatch();

  const {
    current: { data, loading, error },
    update: { loading: isUpdating },
  } = useSelector(getEcdsSelector);

  useEffect(() => {
    if (data?.error) {
      dispatch(
        createAlert({
          message: 'Error occured while getting devices',
          variant: 'error',
        }),
      );
    }
  }, [dispatch, data]);

  useEffect(() => {
    fetchCurrentEcd();
    function fetchCurrentEcd() {
      if (!ecdId) return;

      dispatch(fetchedGetEcd(ecdId));
    }

    return () => {
      dispatch(clearEcdCurrent());
    };
  }, [dispatch, ecdId]);

  const {
    register: registerEcdDetailsInp,
    getValues: getEcdDetailsValues,
    reset: resetEcdDetailsForm,
    control: ecdDetailsFormControl,
  } = useForm();
  const {
    register: registerDeviceInp,
    getValues: getDevicesFormValues,
    reset: resetDevicesForm,
    control: devicesFormControl,
  } = useForm();

  const preparedForms = useMemo(() => {
    if (!data) return null;

    return {
      ...prepareEcdDetailsForm({ ...data, ...updatedEcdDetails }),
      ...prepareDevicesForm(data.devices, updatedDevices),
    };
  }, [data, updatedDevices, updatedEcdDetails]);

  const resetFormsToDefaultValues = useCallback(() => {
    if (!preparedForms) return;

    const { ecdDetailsInitVal, devicesInitVal } = preparedForms;

    resetEcdDetailsForm(ecdDetailsInitVal);
    resetDevicesForm(devicesInitVal);
  }, [preparedForms, resetDevicesForm, resetEcdDetailsForm]);

  useEffect(() => {
    if (!preparedForms) return;

    const { ecdDetailsFields: initEcdDetailsFields, devicesFields } = preparedForms;

    setEcdDetailsFields(initEcdDetailsFields);
    setDevicesWFields(devicesFields);

    resetFormsToDefaultValues();
  }, [preparedForms, resetFormsToDefaultValues, isUpdating]);

  const handleSubmit = () => {
    if (!preparedForms || !devicesWFields || !ecdId) return;

    const { name: nameToUpdate, site_id } = getEcdDetailsValues();
    const siteIdToUpdate = site_id === '' ? null : site_id;

    const { devicesToUpdate, devicesChangedValues } = getDevicesToUpdate(
      preparedForms.devicesInitVal,
      getDevicesFormValues(),
    );

    setUpdatedEcdDetails({ name: nameToUpdate, site_id: siteIdToUpdate });
    setUpdatedDevices({ ...updatedDevices, ...devicesChangedValues });
    setSentOnlyEcdDetails(!Object.keys(devicesToUpdate).length);

    dispatch(
      fetchedUpdateEcd({
        id: ecdId,
        site_id: siteIdToUpdate,
        name: nameToUpdate,
        devices: devicesToUpdate,
      }),
    );
  };

  return {
    error,
    loading,
    devicesWFields,
    ecdDetailsFields,
    handleSubmit,
    registerEcdDetailsInp,
    registerDeviceInp,
    ecdDetailsFormControl,
    devicesFormControl,
    sentOnlyEcdDetails,
    ecdName: updatedEcdDetails.name || data?.name,
  };
};
