import { EcdDevice } from '@appTypes/models/ecd.dto';
import { createDeviceField } from './deviceField';

export const prepareDevicesFormInitVal = (devices: EcdDevice[]) => {
  const initValues = devices.reduce<{ [key: string]: string }>(
    (acc, { id: deviceId, attributes }) =>
      attributes.reduce(
        (attrsAcc, { id, value }) => ({
          ...attrsAcc,
          [createDeviceField(deviceId, id)]: value,
        }),
        acc,
      ),
    {},
  );

  return initValues;
};
