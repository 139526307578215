import { CenterXY } from '@components/LayoutUtils';
import { Box, styled } from '@mui/material';

type OverlayProps = {
  blurIntensity?: number;
  backgroundColor?: string;
};

const OverlayBox = styled(CenterXY, {
  shouldForwardProp: (prop: string) => !['blurIntensity', 'backgroundColor'].includes(prop),
})<Omit<OverlayProps, 'children'>>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  backdrop-filter: blur(${({ blurIntensity }) => blurIntensity}px);
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ContentBox = styled(Box)`
  width: 100%;
  text-align: center;
`;

const Overlay = ({
  children,
  blurIntensity = 3,
  backgroundColor = 'rgba(255, 255, 255, 0.75)',
}: React.PropsWithChildren<OverlayProps>) => (
  <OverlayBox blurIntensity={blurIntensity} backgroundColor={backgroundColor}>
    <ContentBox>{children}</ContentBox>
  </OverlayBox>
);

export default Overlay;
