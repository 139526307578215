import { ActionCreator } from '@appTypes/helpers/redux';
import {
  UserActionsType,
  FetchedUsers,
  RequestUsersFailed,
  RequestUsers,
  RequestUsersSuccess,
  UpdateUserSuccess,
  FetchedUpdateUser,
  GetUserSuccess,
  FetchedGetUser,
  CreateUserSuccess,
  FetchedCreateUser,
  UpdateUsersFilter,
  SetUserRequestError,
  SetUserRequestLoading,
  ClearUserCurrent,
  FetchedUsersCount,
} from './actionTypes';

export const fetchedUsersCount: ActionCreator<FetchedUsersCount> = () => ({
  type: UserActionsType.FETCHED_USERS_COUNT,
});

export const setUserRequestError: ActionCreator<SetUserRequestError> = (payload) => ({
  type: UserActionsType.SET_USER_REQUEST_ERROR,
  payload,
});

export const setUserRequestLoading: ActionCreator<SetUserRequestLoading> = (payload) => ({
  type: UserActionsType.SET_USER_REQUEST_LOADING,
  payload,
});

export const createUserSuccess: ActionCreator<CreateUserSuccess> = (payload) => ({
  type: UserActionsType.CREATE_USER_SUCCESS,
  payload,
});

export const fetchedCreateUser: ActionCreator<FetchedCreateUser> = (payload) => ({
  type: UserActionsType.FETCHED_CREATE_USER,
  payload,
});

export const requestUsers: ActionCreator<RequestUsers> = () => ({
  type: UserActionsType.REQUEST_USERS,
});

export const requestUsersSuccess: ActionCreator<RequestUsersSuccess> = (payload) => ({
  type: UserActionsType.REQUEST_USERS_SUCCESS,
  payload,
});

export const requestUsersFailed: ActionCreator<RequestUsersFailed> = (payload) => ({
  type: UserActionsType.REQUEST_USERS_FAILED,
  payload,
});

export const fetchedUsers: ActionCreator<FetchedUsers> = ({ page = 1, pageSize = 5 }) => ({
  type: UserActionsType.FETCHED_USERS,
  payload: { page, pageSize },
});

export const getUserSuccess: ActionCreator<GetUserSuccess> = (payload) => ({
  type: UserActionsType.GET_USER_SUCCESS,
  payload,
});

export const fetchedGetUser: ActionCreator<FetchedGetUser> = (payload) => ({
  type: UserActionsType.FETCHED_GET_USER,
  payload,
});

export const updateUserSuccess: ActionCreator<UpdateUserSuccess> = (payload) => ({
  type: UserActionsType.UPDATE_USER_SUCCESS,
  payload,
});

export const fetchedUpdateUser: ActionCreator<FetchedUpdateUser> = (payload) => ({
  type: UserActionsType.FETCHED_UPDATE_USER,
  payload,
});

export const updateUsersFilter: ActionCreator<UpdateUsersFilter> = (payload) => ({
  type: UserActionsType.UPDATE_USER_FILTER,
  payload,
});

export type UsersAction =
  | RequestUsers
  | RequestUsersSuccess
  | RequestUsersFailed
  | FetchedUsers
  | UpdateUserSuccess
  | FetchedUpdateUser
  | GetUserSuccess
  | FetchedGetUser
  | CreateUserSuccess
  | FetchedCreateUser
  | FetchedUsersCount
  | UpdateUsersFilter
  | SetUserRequestLoading
  | ClearUserCurrent
  | SetUserRequestError;
