export const getPerformanceOverTimeStaticMock = (multiplier: number) => ({
  returnOnInvestment: {
    currency: 'gbp',
    items: [
      {
        key: 'balance-with-wattstor',
        value: 215.34 * multiplier,
      },
      {
        key: 'balance-without-wattstor',
        value: -112.54 * multiplier,
      },
    ],
    total: 327.97 * multiplier,
  },
  renewables: {
    generation: 69900 * multiplier,
    gridExport: 30900.23 * multiplier,
    carbonAvoided: 22570.590000000004 * multiplier,
    items: [
      {
        key: 'renewable-energy',
        value: 45000 * multiplier,
        dataPoints: [
          45.87, 88.88, 52.04, 98.22, 16.81, 41.73, 34.37, 21.87, 71.19, 61.18, 42.97, 24.72, 80.54,
          93.65, 67.7,
        ],
      },
      {
        key: 'co2-avoided',
        value: 12400 * multiplier,
        dataPoints: [
          45.87, 88.88, 52.04, 98.22, 16.81, 41.73, 34.37, 21.87, 71.19, 61.18, 42.97, 24.72, 80.54,
          93.65, 67.7,
        ],
      },
    ],
  },
  battery: {
    cyclesTotal: 10.93 * multiplier,
    cyclesAverage: 29.92 * multiplier,
    chargeTotal: 730789.51 * multiplier,
    dischargeTotal: 805145.98 * multiplier,
    chargeItems: [
      {
        key: 'from-grid',
        value: 719561.93 * multiplier,
      },
      {
        key: 'from-renewables',
        value: 11227.58 * multiplier,
      },
    ],
    dischargeItems: [],
  },
  consumption: {
    total: 30829.57 * multiplier,
    items: [
      {
        key: 'grid',
        value: 27128.51 * multiplier,
        dataPoints: [
          45.87, 88.88, 52.04, 98.22, 16.81, 41.73, 34.37, 21.87, 71.19, 61.18, 42.97, 24.72, 80.54,
          93.65, 67.7,
        ],
      },
      {
        key: 'renewables',
        value: 3701.06 * multiplier,
        dataPoints: [
          84.96, 78.54, 65.16, 57.15, 65.82, 34.87, 98.73, 37.27, 10.84, 11.28, 4.51, 42.18, 63.47,
          30.79, 90.87,
        ],
      },
    ],
  },
});
