import { GapY } from '@components/LayoutUtils';
import { Pagination } from '@components/Pagination';
import styled from '@emotion/styled';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  ColumnDef,
  SortingState,
  Updater,
  OnChangeFn,
} from '@tanstack/react-table';
import HeaderButton from './HeaderButton';

type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

interface TanStackTableProps<T> {
  columns: ColumnDef<T>[];
  rows: T[];
  itemsCount: number;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
}

const TanStackTable = <T,>({
  columns,
  rows,
  itemsCount,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: TanStackTableProps<T>) => {
  const table = useReactTable<T>({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: itemsCount,
    manualSorting: true,
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange,
    onSortingChange,
    state: {
      pagination,
      sorting,
    },
  });

  const changePage = (page: number) => {
    table.setPageIndex(page - 1);
  };

  const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: white;
    border-radius: 5px;
  `;

  const Th = styled.th`
    text-align: left;
    padding: 8px;
    font-size: 0.875rem;
    padding-left: 10px;
    font-weight: 500;
    color: #616161;
    padding-top: 12px;
    padding-bottom: 14px;
  `;

  const Td = styled.td`
    text-align: left;
    padding: 8px;
    font-size: 0.875rem;
    padding-left: 10px;
    border-top: 1px solid rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
    color: #424242;
  `;

  const Tr = styled.tr`
    &:hover {
      background-color: #f1f1f1;
    }
  `;

  const EmptyRow = styled.tr`
    td {
      height: 500px;
      text-align: center;
      vertical-align: middle;
      background-color: white;
    }
  `;

  return (
    <>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : <HeaderButton header={header} />}
                </Th>
              ))}
            </Tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length === 0 && (
            <EmptyRow>
              <Td colSpan={columns.length}>No rows</Td>
            </EmptyRow>
          )}
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} data-cy="list-row">
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
      {table.getPageCount() > 0 && (
        <>
          <GapY size={2} />
          <Pagination
            currentPage={pagination.pageIndex + 1}
            count={table.getPageCount()}
            onChange={changePage}
          />
        </>
      )}
    </>
  );
};

export default TanStackTable;
