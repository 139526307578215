import CONFIG, { SENTRY_LOGGING } from '@config';
import { store } from '@redux/store';
import {
  init as initSentry,
  captureException,
  replayIntegration,
  withScope,
  SeverityLevel,
  captureMessage,
} from '@sentry/react';
import {
  getEnvironment,
  getTimeInfo,
  getTokenInfo,
  isDevelopment,
  isProduction,
} from '@utils/utils';
import { storage } from './localStorage';

const SENTRY_DSN =
  'https://8a6fbc7a3d7b14f1c609a47a5540be69@o4506710698360832.ingest.sentry.io/4506734033174528';

type DebugInfo = {
  tags: Record<string, Nullable<number | string>>;
  user: Record<string, Nullable<number | string>>;
  additionalData: Record<string, Nullable<number | string> | Object>;
};

const loggerEnabled =
  (SENTRY_LOGGING.ENABLED_DEV && isDevelopment()) ||
  (SENTRY_LOGGING.ENABLED_PROD && isProduction());

const getNetworkDetailAllowUrls = () => {
  if (isProduction()) {
    return ['wattstor-prod.wattstor.net'];
  }
  return [window.location.origin, 'wattstor-dev.wattstor.net'];
};

const createDebugInfo = (): DebugInfo => {
  const { currentUser } = store.getState().authData;

  const unauthenticatedInfo = {
    tags: {
      _podiumVersion: CONFIG.APP_VERSION,
    },
    additionalData: {},
    user: {},
  };

  if (!currentUser) {
    return unauthenticatedInfo;
  }

  const { expired, validTime, validUntil } = getTokenInfo(storage.get('accessToken'));

  return {
    tags: {
      ...unauthenticatedInfo.tags,
    },
    additionalData: {
      ...unauthenticatedInfo.additionalData,
      loggedUserDetail: {
        ...getTimeInfo(),
        isExpired: expired,
        validUntil: `${validUntil} (${validTime}m)`,
      },
    },
    user: {
      email: currentUser?.email,
      fullName: `${currentUser.first_name} ${currentUser.last_name}`,
      organization: currentUser.organization?.name || 'None',
    },
  };
};

type LoggerOptions = {
  level: SeverityLevel;
  extra?: Record<string, any>;
  addDebugInfo?: boolean;
};

const defaultOptions: LoggerOptions = {
  level: 'log',
  extra: {},
  addDebugInfo: false,
};

const logger = {
  init() {
    if (!loggerEnabled) {
      return;
    }
    initSentry({
      dsn: SENTRY_DSN,
      integrations: [
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: getNetworkDetailAllowUrls(),
          networkDetailDenyUrls: [/login/],
        }),
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      environment: getEnvironment(),
    });
  },
  log(message: Error | string, options: LoggerOptions = defaultOptions) {
    if (!loggerEnabled) {
      return;
    }
    const { level, extra, addDebugInfo } = options;

    withScope((scope) => {
      scope.setLevel(level);
      if (extra) {
        Object.entries(extra).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });
      }

      if (addDebugInfo) {
        const { tags, additionalData, user } = createDebugInfo();

        Object.entries(tags).forEach(([key, value]) => {
          scope.setTag(key, value);
        });
        Object.entries(additionalData).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });
        scope.setUser(user);
      }

      if (typeof message === 'string') {
        captureMessage(message);
      } else {
        captureException(message);
      }
    });
  },
};

export default logger;
