import * as Yup from 'yup';
import { createStringValidator } from './createStringValidator';
import { checkMax } from './max';
import { checkMin } from './min';

export const checkEMail = (label: string, validator = Yup.string()) =>
  createStringValidator(checkMin(6), checkMax(50))(
    label,
    validator.email(`${label} must be valid`),
  );
