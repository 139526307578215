import { StateFilter } from '@appTypes/helpers/filters';
import { Box, Tab } from '@mui/material';
import { Tabs } from './Tabs/Tabs';

interface ActiveTabFilterProps {
  value: StateFilter;
  onValueChange(value: StateFilter): void;
}

export const ActiveTabFilter = ({ value, onValueChange }: ActiveTabFilterProps) => {
  const onTabChange = (_: unknown, val: StateFilter) => {
    onValueChange(val);
  };

  return (
    <Box>
      <Tabs value={value} onChange={onTabChange}>
        <Tab label="Active" value={StateFilter.ACTIVE} data-testid="tab-active" />
        <Tab label="Inactive" value={StateFilter.INACTIVE} data-testid="tab-inactive" />
        <Tab label="All" value={StateFilter.ALL} data-testid="tab-all" />
      </Tabs>
    </Box>
  );
};
