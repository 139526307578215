import { Alert } from '@appTypes/alerts/alert';
import { ActionType } from '@appTypes/helpers/redux';

export enum AlertsActionsTypes {
  CREATE_ALERT = 'CREATE_ALERT',
  SHOW_ALERT = 'SHOW_ALERT',
  HIDE_ALERT = 'HIDE_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

export type CreateAlert = ActionType<AlertsActionsTypes.CREATE_ALERT, Omit<Alert, 'id'>>;

export type ShowAlert = ActionType<AlertsActionsTypes.SHOW_ALERT, Alert>;

export type HideAlert = ActionType<AlertsActionsTypes.HIDE_ALERT, Alert['id']>;

export type RemoveAlert = ActionType<AlertsActionsTypes.REMOVE_ALERT, Alert['id']>;
