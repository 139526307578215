import { useEffect } from 'react';
import { useGetSitesAllQuery } from '@apiRtk/sites';
import { useRedirect } from '@hooks';
import { getBookmarkedSitesService } from '@services/bookmarkedSites';
import { MAX_BOOKMARKED_SITES } from '@settings/config';

const useRedirectToFirstSite = () => {
  const { data, isLoading } = useGetSitesAllQuery();
  const redirect = useRedirect();
  const bookmarkedSitesService = getBookmarkedSitesService(MAX_BOOKMARKED_SITES);

  useEffect(() => {
    const shouldRedirect = !isLoading && data?.sites?.length === 1;
    if (shouldRedirect) {
      const site = data.sites[0];
      if (site && !bookmarkedSitesService.isSiteBookmarked(site.id)) {
        bookmarkedSitesService.bookmarkSite({ id: site.id, name: site.name });
      }
      redirect((paths) => paths.siteOverview(site.id));
    }
  }, [bookmarkedSitesService, data, isLoading, redirect]);
};

export default useRedirectToFirstSite;
