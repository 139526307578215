import { FEATURE_FLAGS } from '@appTypes/common';
import { ListItemWithDatapoints, PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import CardHead from '@components/Cards/CardHead';
import CardSummaryItem from '@components/Cards/CardSummaryItem';
import { CardContentWithLoader } from '@components/Cards/CardWithLoader';
import { CardContainerColumn } from '@components/Cards/components';
import { GapY } from '@components/LayoutUtils';
import Overlay from '@components/Overlay/Overlay';
import { ReactComponent as IconSolar } from '@icons/solar.svg';
import { getPerformanceOverTimeStaticMock } from '@mocks/performanceOverTime';
import { Box, Grid } from '@mui/material';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import {
  createMinMaxMarkers,
  generateCardGraphColors,
  optionsAreaChart,
} from '@settings/apexOptions';
import AreaGraph from '../AreaGraph';
import { CardTooltip } from './CardTooltip';
import ComingSoonBig from './ComingSoonOverlay';

const CarbonGraph = ({ listItem }: { listItem: ListItemWithDatapoints }) => (
  <AreaGraph
    series={[listItem].map((item) => ({
      name: item.key,
      data: item.dataPoints,
    }))}
    options={{
      ...optionsAreaChart,
      colors: generateCardGraphColors('sustGreen'),
      xaxis: {
        type: 'numeric',
      },
      annotations: {
        points: [...createMinMaxMarkers(listItem?.dataPoints || [])],
      },
    }}
  />
);

interface CardRenewablesProps {
  data?: PerformanceOverTimeDto['renewables'];
  loading?: boolean;
}

const CardRenewables = ({ data, loading }: CardRenewablesProps) => {
  const mockRenewables = getPerformanceOverTimeStaticMock(1).renewables; // TODO: Remove when API data available

  const graphCarbonDataMock = mockRenewables?.items?.filter((item) => item.key === 'co2-avoided');

  return (
    <CardContainerColumn>
      <CardHead
        title="Renewable Energy"
        subtitle="And carbon emissions avoided"
        icon={<IconSolar />}
        rightSlot={<CardTooltip textKey="avoidedEmmisions" />}
      />
      <CardContentWithLoader loading={loading} sx={{ marginBottom: -2, marginX: -2 }}>
        {data ? (
          <>
            <Grid container px={2}>
              <Grid item xs={6}>
                <CardSummaryItem value={data.generation} title="Renewables generated" unit="Wh" />
              </Grid>
              <Grid item xs={6}>
                <CardSummaryItem value={data.gridExport} title="Exported to the grid" unit="Wh" />
              </Grid>
            </Grid>
            <GapY size={3} />
            <Box position="relative" display="flex" flexDirection="column">
              <Grid container>
                {!isFeatureFlagEnabled(FEATURE_FLAGS.DISABLE_COMING_SOON_OVERLAYS) ? (
                  <Overlay>
                    <Box>
                      <ComingSoonBig featureName="Carbon emisions avoided" />
                    </Box>
                  </Overlay>
                ) : null}
                <Grid item xs={6} px={2}>
                  <CardSummaryItem
                    value={mockRenewables.carbonAvoided}
                    title={
                      <>
                        of CO<sub>2</sub> avoided
                      </>
                    }
                    valueColor="green60"
                    unit="g"
                  />
                </Grid>
              </Grid>
              <GapY size={3} />
              {graphCarbonDataMock.length && graphCarbonDataMock[0]?.dataPoints?.length ? (
                <CarbonGraph listItem={graphCarbonDataMock[0]} />
              ) : null}
            </Box>
          </>
        ) : (
          <Box sx={{ height: '100px' }} />
        )}
      </CardContentWithLoader>
    </CardContainerColumn>
  );
};

export default CardRenewables;
