import React, { useState } from 'react';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { ErrorBoundary } from '@sentry/react';

const LocalErrorBoundary = ({
  children,
  message,
  retry,
}: React.PropsWithChildren<{
  message: string;
  retry: () => void;
}>) => {
  const [key, setKey] = useState(0);

  const handleRetry = () => {
    setKey((prevKey) => prevKey + 1);
    retry();
  };

  const customFallback = () => (
    <AlertWithRefetch onRetryClick={handleRetry}>{message}</AlertWithRefetch>
  );

  return (
    <ErrorBoundary
      key={key}
      fallback={customFallback}
      beforeCapture={(scope) => {
        scope.setLevel('error');
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default LocalErrorBoundary;
