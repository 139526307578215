import { PropsWithChildren } from 'react';
import Overlay from '@components/Overlay/Overlay';
import { Spinner } from '@components/Spinner';
import { BoxProps } from '@mui/material';
import { CardContentWrapper } from './components';

export const CardContentWithLoader = ({
  children,
  loading,
  sx,
  ...props
}: PropsWithChildren<
  BoxProps & {
    loading?: boolean;
  }
>) => (
  <CardContentWrapper sx={{ position: 'relative', ...sx }} {...props}>
    {children}
    {loading ? (
      <Overlay>
        <Spinner />
      </Overlay>
    ) : undefined}
  </CardContentWrapper>
);
