import { takeEvery } from 'redux-saga/effects';
import { sagaApiErrorHandler } from './sagaApiErrorHandler';

type Saga = Parameters<typeof takeEvery>[1];

export const createSafeSaga = (saga: Saga) => {
  function* sagaFn(...sagaParams: Parameters<Saga>) {
    try {
      yield saga(...sagaParams);
    } catch (error) {
      yield sagaApiErrorHandler(error);
    }
  }

  return sagaFn;
};
