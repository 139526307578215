import { ReactNode } from 'react';
import { CenterX } from '@components/LayoutUtils';
import { Box } from '@mui/material';
import { CardSubtitle, TextLarge } from './components';

type CardDashboardProps = {
  title: string;
  subtitle?: string;
  icon?: ReactNode;
  rightSlot: ReactNode;
};

const CardHead = ({ title, subtitle, rightSlot, icon }: CardDashboardProps) => (
  <CenterX justifyContent="space-between">
    <CenterX gap={1.5}>
      {icon || null}
      <Box>
        <TextLarge color="neutral100">{title}</TextLarge>
        {subtitle ? <CardSubtitle>{subtitle}</CardSubtitle> : null}
      </Box>
    </CenterX>
    {rightSlot ? <CenterX>{rightSlot}</CenterX> : null}
  </CenterX>
);

export default CardHead;
