import { useEffect, MutableRefObject, useRef } from 'react';
import { connect, ECharts } from 'echarts';

const GROUP_ID = 'group1';

const enableZoomAction = {
  type: 'takeGlobalCursor',
  key: 'dataZoomSelect',
  dataZoomSelectActive: true,
};

type RefObject = {
  getEchartsInstance: () => ECharts;
};

type UseChartPostRenderEffect = {
  refs: MutableRefObject<Nullable<RefObject>[]>;
  dependencies: any[];
  ready: boolean;
};

const useChartPostRenderEffect = ({
  refs,
  ready,
  dependencies,
}: UseChartPostRenderEffect): Nullable<ECharts>[] => {
  const instancesRef = useRef<Nullable<ECharts>[]>([]);
  const graphsCount = refs.current.length;

  useEffect(() => {
    const instances = refs.current.map((ref, index) => {
      if (ref) {
        const instance = ref.getEchartsInstance();
        instance.group = GROUP_ID;

        if (index === graphsCount - 1) {
          instance.dispatchAction(enableZoomAction);
        }
        return instance;
      }
      return null;
    });

    instancesRef.current = instances;

    if (ready && refs.current.length) {
      connect(GROUP_ID);
    }
  }, [graphsCount, ready, refs]);

  useEffect(() => {
    instancesRef?.current[0]?.dispatchAction(enableZoomAction);
  }, [dependencies, graphsCount]);

  return instancesRef.current;
};

export default useChartPostRenderEffect;
