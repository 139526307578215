import { ActionCreator } from '@appTypes/helpers/redux';
import {
  OrganizationActionsType,
  FetchedOrganizations,
  RequestOrganizationsFailed,
  RequestOrganizations,
  RequestOrganizationsSuccess,
  UpdateOrganizationSuccess,
  FetchedUpdateOrganization,
  GetOrganizationSuccess,
  FetchedGetOrganization,
  CreateOrganizationSuccess,
  FetchedCreateOrganization,
  UpdateOrganizationsFilter,
  SetOrganizationRequestError,
  SetOrganizationRequestLoading,
  ClearOrganizationCurrent,
  FetchedOrganizationsCount,
} from './actionTypes';

export const fetchedOrganizationsCount: ActionCreator<FetchedOrganizationsCount> = () => ({
  type: OrganizationActionsType.FETCHED_ORGANIZATIONS_COUNT,
});

export const setOrganizationRequestError: ActionCreator<SetOrganizationRequestError> = (
  payload,
) => ({
  type: OrganizationActionsType.SET_ORGANIZATION_REQUEST_ERROR,
  payload,
});

export const setOrganizationRequestLoading: ActionCreator<SetOrganizationRequestLoading> = (
  payload,
) => ({
  type: OrganizationActionsType.SET_ORGANIZATION_REQUEST_LOADING,
  payload,
});

export const createOrganizationSuccess: ActionCreator<CreateOrganizationSuccess> = (payload) => ({
  type: OrganizationActionsType.CREATE_ORGANIZATION_SUCCESS,
  payload,
});

export const fetchedCreateOrganization: ActionCreator<FetchedCreateOrganization> = (payload) => ({
  type: OrganizationActionsType.FETCHED_CREATE_ORGANIZATION,
  payload,
});

export const requestOrganizations: ActionCreator<RequestOrganizations> = () => ({
  type: OrganizationActionsType.REQUEST_ORGANIZATIONS,
});

export const requestOrganizationsSuccess: ActionCreator<RequestOrganizationsSuccess> = (
  payload,
) => ({
  type: OrganizationActionsType.REQUEST_ORGANIZATIONS_SUCCESS,
  payload,
});

export const requestOrganizationsFailed: ActionCreator<RequestOrganizationsFailed> = (payload) => ({
  type: OrganizationActionsType.REQUEST_ORGANIZATIONS_FAILED,
  payload,
});

export const fetchedOrganizations: ActionCreator<FetchedOrganizations> = ({
  page = 1,
  pageSize = 5,
}) => ({
  type: OrganizationActionsType.FETCHED_ORGANIZATIONS,
  payload: { page, pageSize },
});

export const getOrganizationSuccess: ActionCreator<GetOrganizationSuccess> = (payload) => ({
  type: OrganizationActionsType.GET_ORGANIZATION_SUCCESS,
  payload,
});

export const fetchedGetOrganization: ActionCreator<FetchedGetOrganization> = (payload) => ({
  type: OrganizationActionsType.FETCHED_GET_ORGANIZATION,
  payload,
});

export const updateOrganizationSuccess: ActionCreator<UpdateOrganizationSuccess> = (payload) => ({
  type: OrganizationActionsType.UPDATE_ORGANIZATION_SUCCESS,
  payload,
});

export const fetchedUpdateOrganization: ActionCreator<FetchedUpdateOrganization> = (payload) => ({
  type: OrganizationActionsType.FETCHED_UPDATE_ORGANIZATION,
  payload,
});

export const updateOrganizationsFilter: ActionCreator<UpdateOrganizationsFilter> = (payload) => ({
  type: OrganizationActionsType.UPDATE_ORGANIZATION_FILTER,
  payload,
});

export type OrganizationsAction =
  | RequestOrganizations
  | RequestOrganizationsSuccess
  | RequestOrganizationsFailed
  | FetchedOrganizations
  | UpdateOrganizationSuccess
  | FetchedUpdateOrganization
  | GetOrganizationSuccess
  | FetchedGetOrganization
  | CreateOrganizationSuccess
  | FetchedCreateOrganization
  | UpdateOrganizationsFilter
  | FetchedOrganizationsCount
  | SetOrganizationRequestLoading
  | ClearOrganizationCurrent
  | SetOrganizationRequestError;
