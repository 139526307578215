import { ButtonPrimary } from '@components/Buttons/Buttons';
import { Flex } from '@components/LayoutUtils';
import { Send } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { useEcdConfigurationContext } from '@providers/EcdConfigurationProvider';

export const SubmitDrawer = ({ onSubmit }: { onSubmit(): void }) => {
  const { allChangesCount, isFormFreezed } = useEcdConfigurationContext();

  return (
    <Drawer
      open={allChangesCount > 0}
      anchor="top"
      variant="persistent"
      PaperProps={{
        sx: {
          width: 'calc(300px)',
          left: 'unset',
          right: 0,
          border: 0,
          background:
            'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 27%, rgba(255,255,255,1) 100%)',
        },
      }}
    >
      <Flex justifyContent="end" px={3} py={4}>
        <ButtonPrimary
          onClick={() => {
            onSubmit();
          }}
          disabled={isFormFreezed}
          startIcon={<Send />}
        >
          {`Send ${allChangesCount || 1} change${allChangesCount < 2 ? '' : 's'}`}
        </ButtonPrimary>
      </Flex>
    </Drawer>
  );
};
