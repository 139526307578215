export const createFullName = (
  firstName: Nullable<string>,
  lastName: Nullable<string>,
  reversed: boolean = true,
) => {
  if (firstName && lastName) {
    return reversed ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
  }

  return firstName || lastName || '';
};
