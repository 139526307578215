import { useEffect } from 'react';
import {
  useCreateNotificationRuleMutation,
  useDeleteNotificationRuleMutation,
  useUpdateNotificationRuleMutation,
} from '@apiRtk/notificationRules';
import { NotificationRuleDto } from '@appTypes/models/notificationRule.dto';
import {
  ConditionComparisonOperator,
  CalculatorComparisonField,
  NotificationRuleDevice,
  BatteryComparisonField,
} from '@appTypes/notificationRules/fields';
import { ButtonPrimary } from '@components/Buttons/Buttons';
import { CenterX } from '@components/LayoutUtils';
import { TriggerWithConfirmModal } from '@components/Modals/ConfirmModal';
import { SitesSelect } from '@components/SitesSelect/SitesSelect';
import { Spinner } from '@components/Spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutationAlerts } from '@hooks';
import {
  Check,
  Close,
  DeleteOutline,
  LocationOn,
  MailOutline,
  CalculateSharp,
} from '@mui/icons-material';
import { Box, Typography, TextField } from '@mui/material';
import { getRgbaColor } from '@utils/utils';
import { Controller, useForm } from 'react-hook-form';
import { palette } from 'styles/palette';
import { ComparisonFieldSelectBox } from './ComparisonFieldSelectBox/ComparisonFieldSelectBox';
import { ConditionComparisonSelectBox } from './ConditionComparisonSelectBox/ConditionComparisonSelectBox';
import { ConditionValInput } from './ConditionValInput/ConditionValInput';
import { DeviceSelectBox } from './DeviceSelectBox/DeviceSelectBox';
import { DrawerContentWrapper } from './DrawerContentWrapper';
import editRuleFormSchema from './EditRuleFormSchema';
import { IsActiveSelectBox, IsActiveSelectBoxVal } from './IsActiveSelectBox/IsActiveSelectBox';
import { LabeledInput } from './Labeled/LabeledInput';
import { LabeledSelectBox } from './Labeled/LabeledSelectBox';

export type EditRuleFormData = {
  is_active: IsActiveSelectBoxVal.ACTIVE | IsActiveSelectBoxVal.INACTIVE;
  emails_list: string;
  site_id: number;
  device: `${NotificationRuleDevice}`;
  condition_comparison_operator: `${ConditionComparisonOperator}`;
  condition_value: number;
  comparison_field: `${CalculatorComparisonField}` | `${BatteryComparisonField}`;
};

interface EditRuleProps {
  selectedSiteId?: Nullable<number>;
  rule?: Nullable<NotificationRuleDto>;
  handleFormClose: () => void;
}

const getActive = (
  rule: Nullable<NotificationRuleDto> | undefined,
): IsActiveSelectBoxVal.ACTIVE | IsActiveSelectBoxVal.INACTIVE => {
  if (!rule) {
    return IsActiveSelectBoxVal.ACTIVE;
  }

  return rule.is_active ? IsActiveSelectBoxVal.ACTIVE : IsActiveSelectBoxVal.INACTIVE;
};

const getEditRuleFormData = (
  rule: Nullable<NotificationRuleDto> | undefined,
): EditRuleFormData => ({
  is_active: getActive(rule),
  emails_list: rule?.emails_list ?? '',
  site_id: rule?.site.id ?? 0,
  device: rule?.device ?? 'Calculator',
  condition_comparison_operator: rule?.condition_comparison_operator ?? 'equal or greater than',
  condition_value: rule?.condition_value ?? 0,
  comparison_field: rule?.comparison_field ?? 'consumption',
});

export const EditRule = ({ selectedSiteId, rule, handleFormClose }: EditRuleProps) => {
  const [createNotificationRule, createResult] = useCreateNotificationRuleMutation();
  const [deleteNotificationRule, deleteResult] = useDeleteNotificationRuleMutation();
  const [updateNotificationRule, updateResult] = useUpdateNotificationRuleMutation();

  useMutationAlerts({
    mutationResult: updateResult,
    actionName: 'Update notification rule',
    onSuccess: handleFormClose,
  });

  useMutationAlerts({
    mutationResult: createResult,
    actionName: 'Create notification rule',
    onSuccess: handleFormClose,
  });

  useMutationAlerts({
    mutationResult: deleteResult,
    actionName: 'Delete notification rule',
    onSuccess: handleFormClose,
  });

  const editMode = Boolean(rule);

  const {
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<EditRuleFormData>({
    mode: 'onChange',
    defaultValues: {
      is_active: getActive(rule),
      emails_list: rule?.emails_list ?? '',
      site_id: rule?.site.id ?? selectedSiteId ?? 0,
      device: rule?.device ?? NotificationRuleDevice.CALCULATOR,
      condition_comparison_operator:
        rule?.condition_comparison_operator ?? ConditionComparisonOperator.GREATER_OR_EQUAL,
      condition_value: rule?.condition_value ?? 0.75,
      comparison_field: rule?.comparison_field ?? CalculatorComparisonField.CONSUMPTION,
    },
    resolver: zodResolver(editRuleFormSchema),
  });

  useEffect(() => {
    const newFormData = getEditRuleFormData(rule);
    reset(newFormData);
  }, [rule, reset]);

  const selectedDevice = watch('device');
  useEffect(() => {
    if (selectedDevice === NotificationRuleDevice.BATTERY) {
      setValue('comparison_field', BatteryComparisonField.SOC);
    } else if (selectedDevice === NotificationRuleDevice.CALCULATOR) {
      setValue('comparison_field', CalculatorComparisonField.CONSUMPTION);
    }
  }, [selectedDevice, setValue]);

  useEffect(() => {
    if (selectedSiteId) {
      setValue('site_id', selectedSiteId);
    }
  }, [selectedSiteId, setValue]);

  const onSubmit = (formData: EditRuleFormData) => {
    const preparedValues = {
      ...formData,
      description: '',
      is_active: formData.is_active === IsActiveSelectBoxVal.ACTIVE,
      site_id: formData.site_id,
      condition_value: formData.condition_value,
    };

    if (editMode && rule) {
      updateNotificationRule({
        id: rule.id,
        ...preparedValues,
      });
    } else {
      createNotificationRule(preparedValues);
    }
  };

  const handleDelete = () => {
    if (rule) {
      deleteNotificationRule(rule.id);
    }
  };

  return (
    <DrawerContentWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="grid" gridTemplateColumns="1fr auto" alignItems="center" mb={3}>
        <Typography variant="h5" color="primary" fontWeight={700}>
          {editMode ? 'Edit' : 'Add New'} Rule
        </Typography>
        <Close sx={{ cursor: 'pointer' }} color="primary" onClick={handleFormClose} />
      </Box>

      <Controller
        name="is_active"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <IsActiveSelectBox value={value} onChange={onChange} />
        )}
      />

      <Controller
        name="site_id"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <LabeledSelectBox
            IconComponent={LocationOn}
            label="When in site"
            selectBox={
              <SitesSelect
                value={value}
                onChange={onChange}
                disableEmptyValue
                disabled={selectedSiteId !== null}
              />
            }
          />
        )}
      />

      <Controller
        name="device"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <DeviceSelectBox data-testid="device" value={value} onChange={onChange} />
        )}
      />

      <Controller
        name="comparison_field"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <ComparisonFieldSelectBox
            value={value}
            onChange={onChange}
            currentDevice={watch('device')}
          />
        )}
      />

      <Controller
        name="condition_comparison_operator"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <ConditionComparisonSelectBox value={value} onChange={onChange} />
        )}
      />

      <Controller
        name="condition_value"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <>
            <ConditionValInput
              data-testid="condition_value"
              value={value}
              onChange={onChange}
              error={!!errors.condition_value}
            />
            {errors.condition_value && (
              <Typography
                data-testid="condition_value_error"
                variant="subtitle2"
                color="error"
                sx={{ mt: '-12px' }}
              >
                {errors.condition_value.message}
              </Typography>
            )}
          </>
        )}
      />

      <Controller
        name="emails_list"
        control={control}
        rules={{ required: false }}
        render={({ field: { onChange, value } }) => (
          <>
            <LabeledInput
              label="Notify by e-mail"
              IconComponent={MailOutline}
              renderedInputComponent={
                <TextField
                  data-testid="emails_list"
                  multiline
                  rows={3}
                  value={value}
                  onChange={onChange}
                  error={!!errors.emails_list}
                />
              }
            />{' '}
            {errors.emails_list && (
              <Typography
                data-testid="emails_list_error"
                variant="subtitle2"
                color="error"
                sx={{ mt: '-12px' }}
              >
                {errors.emails_list.message}
              </Typography>
            )}
          </>
        )}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'space-between',
        }}
      >
        <ButtonPrimary startIcon={<Check />} type="submit">
          <Box sx={{ mr: 2 }}>Done</Box>
          {createResult.isLoading || (updateResult.isLoading && <Spinner size={15} />)}
        </ButtonPrimary>
        {editMode && (
          <TriggerWithConfirmModal
            renderTrigger={(openModal) => (
              <DeleteOutline
                data-testid="delete-rule-button"
                color="secondary"
                sx={{
                  padding: 1.2,
                  borderRadius: '40px',
                  backgroundColor: getRgbaColor(palette.secondary.main, 0.1),
                  cursor: 'pointer',
                }}
                onClick={openModal}
                fontSize="small"
              />
            )}
            onConfirm={handleDelete}
            dialogTitle="Delete rule"
          >
            <Typography variant="subtitle1">
              Do you really want to delete the following rule?
            </Typography>
            <CenterX p={1} gap={0.5}>
              <LocationOn color="secondary" />
              <Typography variant="caption">{rule?.site.name}</Typography>
            </CenterX>
            <CenterX p={1} pt={0} gap={0.5}>
              <CalculateSharp color="secondary" />
              <Typography variant="caption">{rule?.description}</Typography>
            </CenterX>
          </TriggerWithConfirmModal>
        )}
      </Box>
    </DrawerContentWrapper>
  );
};
