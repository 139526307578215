import _ from 'lodash';
import Pattern from './Pattern';

type CircleIconProps = {
  icon: JSX.Element;
  rotation?: number;
  size?: number;
  fill?: string;
  wireThickness?: number;
};

const CircleIcon = ({
  children,
  icon,
  fill,
  size = 24,
  rotation = 0,
}: React.PropsWithChildren<CircleIconProps>) => {
  const patternId = `circle-icon-pattern-${_.uniqueId()}`;

  return (
    <g>
      <Pattern id={patternId} rotation={rotation} fill={fill}>
        {icon}
      </Pattern>
      <circle cx="0" cy={size / 2} r={size / 2} fill={`url(#${patternId})`}>
        {children}
      </circle>
    </g>
  );
};

export default CircleIcon;
