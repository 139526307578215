import React from 'react';

export const usePreviousValue = <T>(value: T): React.MutableRefObject<Nullable<T>>['current'] => {
  const ref = React.useRef<Nullable<T>>(null);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
