import { DeviceParameter } from '@appTypes/models/energyFlow.dto';

export enum ENERGY_FLOW_NODE_COLORS {
  default = 'white',
  grid = '#ffa39e',
  generation = '#b7eb8f',
  consumption = '#ffbb96',
  ev = '#91d5ff',
  battery = '#e9bbf0',
}

export enum ENERGY_FLOW_INDICATOR_COLORS {
  yellow = '#f90',
  green = '#0a0',
  red = '#c00',
}

type DiagramConfigItem = {
  title: string;
  icon: JSX.Element;
  iconOn?: JSX.Element;
  reverseFlow?: boolean;
  energyColor?: string;
  mainParameterName?: string;
};

export type DiagramConfig = Record<string, DiagramConfigItem>;

export type DeviceParameterWithName = DeviceParameter & {
  name: string;
};
