import { useGetEcdsQuery, useGetEcdStatusQuery } from '@apiRtk/imps';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { EcdSimpleDto } from '@appTypes/models/ecd.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Flex } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Switch } from '@components/Switch';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect } from '@hooks';
import { Wifi } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { palette } from 'styles/palette';

const createColumns = (redirect: ReturnType<typeof useRedirect>): ColumnDef<EcdSimpleDto>[] => [
  {
    header: 'Status',
    accessorKey: 'is_active',
    enableSorting: false,
    cell: ({ row }) => (
      <Flex style={{ maxWidth: '80px' }}>
        <Switch size="small" checked={row.original.is_active} />
      </Flex>
    ),
  },
  {
    header: 'ECD ID',
    accessorKey: 'id',
    cell: ({ row }) => <div style={{ flex: 0.6 }}>{row.original.id}</div>,
  },
  {
    header: 'ECD Name',
    accessorKey: 'name',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.name}</div>,
  },
  {
    header: 'Connect',
    accessorKey: 'is_online',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ maxWidth: 75 }}>
        <Box width="100%" display="grid" justifyItems="center">
          {(() => {
            const { data, isLoading } = useGetEcdStatusQuery(row.original.id);

            if (isLoading) {
              return <Spinner size={20} />;
            }

            if (!data) {
              return <Wifi sx={{ color: palette.neutral.main }} />;
            }

            return (
              <Wifi sx={{ color: data.alive ? palette.success.dark : palette.neutral.main }} />
            );
          })()}
        </Box>
      </div>
    ),
  },
  {
    header: 'Organization',
    accessorKey: 'organization.name',
    enableSorting: false,
    cell: ({ row }) => (
      <DetailCell
        onDetailClick={() => redirect((paths) => paths.configurationEcd(row.original.id))}
      >
        {row.original.organization?.name}
      </DetailCell>
    ),
  },
];

export type EcdListProps = {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const EcdList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: EcdListProps) => {
  const redirect = useRedirect();

  const columns = createColumns(redirect);

  const { data, isError, refetch, isLoading } = useGetEcdsQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {isLoading && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>
          Some error occurred while getting ECDs, please try again later.
        </AlertWithRefetch>
      )}
      {data && !isLoading && (
        <TanStackTable<EcdSimpleDto>
          columns={columns}
          rows={data.imps || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
