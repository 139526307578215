import { RouterHistoryRoute } from '@appTypes/helpers/routerHistory';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { RouterHistoryAction } from './actionCreators';
import { RouterHistoryActionsTypes } from './actionTypes';

export const routerHistory = createBrowserHistory();

const initialState = {
  currentRoute: _.pick(routerHistory, ['action', 'location']) as RouterHistoryRoute,
  previousRoute: null as Nullable<RouterHistoryRoute>,
};

export const routerHistoryReducer = (
  state = initialState,
  action: RouterHistoryAction,
): RouterHistoryStateType => {
  switch (action.type) {
    case RouterHistoryActionsTypes.UPDATE_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
        previousRoute: state.currentRoute,
      };
    default:
      return state;
  }
};

export type RouterHistoryStateType = typeof initialState;
