import { SitesSelect } from '@components/SitesSelect/SitesSelect';
import { EcdDetailField } from '../EcdDetails/EcdDetails';
import {
  prepareEcdDetailsFormInitVal,
  PrepareEcdDetailsFormInitValData,
} from './prepareEcdDetailsFormInitVal';

const fields: Omit<EcdDetailField, 'initValue'>[] = [
  { name: 'name', label: 'Name' },
  { name: 'id', label: 'ID', readOnly: true },
  {
    name: 'creation_date',
    label: 'Creation date',
    readOnly: true,
    isDate: true,
  },
  {
    name: 'last_modify_date',
    label: 'Last modify date',
    readOnly: true,
    isDate: true,
  },
  { name: 'status', label: 'Status', readOnly: true },
  {
    name: 'site_id',
    label: 'Site',
    CustomComponent: ({ ...props }) => (
      // @ts-ignore
      <SitesSelect {...props} emptyValueContent="-" sx={{ '& .MuiSelect-select': { py: 1.6 } }} />
    ),
  },
];

export const prepareEcdDetailsForm = (data: PrepareEcdDetailsFormInitValData) => {
  const ecdDetailsInitVal = prepareEcdDetailsFormInitVal(data);

  const ecdDetailsFields = fields.map((field) => ({
    ...field,
    initValue: ecdDetailsInitVal[field.name as keyof typeof ecdDetailsInitVal],
  }));

  return { ecdDetailsFields, ecdDetailsInitVal };
};
