import { ActionType } from '@appTypes/helpers/redux';
import { RouterHistoryRoute } from '@appTypes/helpers/routerHistory';

export enum RouterHistoryActionsTypes {
  UPDATE_CURRENT_ROUTE = 'UPDATE_CURRENT_ROUTE',
}

export type UpdateCurrentRoute = ActionType<
  RouterHistoryActionsTypes.UPDATE_CURRENT_ROUTE,
  RouterHistoryRoute
>;
