import { HttpStatusCode } from '@appTypes/api/error';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import { useDashboardInfo } from '@hooks';
import { ChartToolbarProvider } from '@providers/ChartToolbarProvider';
import { selectAuthSlice } from '@redux/slices/auth';
import { dictionary } from '@settings/dictionary';
import { useSelector } from 'react-redux';
import { Graph } from './Graph';

const DataAnalysis = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const dashboardPageConfig = {
    displayDateRangeSelect: true,
    displayFromInput: true,
  };

  const { refetch, dashboardId, error, isError, isLoading, graphsConfig, siteId } =
    useDashboardInfo();

  if (!graphsConfig || isLoading) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  if (isError) {
    const errorMessage =
      error?.status === HttpStatusCode.NOT_FOUND
        ? `Dashboard with id: "${dashboardId}" not found`
        : dictionary.errorCommon;

    return (
      <SiteHeaderWrapper siteId={siteId} siteName="-">
        <AlertWithRefetch onRetryClick={refetch}>{errorMessage}</AlertWithRefetch>
      </SiteHeaderWrapper>
    );
  }

  const currentSite = currentUser?.sites.find(({ id }) => id === siteId);
  const title = currentSite ? currentSite.name : '-';

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <ChartToolbarProvider {...dashboardPageConfig}>
        {({ renderDateInputs, timeSpan }) => (
          <>
            {renderDateInputs()}
            {graphsConfig.map((graph) => (
              <Graph key={`${graph.id}-${graph.graph_id}`} timeSpan={timeSpan} {...graph} />
            ))}
          </>
        )}
      </ChartToolbarProvider>
    </SiteHeaderWrapper>
  );
};

export default DataAnalysis;
