export enum ConditionComparisonOperator {
  GREATER_OR_EQUAL = 'equal or greater than',
  LESS_OR_EQUAL = 'equal or less than',
}

export enum NotificationRuleDevice {
  BATTERY = 'Battery',
  CALCULATOR = 'Calculator',
}

export enum CalculatorComparisonField {
  CONSUMPTION = 'consumption',
  GRID_CONSUMPTION = 'grid_consumption',
  PV_GENERATION = 'pv_generation',
  GRID_BACKFLOW = 'grid_backflow',
  BATTERY_CHARGE = 'battery_charge',
  BATTERY_DISCHARGE = 'battery_discharge',
}

export enum BatteryComparisonField {
  SOC = 'Battery_SOC',
  SOH = 'soh',
}
