import { DefaultFilters } from '@appTypes/helpers/redux';
import { AppStateType } from '@appTypes/redux';

export enum ConfiguredItem {
  ECDS = 'ECDS',
  SITES = 'SITES',
  ORGANIZATIONS = 'ORGANIZATIONS',
  USERS = 'USERS',
}

export type ConfigurationURLQuery = {
  selectedItem: string;
};

export type ListSelectorReturn = {
  filters: Omit<DefaultFilters, 'page' | 'pageSize'>;
  itemsCount: number;
};

export type CurrentListSelector = (state: AppStateType) => ListSelectorReturn;
