import {
  Children,
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useEffect,
  useState,
} from 'react';
import { Button, ButtonGroup, css, styled } from '@mui/material';
import { palette } from 'styles/palette';

const StyledButtonGroup = styled(ButtonGroup)`
  box-shadow: none;
  height: 32px;
`;

export const ButtonTabsItem = styled(Button, {
  shouldForwardProp: (prop: string) => !['active'].includes(prop),
})<{ active?: boolean }>`
  border-radius: 8px;
  background: ${palette.white};
  border: 1px solid ${palette.primary.main} !important;
  color: ${palette.primary.main};
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;

  ${({ active }) =>
    active
      ? css`
          background: ${palette.primary.main};
          color: ${palette.white};
          font-weight: bold;
        `
      : css`
          :hover {
            background: ${palette.neutral.main};
            color: ${palette.primary.main};
          }
        `};
`;

type ChildType = ReactElement<ComponentProps<typeof ButtonTabsItem>>;

const ButtonTabs = ({
  children,
  initialSelectedIndex = undefined,
}: PropsWithChildren<{ initialSelectedIndex?: number }>) => {
  const [selectedItem, setSelectedItem] = useState<Nullable<number>>(null);

  useEffect(() => {
    if (typeof initialSelectedIndex !== 'undefined') {
      setSelectedItem(initialSelectedIndex);
    }
  }, [initialSelectedIndex]);

  if (!children) {
    return null;
  }

  const enhancedChildren = Children.map(children, (child, index) =>
    cloneElement(child as ChildType, {
      onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if ((child as ChildType).props.onClick) {
          (child as ChildType).props?.onClick?.(e);
        }
        setSelectedItem(index);
      },
      active: selectedItem === index,
    }),
  );

  return <StyledButtonGroup>{enhancedChildren}</StyledButtonGroup>;
};

export default ButtonTabs;
