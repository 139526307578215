import { z } from 'zod';

export const siteFormSchema = z.object({
  organization_id: z.number({
    required_error: 'Organization is required',
    invalid_type_error: 'Please select organization',
  }),
  name: z
    .string({
      required_error: 'Name is required',
      invalid_type_error: 'Name must be a string',
    })
    .regex(/^[^<>;\\]*$/, { message: 'Name must not contain special characters.' })
    .min(2, { message: 'Name must contain at least 2 characters' })
    .max(50, { message: 'Name must contain at most 50 characters' }),
  street: z
    .string({
      required_error: 'Street is required',
      invalid_type_error: 'Street must be a string',
    })
    .regex(/^[^<>;\\]*$/, { message: 'Street must not contain special characters.' })
    .min(2, { message: 'Street must contain at least 2 characters' })
    .max(50, { message: 'Street must contain at most 50 characters' }),
  city: z
    .string({
      required_error: 'City is required',
      invalid_type_error: 'City must be a string',
    })
    .regex(/^[^0-9!@#$%^&*_;()?~`"{}|<>[\]\\]*$/, {
      message: 'City must not contain special characters.',
    })
    .min(2, { message: 'City must contain at least 2 characters' })
    .max(50, { message: 'City must contain at most 50 characters' }),
  postal_code: z
    .string({
      required_error: 'Postal Code is required',
      invalid_type_error: 'Postal Code must be a string',
    })
    .regex(/^[A-Z0-9 -]*$/, { message: 'Postal Code must be valid' })
    .min(4, { message: 'Postal Code must contain at least 4 characters' })
    .max(10, { message: 'Postal Code must contain at most 10 characters' }),
  country: z
    .string({
      required_error: 'Country is required',
      invalid_type_error: 'Country must be a string',
    })
    .regex(/^[^0-9!@#$%^&*_;()?~`"{}|<>[\]\\]*$/, {
      message: 'Country must not contain special characters.',
    })
    .min(4, { message: 'Country must contain at least 4 characters' })
    .max(30, { message: 'Country must contain at most 30 characters' }),
  lat: z
    .string()
    .refine((value) => value !== '', {
      message: 'Latitude is required',
    })
    .refine((value) => !Number.isNaN(value), {
      message: 'Latitude must be a number',
    })
    .refine((value) => Number(value) >= -90 && Number(value) <= 90, {
      message: 'Latitude must be a number between -90 and 90',
    }),
  lon: z
    .string()
    .refine((value) => value !== '', {
      message: 'Longitude is required',
    })
    .refine((value) => !Number.isNaN(value), {
      message: 'Longitude must be a number',
    })
    .refine((value) => Number(value) >= -180 && Number(value) <= 180, {
      message: 'Longitude must be a number between -180 and 180',
    }),
});
